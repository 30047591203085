import { Options, Vue } from 'vue-class-component'
import { Component, Watch } from 'vue-property-decorator'
import BaseFunctions from '@/components/Utility/Base'
import CommonFunctions, { countryList } from '@/components/Utility/Common'
import { Competitions } from '@/services/CompetitionsDataService'
import ClubsDataService from '@/services/ClubsDataService'
import MembersDataService from '@/services/MembersDataService'
import ForeignPlayersDataService from '@/services/ForeignPlayersDataService'
import UsersDataService from '@/services/UsersDataService'
import { Products } from '@/services/ProductsDataService'
import SysCompetition, { competitionCategory2FriendlyType, competitionCategoryFriendlyType, SysCompetitionRegistration, SysCompetitionRegistrationId, SysCompetitionRows, SysCompetitionRankingsPoint, SysCompetitionResults, SysForeignCompetition, SysForeignCompetitionResults, SysOldCompetitionResults } from '@/types/SysCompetition'
import SysClub from '@/types/SysClub'
import SysMember from '@/types/SysMember'
import SysForeignPlayer from '@/types/SysForeignPlayer'
import SysUser from '@/types/SysUser'
import SysProduct, { SysProductProductType } from '@/types/SysProduct'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { VueCsvErrors, VueCsvImport, VueCsvInput, VueCsvMap, VueCsvToggleHeaders } from 'vue-csv-import'
import MediasDataService from '@/services/MediasDataService'
import exportFromJSON from 'export-from-json'
import { DartConnect } from '@/services/DartConnectService'

/* export default defineComponent({
  name: 'AdminCompetitions',
  setup () {
    // component setup
  }
}) */

enum PlayerRestrictionsCheckFailFlag {
  gender = <any>'1',
  gendermixdouble = <any>'2',
  oldboysage = <any>'4',
  juniorage = <any>'8',
  paradart = <any>'16'
}

type competitionDataType = { competitionStatus: boolean; competitionName: string; competitionCategory: string; competitionRegistrationFrom: string; competitionLastRegistrationDate: string; competitionPeriodBegin: string; competitionPeriodEnd: string; competitionVenueLocation: string; competitionClub: number; competitionRows: number[]; competitionInvitation: any[]; competitionLeader: string; competitionUmpire: string; }
type competitionRowDataType = { competitionRowStatus: boolean; competitionRowName: string; competitionRegistrationFee: number; competitions: number[] }
type competitionMemberRegistrationDataType = { competitionMemberRegistrationStatus: boolean; competitionMemberRegistrationMembers: any; competitionForeignPlayerRegistrationMembers: any; competitionMemberRegistrationCompetitionRowId: any; competitionMemberRegistrationCompetitionId: any; competitionMemberRegistrationAddRemoveStatus: boolean }
type competitionRankingsPointDataType = { competitionRankingsPointStatus: boolean; competitionPlacement: number; competitionRankingsPointPoint: number }
type competitionResultsDataType = { competitionResultsInput: string; competitionResultsDate: string }
type competitionResultsCSVDataType = { place: string; navn: string; clubcountry: string; licens: string; memberactive?: boolean; points?: number; pointoffset?: number }
type foreignPlayerCompetitionResultDataType = { foreignResultPlacement: string; foreignResultDate: string; foreignResultPoints: number; rowName: string; foreignCompetitionName: string; }
type foreignPlayerCompetitionDataType = { foreignCompetitionName: string; foreignCompetitionDate: string; }
type dataReturnType = { competitionRowsForRegistration: any; competitions: any; competitionData: any; competitionRowData: any; competitionMemberRegistrationData: any; competitionRankingsPointData: any; competitionResultsData: any; competitionMemberForMemberRegistrationNameMaxSelections: number; competitionForeignPlayerForMemberRegistrationNameMaxSelections: number; selectedCompetitionInvitation: any; staevners: any; staevneraekkers: any; staevneranglistepoints: any; filterRegistrationByCompetitionsOptions: any; filterStatusStringValue: string; filterStatusStringValue2: string; filterStatusStringValue3: string; filterStatusStringValue4: string; filterCompetitionByTimeValue: any; filterCompetitionByTimeOptions: any[]; csvInputDataObj: any; medias: any; tabValue: string; index: number; error: any; }

@Options({
  props: {
    msg: String
  },
  components: {
    QuillEditor,
    VueCsvImport,
    VueCsvToggleHeaders,
    VueCsvMap,
    VueCsvInput,
    VueCsvErrors
  }
})
export default class AdminCompetitions extends Vue {
  msg!: string
  error: any = null
  private tabValue = 'Vis og rediger stævner'
  private staevners: SysCompetition[] = []
  private competitions: SysCompetition[] = []
  private staevneraekkers: SysCompetitionRows[] = []
  private competitionRowsForRegistration: SysCompetitionRows[] = []
  private allSeries: SysCompetitionRows[] = []
  private staevnetilmeldings: SysCompetitionRegistration[] = []
  private filteredStaevnetilmeldings: SysCompetitionRegistration[] = []
  private staevneranglistepoints: SysCompetitionRankingsPoint[] = []
  private staevneresultats: SysCompetitionResults[] = []
  private tempStaevneresults: SysCompetitionResults[] = []
  private foreignCompetitons: SysForeignCompetition[] = []
  private udspillers: SysForeignPlayer[] = []
  private udStaevneresultats: SysForeignCompetitionResults[] = []
  private tempUdStaevneresultats: SysForeignCompetitionResults[] = []
  private klubbers: SysClub[] = []
  private medlemmers: SysMember[] = []
  private medias: any[] = []
  private tempCompetition = {} as SysCompetition
  private tempCompetitionRow = {} as SysCompetitionRows
  private tempCompetitionRegistration = {} as SysCompetitionRegistration
  private tempCompetitionRegistrations: SysCompetitionRegistration[] = []
  private tempCompetitionRankingsPoint = {} as SysCompetitionRankingsPoint
  private tempCompetitionRankingsPoints: SysCompetitionRankingsPoint[] = []
  private tempCompetitionResults: SysCompetitionResults[] = []
  private tempCompetitionRegistrationUsers: SysUser[] = []
  private tempCompetitionRegistrationForeignPlayers: SysForeignPlayer[] = []
  private tempCompetitionResultUsers: SysUser[] = []
  private tempMembers: SysMember[] = []
  competitionData: competitionDataType = { competitionStatus: false, competitionName: '', competitionCategory: '', competitionRegistrationFrom: '', competitionLastRegistrationDate: '', competitionPeriodBegin: '', competitionPeriodEnd: '', competitionVenueLocation: '', competitionClub: 0, competitionRows: [], competitionInvitation: [], competitionLeader: '', competitionUmpire: '' }
  competitionRowData: competitionRowDataType = { competitionRowStatus: true, competitionRowName: '', competitionRegistrationFee: 0, competitions: [] }
  competitionMemberRegistrationData: competitionMemberRegistrationDataType = { competitionMemberRegistrationStatus: true, competitionMemberRegistrationMembers: [0], competitionForeignPlayerRegistrationMembers: [0], competitionMemberRegistrationCompetitionRowId: 0, competitionMemberRegistrationCompetitionId: 0, competitionMemberRegistrationAddRemoveStatus: true }
  competitionRankingsPointData: competitionRankingsPointDataType = { competitionRankingsPointStatus: true, competitionPlacement: 0, competitionRankingsPointPoint: 0 }
  competitionResultsData: competitionResultsDataType = { competitionResultsInput: '', competitionResultsDate: '' }
  foreignPlayerCompetitionData: foreignPlayerCompetitionDataType = { foreignCompetitionName: '', foreignCompetitionDate: '' }
  foreignPlayerCompetitionResultData: foreignPlayerCompetitionResultDataType = { foreignResultPlacement: '', foreignResultDate: '', foreignCompetitionName: '', foreignResultPoints: 0, rowName: '' }
  filterCompetitionByTimeValue = 'Alle stævner'
  filterCompetitionByTimeOptions: string[] = ['Alle stævner', 'Kommende stævner', 'Afholdte stævner']
  filterStatusStringValue = 'Vis alt'
  filterStatusStringOptions: string[] = ['Vis alt', 'Aktive', 'Ikke aktive']
  filterStatusStringValue2 = 'Vis alt'
  filterStatusStringOptions2: string[] = ['Vis alt', 'Aktive', 'Ikke aktive']
  filterStatusStringValue3 = 'Vis alt'
  filterStatusStringOptions3: string[] = ['Vis alt', 'Aktive', 'Ikke aktive']
  filterStatusStringValue4 = 'Vis alt'
  filterStatusStringOptions4: string[] = ['Vis alt', 'Aktive', 'Ikke aktive']
  filterStatusStringValue5 = 'Vis alt'
  filterStatusStringOptions5: string[] = ['Vis alt', 'Aktive', 'Ikke aktive']
  filterCompetitionRowValue = { name: 'Alle rækker', id: 0 }
  filterCompetitionRowOptions: { name: string, id: number }[] = [{ name: 'Alle rækker', id: 0 }]
  filterCompetitionNameValue = { name: 'Intet stævne', value: '0' }
  filterCompetitionNameOptions: { name: string; value: string }[] = [{ name: 'Intet stævne', value: '0' }]
  tempFilterCompetitionNameOptions: { name: string; value: string }[] = [{ name: 'Intet stævne', value: '0' }]
  tempFilterForeignCompetitionNameOptions: { name: string; value: string }[] = [{ name: 'Intet stævne', value: '0' }]
  filterRegistrationByCompetitionsValue = { name: 'Intet stævne', id: '0', rows: [] }
  filterRegistrationByCompetitionsOptions: { name: string; id: string; rows: any[] }[] = [{ name: 'Intet stævne', id: '0', rows: [] }]
  filterRegistrationsBySeriesValue = { name: 'Vis alt', id: 0 }
  filterRegistrationBySeriesOptions: { name: string, id: number }[] = []
  editCompetitionResultsModalTabValue = ''
  competitionSearchInputValue = ''
  competitionRowSearchInputValue = ''
  competitionPointSearchInputValue = ''
  memberSearchInputString = ''
  competitionSearchTerm = ''
  competitionRowSearchTerm = ''
  competitionPointSearchTerm = ''
  memberSearchTerm = ''
  toggleIconsSortingValue = 0
  toggleIconsSortingValue2 = 0
  toggleIconsSortingValue3 = 0
  competitionRegionLimitValue = competitionCategory2FriendlyType.alle
  competitionRegionLimitOptions = Object.values(competitionCategory2FriendlyType).filter(value => typeof value === 'string').filter(function (element) { return (<string>element).startsWith((<string>element).substr(0, 1).toLowerCase()) })
  competitionCategoryTitleValue = competitionCategoryFriendlyType.Kategori1
  competitionCategoryTitleOptions = Object.values(competitionCategoryFriendlyType).filter(value => typeof value === 'string').filter(function (element) { return (<string>element).startsWith((<string>element).substr(0, 1).toLowerCase()) })
  competitionPlayerCategoryTitleValue = { name: '', value: '' }
  competitionPlayerCategoryTitleOptions: { name: string; value: string }[] = []
  // competitionClubsNameValue: { name: string; value: string }[] = []
  competitionClubsNameValue = { name: 'Vælg her', value: 0 }
  competitionClubsNameOptions: { name: string; value: number }[] = []
  competitionRowsNameValue: { name: string; value: string, price: number, productId: number | null }[] = []
  competitionRowsNameOptions: { name: string; value: string, price: number, productId: number | null }[] = []
  competitionRowTypesNameValue = { name: 'Single (1 person)', value: '0' }
  competitionRowTypesNameOptions: { name: string; value: string }[] = [{ name: 'Single (1 person)', value: '0' }, { name: 'Double (2 personer)', value: '1' }, { name: 'Hold (4 personer)', value: '2' }]
  competitionRowForMemberRegistrationNameValue = { name: '', value: '' }
  competitionRowForMemberRegistrationNameOptions: { name: string; value: string; competitionrowid: string; competitionid: string; competitionrowtype: number }[] = []
  competitionMemberForMemberRegistrationNameValue: { name: string; value: string; gender: string; birthdaydate: string; isparadart: boolean; clubid: number; competitionRegistrations: SysCompetitionRegistrationId[] }[] = []
  competitionMemberForMemberRegistrationNameOptions: { key: number; name: string; value: string; gender: string; birthdaydate: string; isparadart: boolean; clubid: number; competitionRegistrations: SysCompetitionRegistrationId[] }[] = []
  competitionForeignPlayerForMemberRegistrationNameValue: { name: string; value: string; gender: string; birthdaydate: string; isparadart: boolean; country: string; competitionRegistrations: SysCompetitionRegistrationId[] }[] = []
  competitionForeignPlayerForMemberRegistrationNameOptions: { key: number; name: string; value: string; gender: string; birthdaydate: string; isparadart: boolean; country: string; competitionRegistrations: SysCompetitionRegistrationId[] }[] = []
  foreignCompetitionValue = { name: 'Vælg stævne', id: 0, date: '' }
  foreignCompetitionOptions: { name: string; id: number; date: string }[] = []
  foreignCompetitionRowsOptions: { id: number, name: string }[] = [{ id: 0, name: 'Vælg række' }]
  // foreignCompetitionRowsOptions: { id: number, name: string }[] = [{ id: 0, name: 'Vælg række' }, { id: 22, name: 'Herresingle' }, { id: 23, name: 'Damesingle' }, { id: 24, name: 'Pigesingle' }, { id: 25, name: 'Drengesingle' }, { id: 15, name: 'Paradart Single' }]
  foreignCompetitionRowsValue = { id: 0, name: 'Vælg række' }
  foreignCompetitionRankingValue = { placement: 'Vælg placering', awardedPoints: 0, index: -1 }
  foreignCompetitionRankingOptions: { placement: string, awardedPoints: number, index: number }[] = [{ placement: 'Vælg placering', awardedPoints: 0, index: -1 }]
  foreignCompetitionResultsArray: { name: string, memberId: number, rowName: string, rowId: number, date: string, placement: string, points: number, numberInArray: number }[] = []
  historicalCompetitionResultsValue = 'Seneste år'
  historicalCompetitionResultsOptions: string[] = ['Seneste år']
  findMemberValue = { id: 0, name: 'Vælg medlem' }
  findMemberOptions: { id: number, name: string }[] = [{ id: 0, name: 'Vælg medlem' }]
  competitionPlayerForMemberRegistrationNameMaxSelections = 1
  competitionMemberForMemberRegistrationNameMaxSelections = 1
  competitionForeignPlayerForMemberRegistrationNameMaxSelections = 1
  competitionPlayerModusSelectedOption = '0'
  competitionPlayerModusOptions = ['0', '1', '2']
  // competitionPlayerModusLabels = ['Kun medlemmer', 'En udenlandsk', 'Kun udenlandske']
  competitionPlayerModusLabels = ['Danske spillere', 'Både danske/ udenlandske spillere (bruges ved hold og doubles)', 'Kun udenlandske spillere']
  competitionPlayerDisregardForeignPlayers = false
  private competitionRowsRows = [{ key: 1, id: 0, staevne_raekker_status: false, staevne_raekker_navn: '', staevne_kategori: '', staevne_navn: '', staevne_spillested: '', staevne_start: '', staevne_slut: '', staevne_sidstetilmelding: '', staevne_raekker_tilmeldingsgebyr: 0, staevne_raekker_type: 0 }]
  private competitionRowsRowsForRegistration = [{ key: 1, id: 0, staevne_raekker_status: false, staevne_raekker_navn: '', staevne_kategori: '', staevne_navn: '', staevne_spillested: '', staevne_start: '', staevne_slut: '', staevne_sidstetilmelding: '', staevne_raekker_tilmeldingsgebyr: 0, staevne_raekker_type: 0 }]
  // Some variables for handling of the foreign competition results:
  private existingForeignCompetition = false
  private foreignCompetitionResults = false
  private foreignResultId = 0
  private foreignCompetitionDate = ''
  private foreignCompetitionPoints = 0
  private currentCompetitionSortingOrder = 'staevne_start:desc'
  private oldCompetitionSortingOrder = ''
  private currentCompetitionRowsSortingOrder = ''
  private oldCompetitionRowsSortingOrder = ''
  private currentListSortingOrder = ''
  // private currentListSortingOrder2 = ''
  private currentListSortingOrder3 = ''
  private currentSeriesSortingOrder = ''
  private oldSeriesSortingOrder = ''
  // private submitted = false
  private submitted2 = false
  private submitted3 = false
  // private editCompetitionModal = false
  private editForeignCompetitionModal = false
  private editCompetitionRowModal = false
  private editRegistrationModal = false
  private editCompetitionRankingsPointModal = false
  private editCompetitionResultsModal = false
  private foreignCompetitionResultsModal = false
  private updateCompetitionMemberRegistrationModal = false
  private addInvitationModal = false
  // private createNewCompetitionModal = false
  private showPlayerSelectionOptions = true
  private seriesDrawerRight = false
  private deleteCompetitionWarningModal = false
  private deleteRegistrationWarningModal = false
  private deleteResultWarningModal = false
  private deleteRegistrationId = 0
  private deleteCompetitionId = 0
  private lastEditedCompetitionId = 0
  private lastEditedCompetitionRowId = 0
  private lastEditedCompetitionRegistrationId = 0
  private lastEditedCompetitionRankingsPointId = 0
  private lastEditedCompetitionResultsCompetitionId = 0
  private lastEditedForeignCompetition = 0
  private lastCreatedCompetitionId = 0
  private lastCreatedCompetitionRowId = 0
  private lastCreatedCompetitionRegistrationId = 0
  // private updateCompetitionMethodStatus = 0
  private checkPlayerRestrictionsFlag = 0
  private csvInputDelimiter = '\t'
  private csvInputDataObj: competitionResultsCSVDataType[] = []
  private csvInputUploadHasHeader = false
  private keyPress = ''
  private backSpace = ''
  private toDaysDate = new Date().toISOString()
  private competitionHeld = ''
  private finishedUploadFlag = false
  private selectedCompetitionInvitation = ''
  private selectedFile: any = null
  private extraRegistrationParameter = ''
  private idOfGenericMember = 17
  private index = 0
  private currentPage = 1
  private totalPages = 0
  private totalNumberOfPages = 0
  pageSizeValue = 25
  pageSizeValueString = '25'
  pageSizeOptions: string[] = ['10', '25', '100']
  exportLoader = false
  productTypeValue = { name: 'Vælg produktgruppe', value: 0 }
  productTypeOptions: { name: string, value: number }[] = []
  createNewCompetition = false
  competitionModal = false
  tournamentsOption = []
  selectedTournamentOption = null
  tournamentEventsOption = []
  selectedEventsData = {}
  dartConnectResultsData: any | null = null
  tempCompetitionResultsData: any[] = []
  isGenerateResultsDisabled = true
  isFromCsv = false
  isFromDartConnect = false
  isEventRegistrationOpen = true

  // public refThisAdminCompetitions: any

  readonly name : string = 'AdminCompetitions'
  $Message: any

  data (): dataReturnType {
    return {
      competitionRowsForRegistration: this.competitionRowsForRegistration,
      competitions: this.competitions,
      competitionData: this.competitionData,
      competitionRowData: this.competitionRowData,
      competitionMemberRegistrationData: this.competitionMemberRegistrationData,
      competitionRankingsPointData: this.competitionRankingsPointData,
      competitionResultsData: this.competitionResultsData,
      competitionMemberForMemberRegistrationNameMaxSelections: this.competitionMemberForMemberRegistrationNameMaxSelections,
      competitionForeignPlayerForMemberRegistrationNameMaxSelections: this.competitionForeignPlayerForMemberRegistrationNameMaxSelections,
      selectedCompetitionInvitation: '',
      staevners: this.staevners,
      staevneraekkers: this.staevneraekkers,
      staevneranglistepoints: this.staevneranglistepoints,
      filterRegistrationByCompetitionsOptions: this.filterRegistrationByCompetitionsOptions,
      filterStatusStringValue: this.filterStatusStringValue,
      filterStatusStringValue2: this.filterStatusStringValue2,
      filterStatusStringValue3: this.filterStatusStringValue3,
      filterStatusStringValue4: this.filterStatusStringValue4,
      filterCompetitionByTimeValue: this.filterCompetitionByTimeValue,
      filterCompetitionByTimeOptions: this.filterCompetitionByTimeOptions,
      csvInputDataObj: this.csvInputDataObj,
      medias: this.medias,
      tabValue: 'Vis og rediger stævner',
      index: this.index,
      error: this.error
    }
  }

  declare $refs: {
    editVenueLocationEditor: typeof QuillEditor
  }

  public createHistoricalResultsOptions () : void {
    this.historicalCompetitionResultsOptions = ['Seneste år']
    const currentYear = new Date().getFullYear()
    const startYear = 2010
    for (let i = 0; i < currentYear - startYear; i++) {
      const yearValue = startYear + i
      this.historicalCompetitionResultsOptions.push(yearValue.toString())
    }
    this.historicalCompetitionResultsOptions.sort((a, b) => {
      if (a === 'Seneste år') { return -1 }
      if (b === 'Seneste år') { return 1 }
      if (Number(b) < Number(a)) { return -1 }
      if (Number(b) > Number(a)) { return 1 }

      return 0
    })
  }

  // public test (event: { name: string }) {
  //   console.log(event.name)
  // }

  @Watch('pageSizeValueString')
  onPageSizeValueChange (newVal: any) : void {
    this.pageSizeValue = Number(newVal)
    this.currentPage = 1
    this.retrieveCompetitions()
    CommonFunctions.scrollToTop()
  }

  @Watch('competitionRegionLimitValue')
  onChange () : void {
    console.log(this.competitionRegionLimitValue)
  }

  @Watch('deleteRegistrationWarningModal')
  onRegistrationWarningModalChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.deleteRegistrationId = 0
    }
  }

  @Watch('deleteCompetitionWarningModal')
  onCompetitionWarningModalChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.deleteCompetitionId = 0
    }
  }

  @Watch('filterRegistrationsBySeriesValue')
  onFilterChange () : void {
    if (this.filterRegistrationsBySeriesValue.id === 0) {
      this.filteredStaevnetilmeldings = this.staevnetilmeldings
    } else {
      this.filteredStaevnetilmeldings = this.staevnetilmeldings.filter((element) => { return element.staevne_raekker_id.id === this.filterRegistrationsBySeriesValue.id })
    }
  }

  @Watch('filterCompetitionNameValue')
  onCompetitionNameValueChange () : void {
    // if (this.filterCompetitionNameValue.value !== '0') {
    //   this.filterCompetitionRowValue = { name: 'Alle rækker', id: 0 }
    //   this.filterCompetitionRowOptions = [{ name: 'Alle rækker', id: 0 }]
    //   this.retrieveCompetitionResults()
    // }
    this.filterCompetitionRowValue = { name: 'Alle rækker', id: 0 }
    this.filterCompetitionRowOptions = [{ name: 'Alle rækker', id: 0 }]
    this.retrieveCompetitionResults()
  }

  @Watch('filterCompetitionRowValue')
  onCompetitionRowValueChange () : void {
    if (!this.foreignCompetitionResults) {
      if (this.filterCompetitionRowValue.id === 0) {
        this.staevneresultats = this.tempStaevneresults
      } else {
        this.staevneresultats = this.tempStaevneresults.filter(item => item.staevne_raekker_id.id === this.filterCompetitionRowValue.id)
      }
      this.staevneresultats.sort(function (a, b) {
        if (a.staevne_raekker_id.staevne_raekker_navn === b.staevne_raekker_id.staevne_raekker_navn) {
          return parseInt(a.staevne_resultater_placering) - parseInt(b.staevne_resultater_placering)
        }
        return a.staevne_raekker_id.staevne_raekker_navn > b.staevne_raekker_id.staevne_raekker_navn ? 1 : -1
      })
    } else {
      if (this.filterCompetitionRowValue.id === 0) {
        this.udStaevneresultats = this.tempUdStaevneresultats
      } else {
        this.udStaevneresultats = this.tempUdStaevneresultats.filter(item => item.staevne_raekker_id.id === this.filterCompetitionRowValue.id)
      }
      this.udStaevneresultats.sort(function (a, b) {
        if (a.staevne_raekker_id.staevne_raekker_navn === b.staevne_raekker_id.staevne_raekker_navn) {
          return parseInt(a.ud_resultat_placering) - parseInt(b.ud_resultat_placering)
        }
        return a.staevne_raekker_id.staevne_raekker_navn > b.staevne_raekker_id.staevne_raekker_navn ? 1 : -1
      })
    }
  }

  @Watch('competitionRowForMemberRegistrationNameValue')
  onCompetitionRowForMemberRegistrationNameValueChange () : void {
    if (this.competitionRowForMemberRegistrationNameValue.value !== '') {
      this.updateCompetitionMemberRegistration()
    }
  }

  @Watch('filterRegistrationByCompetitionsValue')
  showRegisteredPlayersForCompetition () : void {
    if (this.filterRegistrationByCompetitionsValue.id !== '0') {
      this.extraRegistrationParameter = 'staevner_id.id=' + this.filterRegistrationByCompetitionsValue.id
      this.retrieveCompetitionRegistrations()
      this.createSeriesFilterForRegistration()
    }
  }

  @Watch('filterCompetitionByTimeValue')
  onfilterByTimeValueChange (status: string) : void {
    if (status === 'Alle stævner') {
      this.competitionHeld = ''
      this.currentCompetitionSortingOrder = 'staevne_start:desc'
    }
    if (status === 'Kommende stævner') {
      this.competitionHeld = `staevne_start_gte=${this.toDaysDate}`
      this.currentCompetitionSortingOrder = 'staevne_start:asc'
    }
    if (status === 'Afholdte stævner') {
      this.competitionHeld = `staevne_start_lt=${this.toDaysDate}`
      this.currentCompetitionSortingOrder = 'staevne_start:desc'
    }

    this.retrieveCompetitions()
  }

  @Watch('filterStatusStringValue')
  onCompetitionRowsStatusChanged (statusValue: string, oldStatusValue: string) : void {
    console.log('Filter status changed to: ' + statusValue)
    this.retrieveCompetitionRows()
  }

  @Watch('filterStatusStringValue2')
  onCompetitionsStatusChanged (statusValue: string, oldStatusValue: string) : void {
    console.log('Filter status changed to: ' + statusValue)
    this.retrieveCompetitions()
  }

  @Watch('filterStatusStringValue3')
  onCompetitionRegistrationsStatusChanged (statusValue: string, oldStatusValue: string) : void {
    console.log('Filter status changed to: ' + statusValue)
    this.retrieveCompetitionRegistrations()
  }

  @Watch('filterStatusStringValue4')
  onCompetitionRankingsPointsStatusChanged (statusValue: string, oldStatusValue: string) : void {
    console.log('Filter status changed to: ' + statusValue)
    this.retrieveCompetitionRankingsPoints()
  }

  // @Watch('toggleIconsSortingValue')
  // onListSortToggleChanged (toggleValue: string, oldToggleValue: string) : void {
  //   console.log('List sort toggle changed to: ' + toggleValue)
  //   if (toggleValue === 'check_box_outline_blank') {
  //     this.currentListSortingOrder = ''
  //   } else if (toggleValue === 'staevneraekkenavn') {
  //     this.currentListSortingOrder = 'staevne_raekker_navn:asc'
  //   } else if (toggleValue === 'staevnenavn') {
  //     this.currentListSortingOrder = 'staevner_id.staevne_navn:asc'
  //   } else if (toggleValue === 'kategori') {
  //     this.currentListSortingOrder = 'staevner_id.staevne_kategori:asc'
  //   } else if (toggleValue === 'startidspunkt') {
  //     this.currentListSortingOrder = 'staevner_id.staevne_start:asc'
  //   } else if (toggleValue === 'sidstetilmelding') {
  //     this.currentListSortingOrder = 'staevner_id.staevne_sidstetilmelding:asc'
  //   } else if (toggleValue === 'pris') {
  //     this.currentListSortingOrder = 'staevne_raekker_tilmeldingsgebyr:asc'
  //   }
  //
  //   this.retrieveCompetitionRows()
  // }

  // @Watch('toggleIconsSortingValue2')
  // onListSort2ToggleChanged (toggleValue: string, oldToggleValue: string) : void {
  //   console.log('List sort toggle changed to: ' + toggleValue)
  //   if (toggleValue === 'check_box_outline_blank') {
  //     this.currentListSortingOrder2 = ''
  //   } else if (toggleValue === 'navn') {
  //     this.currentListSortingOrder2 = 'staevne_navn:asc'
  //   } else if (toggleValue === 'kategori') {
  //     this.currentListSortingOrder2 = 'staevne_kategori:asc'
  //   } else if (toggleValue === 'startidspunkt') {
  //     this.currentListSortingOrder2 = 'staevne_start:asc'
  //   } else if (toggleValue === 'sidstetilmelding') {
  //     this.currentListSortingOrder2 = 'staevne_sidstetilmelding:asc'
  //   }
  //
  //   this.retrieveCompetitions()
  // }

  @Watch('toggleIconsSortingValue3')
  onListSort3ToggleChanged (toggleValue: string, oldToggleValue: string) : void {
    console.log('List sort toggle changed to: ' + toggleValue)
    if (toggleValue === 'check_box_outline_blank') {
      this.currentListSortingOrder3 = ''
    } else if (toggleValue === 'placering') {
      this.currentListSortingOrder3 = 'staevne_ranglistepoint_placering:asc'
    } else if (toggleValue === 'kategori') {
      this.currentListSortingOrder3 = 'staevne_ranglistepoint_kategori:asc'
    } else if (toggleValue === 'points') {
      this.currentListSortingOrder3 = 'staevne_ranglistepoint_point:asc'
    } else if (toggleValue === 'raekke') {
      this.currentListSortingOrder3 = 'staevne_ranglistepoint_raekke:desc'
    }

    this.retrieveCompetitionRankingsPoints()
  }

  @Watch('competitionPlayerModusSelectedOption')
  onCompetitionPlayerModusSelectedOptionChange (selectValue: string, oldSelectValue: string) : void {
    console.log('[onCompetitionPlayerModusSelectedOptionChange] Selection changed to: ' + selectValue)

    if (this.competitionPlayerDisregardForeignPlayers === false) {
      this.competitionMemberForMemberRegistrationNameValue = []
      this.competitionForeignPlayerForMemberRegistrationNameValue = []

      if (Number(selectValue) === 0) {
        // Only member players can be selected.
        this.competitionMemberForMemberRegistrationNameMaxSelections = this.competitionPlayerForMemberRegistrationNameMaxSelections
        this.competitionForeignPlayerForMemberRegistrationNameMaxSelections = 0
      } else if (Number(selectValue) === 2) {
        // Only foreign players can be selected.
        this.competitionForeignPlayerForMemberRegistrationNameMaxSelections = this.competitionPlayerForMemberRegistrationNameMaxSelections
        this.competitionMemberForMemberRegistrationNameMaxSelections = 0
      } else if (Number(selectValue) === 1) {
        // Both foreign and member players can be selected.
        this.competitionMemberForMemberRegistrationNameMaxSelections = (this.competitionPlayerForMemberRegistrationNameMaxSelections > 1 ? this.competitionPlayerForMemberRegistrationNameMaxSelections / 2 : 0)
        this.competitionForeignPlayerForMemberRegistrationNameMaxSelections = (this.competitionPlayerForMemberRegistrationNameMaxSelections > 1 ? this.competitionPlayerForMemberRegistrationNameMaxSelections / 2 : this.competitionPlayerForMemberRegistrationNameMaxSelections)
      }

      console.log('[onCompetitionPlayerModusSelectedOptionChange] competitionMemberForMemberRegistrationNameMaxSelections = ' + this.competitionMemberForMemberRegistrationNameMaxSelections)
      console.log('[onCompetitionPlayerModusSelectedOptionChange] competitionForeignPlayerForMemberRegistrationNameMaxSelections = ' + this.competitionForeignPlayerForMemberRegistrationNameMaxSelections)

      // Fix: To update the currently used max-selections values for the select options input elements, it is necessary to remove and re-add the select options input elements in the DOM.
      // this.updateCompetitionMemberRegistrationModal = false
      this.showPlayerSelectionOptions = false
      this.$nextTick(() => {
        // this.updateCompetitionMemberRegistrationModal = true
        this.showPlayerSelectionOptions = true
      })
    }
  }

  /* constructor (options: any) {
    super(options)
    this.refThisAdminCompetitions = this
  } */

  public getRowNameFromId (id: number) : string {
    const obj = this.staevneraekkers.find(arrayItem => arrayItem.id === id)

    return (obj !== undefined ? obj.staevne_raekker_navn : 'Ukendt række')
  }

  public getDefaultValueFromId (id: number) : number {
    const obj = this.staevneraekkers.find(arrayItem => arrayItem.id === id)

    return (obj !== undefined ? obj.staevne_raekker_tilmeldingsgebyr : 0)
  }

  public turnToNewPage (pageChange : number) : void {
    this.currentPage += pageChange

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage >= this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveCompetitions()
  }

  public jumpToPage (pageNumber : number) : void {
    this.currentPage = pageNumber

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage >= this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveCompetitions()
  }

  public removeInvitation (numberInArray: number) : void {
    this.competitionData.competitionInvitation.splice(numberInArray, 1)
    console.log(this.competitionData.competitionInvitation)
  }

  public moveInvitation (oldIndex: number, byThisAmount: number) : void {
    const element = this.competitionData.competitionInvitation[oldIndex]
    console.log(element)
    const newIndex = oldIndex + byThisAmount
    console.log('New position' + newIndex)
    this.competitionData.competitionInvitation.splice(oldIndex, 1)
    this.competitionData.competitionInvitation.splice(newIndex, 0, element)

    console.log(this.competitionData.competitionInvitation)
  }

  public addInvitation () : void {
    console.log('Fetching pdfs from API.')
    this.addInvitationModal = true

    MediasDataService.getAll('updated_at:desc', null, 'ext=.pdf')
      .then((response: any) => {
        this.medias = response.data
        console.log(response.data)
      })
      .catch((err: any) => {
        this.error = err
      })
  }

  public useSelectedInvitation () : void {
    console.log(this.selectedCompetitionInvitation)

    if (this.selectedCompetitionInvitation !== undefined) {
      this.competitionData.competitionInvitation.push(this.selectedCompetitionInvitation)
    }
    console.log(this.competitionData.competitionInvitation)

    this.addInvitationModal = false
    this.selectedCompetitionInvitation = ''
    this.selectedFile = ''
  }

  public cancelAddInvitation () : void {
    this.selectedCompetitionInvitation = ''
    this.addInvitationModal = false
    this.selectedFile = ''
  }

  public onFileSelected (event: any) : void {
    this.selectedFile = event.target.files[0]
    console.log(this.selectedFile)
  }

  public onUpload () : void {
    if (this.selectedFile !== null && this.selectedFile.type === 'application/pdf') {
      const fd: FormData = new FormData()

      fd.append('files', this.selectedFile)

      MediasDataService.upload(fd)
        .then((response) => {
          console.log(response.data)
          alert('Invitationen er successfuldt uploadet')

          this.addInvitation()
        })
        .catch((err) => {
          this.error = err
          alert('Der gik et eller andet galt, prøv igen')
        })
    } else {
      alert('Kun pdf filer kan uploades her')
    }
  }

  public competitionRowColumnSort (sortBy: string) : void {
    if (sortBy === 'reset') {
      this.currentCompetitionRowsSortingOrder = ''
      this.oldCompetitionRowsSortingOrder = ''
      this.competitionRowSearchInputValue = ''
      this.competitionRowSearchTerm = ''
    }
    if (sortBy === 'rowName') {
      if (this.oldCompetitionRowsSortingOrder === '' || this.oldCompetitionRowsSortingOrder !== sortBy) {
        this.currentCompetitionRowsSortingOrder = 'staevne_raekker_navn:asc,staevne_raekker_type:asc'
        this.oldCompetitionRowsSortingOrder = sortBy
      } else {
        this.currentCompetitionRowsSortingOrder = 'staevne_raekker_navn:desc,staevne_raekker_type:asc'
        this.oldCompetitionRowsSortingOrder = ''
      }
    }
    if (sortBy === 'rowType') {
      if (this.oldCompetitionRowsSortingOrder === '' || this.oldCompetitionRowsSortingOrder !== sortBy) {
        this.currentCompetitionRowsSortingOrder = 'staevne_raekker_type:desc,staevne_raekker_navn:desc'
        this.oldCompetitionRowsSortingOrder = sortBy
      } else {
        this.currentCompetitionRowsSortingOrder = 'staevne_raekker_type:asc,staevne_raekker_navn:desc'
        this.oldCompetitionRowsSortingOrder = ''
      }
    }
    if (sortBy === 'price') {
      if (this.oldCompetitionRowsSortingOrder === '' || this.oldCompetitionRowsSortingOrder !== sortBy) {
        this.currentCompetitionRowsSortingOrder = 'staevne_raekker_tilmeldingsgebyr:asc,staevne_raekker_type:asc,staevne_raekker_navn:asc'
        this.oldCompetitionRowsSortingOrder = sortBy
      } else {
        this.currentCompetitionRowsSortingOrder = 'staevne_raekker_tilmeldingsgebyr:desc,staevne_raekker_type:desc,staevne_raekker_navn:desc'
        this.oldCompetitionRowsSortingOrder = sortBy
      }
    }
    if (sortBy === 'competitionCategory') {
      if (this.oldCompetitionRowsSortingOrder === '' || this.oldCompetitionRowsSortingOrder !== sortBy) {
        this.currentCompetitionRowsSortingOrder = 'staevner_id.staevne_kategori:asc,staevne_raekker_type:asc,staevne_raekker_navn:asc'
        this.oldCompetitionRowsSortingOrder = sortBy
      } else {
        this.currentCompetitionRowsSortingOrder = 'staevner_id.staevne_kategori:desc,staevne_raekker_type:desc,staevne_raekker_navn:desc'
        this.oldCompetitionRowsSortingOrder = ''
      }
    }
    if (sortBy === 'competitionName') {
      if (this.oldCompetitionRowsSortingOrder === '' || this.oldCompetitionRowsSortingOrder !== sortBy) {
        this.currentCompetitionRowsSortingOrder = 'staevner_id.staevne_navn:asc'
        this.oldCompetitionRowsSortingOrder = sortBy
      } else {
        this.currentCompetitionRowsSortingOrder = 'staevner_id.staevne_navn:desc'
        this.oldCompetitionRowsSortingOrder = ''
      }
    }
    if (sortBy === 'begins') {
      if (this.oldCompetitionRowsSortingOrder === '' || this.oldCompetitionRowsSortingOrder !== sortBy) {
        this.currentCompetitionRowsSortingOrder = 'staevner_id.staevne_start:asc,staevner_id.staevne_navn:asc'
        this.oldCompetitionRowsSortingOrder = sortBy
      } else {
        this.currentCompetitionRowsSortingOrder = 'staevner_id.staevne_start:desc,staevner_id.staevne_navn:desc'
        this.oldCompetitionRowsSortingOrder = ''
      }
    }
    if (sortBy === 'ends') {
      if (this.oldCompetitionRowsSortingOrder === '' || this.oldCompetitionRowsSortingOrder !== sortBy) {
        this.currentCompetitionRowsSortingOrder = 'staevner_id.staevne_slut:asc,staevner_id.staevne_navn:asc'
        this.oldCompetitionRowsSortingOrder = sortBy
      } else {
        this.currentCompetitionRowsSortingOrder = 'staevner_id.staevne_slut:desc,staevner_id.staevne_navn:desc'
        this.oldCompetitionRowsSortingOrder = ''
      }
    }
    if (sortBy === 'lastRegistration') {
      if (this.oldCompetitionRowsSortingOrder === '' || this.oldCompetitionRowsSortingOrder !== sortBy) {
        this.currentCompetitionRowsSortingOrder = 'staevner_id.staevne_sidstetilmelding:asc,staevner_id.staevne_navn:asc'
        this.oldCompetitionRowsSortingOrder = sortBy
      } else {
        this.currentCompetitionRowsSortingOrder = 'staevner_id.staevne_sidstetilmelding:desc,staevner_id.staevne_navn:desc'
        this.oldCompetitionRowsSortingOrder = ''
      }
    }

    this.retrieveCompetitionRows()
  }

  public competitionColumnSort (sortBy : string) : void {
    if (sortBy === 'reset') {
      this.currentCompetitionSortingOrder = 'staevne_start:desc'
      this.oldCompetitionSortingOrder = ''
      this.competitionSearchTerm = ''
      this.competitionSearchInputValue = ''
    }
    if (sortBy === 'name') {
      if (this.oldCompetitionSortingOrder === '' || this.oldCompetitionSortingOrder !== sortBy) {
        this.currentCompetitionSortingOrder = 'staevne_navn:asc'
        this.oldCompetitionSortingOrder = sortBy
      } else {
        this.currentCompetitionSortingOrder = 'staevne_navn:desc'
        this.oldCompetitionSortingOrder = ''
      }
    }
    if (sortBy === 'category') {
      if (this.oldCompetitionSortingOrder === '' || this.oldCompetitionSortingOrder !== sortBy) {
        this.currentCompetitionSortingOrder = 'staevne_kategori:asc'
        this.oldCompetitionSortingOrder = sortBy
      } else {
        this.currentCompetitionSortingOrder = 'staevne_kategori:desc'
        this.oldCompetitionSortingOrder = ''
      }
    }
    if (sortBy === 'place') {
      if (this.oldCompetitionSortingOrder === '' || this.oldCompetitionSortingOrder !== sortBy) {
        this.currentCompetitionSortingOrder = 'staevne_spillested:asc'
        this.oldCompetitionSortingOrder = sortBy
      } else {
        this.currentCompetitionSortingOrder = 'staevne_spillested:desc'
        this.oldCompetitionSortingOrder = ''
      }
    }
    if (sortBy === 'start') {
      if (this.oldCompetitionSortingOrder === '' || this.oldCompetitionSortingOrder !== sortBy) {
        this.currentCompetitionSortingOrder = 'staevne_start:asc'
        this.oldCompetitionSortingOrder = sortBy
      } else {
        this.currentCompetitionSortingOrder = 'staevne_start:desc'
        this.oldCompetitionSortingOrder = ''
      }
    }
    if (sortBy === 'end') {
      if (this.oldCompetitionSortingOrder === '' || this.oldCompetitionSortingOrder !== sortBy) {
        this.currentCompetitionSortingOrder = 'staevne_slut:asc'
        this.oldCompetitionSortingOrder = sortBy
      } else {
        this.currentCompetitionSortingOrder = 'staevne_slut:desc'
        this.oldCompetitionSortingOrder = ''
      }
    }
    if (sortBy === 'lastRegistration') {
      if (this.oldCompetitionSortingOrder === '' || this.oldCompetitionSortingOrder !== sortBy) {
        this.currentCompetitionSortingOrder = 'staevne_sidstetilmelding:asc'
        this.oldCompetitionSortingOrder = sortBy
      } else {
        this.currentCompetitionSortingOrder = 'staevne_sidstetilmelding:desc'
        this.oldCompetitionSortingOrder = ''
      }
    }

    this.retrieveCompetitions()
  }

  public getTimeOfDay (dateString: string) : string {
    return CommonFunctions.getHoursAndMinutes(dateString)
  }

  public forceRerenderCompetitionsList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  public convertHTMLToText (htmlData: string) : string {
    return BaseFunctions.convertHTMLToText(htmlData)
  }

  public convertHTMLToTextShort (htmlData: string, cutoffSize = 40) : string {
    const getVal = this.convertHTMLToText(htmlData).trim()

    return getVal.slice(0, cutoffSize) + (getVal.length > cutoffSize + 1 ? ' ...' : '')
  }

  public locConvertFromUTCtoLocalDateTime (inputDatetimeString: string) : string {
    let retVal = ''
    // console.log('[locConvertFromUTCtoLocalDateTime()] inputDatetimeString = ' + inputDatetimeString)
    retVal = CommonFunctions.convertFromUTCtoLocalDateTime(inputDatetimeString, 0)
    const strPos = retVal.indexOf(' ')
    retVal = (retVal.substring(0, strPos) + 'T' + retVal.substring(strPos + 1)).substring(0, 19)

    return retVal
  }

  public danishDataWClockArr (dateString: string) : string[] {
    return [CommonFunctions.toDanishDateString(dateString, 3), CommonFunctions.getHoursAndMinutes(dateString, false)]
  }

  // Fill the club name options array with data of the available club items.
  // public fillCompetitionClubsNameOptionsData () : void {
  //   this.competitionClubsNameOptions = []
  //   for (const clubitem of this.klubbers) {
  //     if (clubitem.klubber_status) {
  //       this.competitionClubsNameOptions.push({ name: (clubitem.id === undefined || clubitem.klubber_klubnavn === null ? '' : clubitem.klubber_klubnavn), value: (clubitem.id === undefined ? '0' : Number(clubitem.id).toString()) })
  //     }
  //   }
  //   // console.log('Club names = ' + JSON.stringify(this.competitionClubsNameOptions))
  // }

  // Fill the competition name options array with data of the available competition items
  // public fillCompetitionNameOptionsData () : void {
  //   this.filterCompetitionNameOptions = [{ name: 'Intet stævne', value: '0' }]
  //   for (const competitionitem of this.staevners) {
  //     if (competitionitem.staevne_status) {
  //       this.filterCompetitionNameOptions.push({ name: competitionitem.staevne_navn, value: (competitionitem.id === undefined ? '0' : Number(competitionitem.id).toString()) })
  //     }
  //   }
  //   // console.log('Competition names = ' + JSON.stringify(this.filterCompetitionNameOptions))
  // }

  // Fill the competition row name options array with data of the available competition row items.
  public fillCompetitionRowsNameOptionsData () : void {
    this.competitionRowsNameOptions = []
    for (const competitionrowitem of this.staevneraekkers) {
      if (competitionrowitem.staevne_raekker_status) {
        this.competitionRowsNameOptions.push({ name: competitionrowitem.staevne_raekker_navn + ' (' + competitionrowitem.staevne_raekker_tilmeldingsgebyr + ' kr.)', value: (competitionrowitem.id === undefined ? '0' : Number(competitionrowitem.id).toString()), price: competitionrowitem.staevne_raekker_tilmeldingsgebyr, productId: null })
      }
    }
    this.competitionRowsNameOptions.sort((a, b) => a.name.localeCompare(b.name))
    // console.log('Competition row names = ' + JSON.stringify(this.competitionRowsNameOptions))
  }

  // Fill the competition player category title options array with data of the available competition row items of the specified competition.
  public fillCompetitionPlayerCategoryTitleOptionsData (competitionId: number) : void {
    const tempIndexOfCompetition = this.staevners.findIndex(x => x.id === competitionId)

    if (tempIndexOfCompetition >= 0 && this.staevners[tempIndexOfCompetition] !== undefined &&
      this.staevners[tempIndexOfCompetition] !== null && this.staevners[tempIndexOfCompetition].staevne_raekker_id !== undefined &&
      this.staevners[tempIndexOfCompetition].staevne_raekker_id !== null) {
      this.competitionPlayerCategoryTitleOptions = []
      let competitionRowItemName = ''

      for (const competitionrowitem of this.staevners[tempIndexOfCompetition].staevne_raekker_id) {
        if (competitionrowitem.staevne_raekker_status) {
          competitionRowItemName = competitionrowitem.staevne_raekker_navn.trim().toLocaleLowerCase()
          this.competitionPlayerCategoryTitleOptions.push({ name: competitionrowitem.staevne_raekker_navn.trim() + ' - ' + competitionrowitem.staevne_raekker_tilmeldingsgebyr + ' kr.', value: (competitionrowitem.id === undefined ? '0' : Number(competitionrowitem.id).toString()) })
          // if (competitionRowItemName.length > 0 && (competitionRowItemName.startsWith('a-') || competitionRowItemName.startsWith('b-') || competitionRowItemName.startsWith('c-') || competitionRowItemName.endsWith(' a') || competitionRowItemName.endsWith(' b') || competitionRowItemName.endsWith(' c'))) {
          //   this.competitionPlayerCategoryTitleOptions.push({ name: competitionrowitem.staevne_raekker_navn.trim() + ' - ' + competitionrowitem.staevne_raekker_tilmeldingsgebyr + ' kr.', value: (competitionrowitem.id === undefined ? '0' : Number(competitionrowitem.id).toString()) })
          // }
        }
      }
      // console.log('Competition row names = ' + JSON.stringify(this.competitionPlayerCategoryTitleOptions))
    }
  }

  public generateValueArrayFromObjectArray (objArray: { name: string, value: string }[]) : string[] {
    const retVal = []

    for (const objItem of objArray) {
      retVal.push(objItem.value)
    }
    console.log('Generated value array: ' + retVal)

    return retVal
  }

  public getPlayerRegistrationCheckFlagMsg () : string {
    let retVal = ''

    if (this.checkPlayerRestrictionsFlag === PlayerRestrictionsCheckFailFlag.gender) {
      retVal = 'køn'
    }
    if (this.checkPlayerRestrictionsFlag === PlayerRestrictionsCheckFailFlag.gendermixdouble) {
      retVal = (retVal.length > 0 ? ', kønsforskel' : 'kønsforskel')
    }
    if (this.checkPlayerRestrictionsFlag === PlayerRestrictionsCheckFailFlag.oldboysage) {
      retVal = (retVal.length > 0 ? ', aldersgrænse for senior' : 'aldersgrænse for senior')
    }
    if (this.checkPlayerRestrictionsFlag === PlayerRestrictionsCheckFailFlag.juniorage) {
      retVal = (retVal.length > 0 ? ', aldersgrænse for junior' : 'aldersgrænse for junior')
    }
    if (this.checkPlayerRestrictionsFlag === Number(PlayerRestrictionsCheckFailFlag.paradart)) {
      retVal = (retVal.length > 0 ? ', paradart' : 'paradart')
    }

    return (retVal.length > 0 ? '(Spiller tjek fejlede ved: ' + retVal + ')' : retVal)
  }

  public getTextOfMembersFromObject (objArray: { id: number | null, medlem_licens: number, user_id: number | null, klubber_id: number | null }[], objArray2: { id: number | null, ud_spiller_land: string, ud_spiller_fornavn: string, ud_spiller_efternavn: string }[] = []) : string {
    let retVal = ''

    for (const objItem of objArray) {
      retVal = retVal + this.getUserNameFromCompetitionRegistrationsUsers(objItem.user_id) + ' [' + (objItem.klubber_id === null ? 'Ingen klub' : this.getClubNameFromCompetitionRegistrationUsers(objItem.klubber_id)) + '] [' + (objItem.medlem_licens === null ? '-' : objItem.medlem_licens) + (Number(objItem.id) === Number(objArray[objArray.length - 1].id) ? ']' : '], ')
      // retVal = retVal + 'ID: ' + Number(objItem.id) + ' ' + this.getUserNameFromCompetitionRegistrationsUsers(objItem.user_id) + ' [' + (objItem.medlem_licens === null ? '-' : objItem.medlem_licens) + (Number(objItem.id) === Number(objArray[objArray.length - 1].id) ? ']' : '], ')
    }
    let firstLoop = true
    for (const objItem of objArray2) {
      retVal = retVal + (firstLoop && retVal.length > 0 ? ', ' : '') + Number(objItem.id) + ' ' + (objItem.ud_spiller_fornavn === null ? '-' : objItem.ud_spiller_fornavn) + ' ' + (objItem.ud_spiller_efternavn === null ? '-' : objItem.ud_spiller_efternavn) + ' [' + (objItem.ud_spiller_land === null ? '-' : objItem.ud_spiller_land) + (Number(objItem.id) === Number(objArray2[objArray2.length - 1].id) ? ']' : '], ')
      // retVal = retVal + (firstLoop && retVal.length > 0 ? ', ID: ' : 'ID: ') + Number(objItem.id) + ' ' + (objItem.ud_spiller_navn === null ? '-' : objItem.ud_spiller_navn) + ' [' + (objItem.ud_spiller_land === null ? '-' : objItem.ud_spiller_land) + (Number(objItem.id) === Number(objArray2[objArray2.length - 1].id) ? ']' : '], ')
      firstLoop = false
    }

    return retVal
  }

  public getTextOfClubOrCountryFromObject (objArray: { id: number | null, klubber_id: number | null }[], objArray2: { id: number | null, ud_spiller_land: string }[] = []) : string {
    let retVal = ''

    for (const objItem of objArray) {
      retVal = retVal + (objItem.klubber_id === null ? 'Ingen klub' : this.getClubNameFromCompetitionRegistrationUsers(objItem.klubber_id)) + (Number(objItem.id) === Number(objArray[objArray.length - 1].id) ? '' : ', ')
    }
    let firstLoop = true
    for (const objItem of objArray2) {
      retVal = retVal + (firstLoop && retVal.length > 0 ? ', ' : '') + (objItem.ud_spiller_land === null ? '-' : objItem.ud_spiller_land) + (Number(objItem.id) === Number(objArray2[objArray2.length - 1].id) ? '' : ', ')
      firstLoop = false
    }

    return retVal
  }

  public getTextOfCompetitionFromObject (objArray: { id: number | null, staevne_navn: string }[]) : string {
    let retVal = ''

    for (const objItem of objArray) {
      retVal = retVal + (objItem.staevne_navn) + (Number(objItem.id) === Number(objArray[objArray.length - 1].id) ? '' : ', ')
    }

    return retVal
  }

  public getClubNameFromCompetitionRegistrationUsers (clubId: number | null) : string {
    let retVal = ''

    for (const clubItem of this.klubbers) {
      if (clubId === clubItem.id) {
        retVal = clubItem.klubber_klubnavn
      }
    }

    return retVal
  }

  public getUserNameFromCompetitionRegistrationsUsers (userId: number | null) : string {
    let retVal = '????'
    const indexUsers = this.tempCompetitionRegistrationUsers.findIndex(x => x.id === userId)

    if (indexUsers !== undefined && this.tempCompetitionRegistrationUsers[indexUsers] !== undefined) {
      retVal = this.tempCompetitionRegistrationUsers[indexUsers].firstname + ' ' + this.tempCompetitionRegistrationUsers[indexUsers].lastname
    }

    return retVal
  }

  public getUserNameFromCompetitionResultsUsers (userId: number, altName = '', useAltNameFlag = false) : string {
    let retVal = '????'

    if (useAltNameFlag) {
      retVal = altName
    } else {
      const indexUsers = this.tempCompetitionResultUsers.findIndex(x => x.id === userId)

      if (indexUsers !== undefined && this.tempCompetitionResultUsers[indexUsers] !== undefined) {
        retVal = this.tempCompetitionResultUsers[indexUsers].firstname + ' ' + this.tempCompetitionResultUsers[indexUsers].lastname
      }
    }

    return retVal
  }

  public fixCSVInputName (csvName: string) : string {
    let retVal = csvName.trim()
    const indexOfStartparenthesis = retVal.indexOf('(')

    if (indexOfStartparenthesis >= 0) {
      retVal = retVal.slice(0, indexOfStartparenthesis - 1)
    }
    retVal = retVal.replace('AFBUD', '')

    return retVal.replace(/\s{2,}/g, ' ').trim() // Changes every sequence of multiple spaces found within the string to a single space
  }

  public findCompetition () : void {
    console.log('Search competitions.')
    this.competitionSearchTerm = this.competitionSearchInputValue
    this.retrieveCompetitions()
  }

  public findCompetitionRow () : void {
    console.log('Search competition rows.')
    this.competitionRowSearchTerm = this.competitionRowSearchInputValue
    this.retrieveCompetitionRows()
  }

  public findCompetitionRankingsPoint () : void {
    console.log('Search competition rankings point.')
    this.competitionPointSearchTerm = this.competitionPointSearchInputValue
    this.retrieveCompetitionRankingsPoints()
  }

  public getProductId (rowName: string, productArray: { id: number | null, produkt_navn: string }[]) : number {
    let retId = 0

    for (const objItem of productArray) {
      if (objItem.produkt_navn.includes(rowName)) {
        retId = (objItem.id === null ? 0 : objItem.id)
      }
    }

    return retId
  }

  getRowPrice (rowName: string, defaultPrice: number, productArray: { id: number | null, produkt_navn: string, produkt_pris: number }[]) : number {
    const obj = productArray.find(objItem => objItem.produkt_navn.includes(rowName))

    return (obj !== undefined ? obj.produkt_pris : defaultPrice)
  }

  public editCompetition (competitionId : number) : void {
    console.log('Edit competition item with Id: ' + competitionId)
    // this.editCompetitionModal = true
    this.createNewCompetition = false
    this.competitionModal = true
    this.lastEditedCompetitionId = competitionId

    Competitions.CompetitionsDataService.get(competitionId.toString())
      .then((response) => {
        this.tempCompetition = response.data
        console.log(response.data)
        this.selectedEventsData = (this as any).tempCompetition.staevner_tournament_events ? JSON.parse((this as any).tempCompetition.staevner_tournament_events) : {}
        // this.competitionData = { competitionStatus: this.tempCompetition.staevne_status, competitionName: this.tempCompetition.staevne_navn, competitionCategory: this.tempCompetition.staevne_kategori.toString(), competitionLastRegistrationDate: this.tempCompetition.staevne_sidstetilmelding, competitionPeriodBegin: this.tempCompetition.staevne_start, competitionPeriodEnd: this.tempCompetition.staevne_slut, competitionVenueLocation: this.tempCompetition.staevne_spillested, competitionClub: this.tempCompetition.klubber_id, competitionRows: this.tempCompetition.staevne_raekker_id, competitionInvitation: this.tempCompetition.staevne_invitation }
        this.competitionData = {
          competitionStatus: this.tempCompetition.staevne_status,
          competitionName: this.tempCompetition.staevne_navn,
          competitionCategory: this.tempCompetition.staevne_kategori.toString(),
          competitionLastRegistrationDate: this.tempCompetition.staevne_sidstetilmelding,
          competitionPeriodBegin: this.locConvertFromUTCtoLocalDateTime(this.tempCompetition.staevne_start).substring(0, 19),
          competitionPeriodEnd: this.locConvertFromUTCtoLocalDateTime(this.tempCompetition.staevne_slut).substring(0, 19),
          competitionVenueLocation: this.tempCompetition.staevne_spillested,
          competitionClub: (this.tempCompetition.klubber_id !== undefined && this.tempCompetition.klubber_id !== null ? Number(this.tempCompetition.klubber_id.id) : 0),
          competitionRows: [],
          competitionInvitation: this.tempCompetition.staevne_invitation,
          competitionLeader: this.tempCompetition.staevne_staevneleder,
          competitionUmpire: this.tempCompetition.staevne_overdommer,
          competitionRegistrationFrom: this.tempCompetition.produkts_id[0].produkt_datofra
        }
        if ((this as any).tempCompetition?.staevner_tournament_id) {
          const selectedTournamentOption = this.tournamentsOption.find((item: any) => item.id === (this as any).tempCompetition?.staevner_tournament_id)
          if (selectedTournamentOption) {
            this.selectedTournamentOption = selectedTournamentOption
          }
        }
        this.competitionCategoryTitleValue = this.tempCompetition.staevne_kategori
        this.competitionRegionLimitValue = (this.tempCompetition.staevne_kat2 === null ? competitionCategory2FriendlyType.alle : this.tempCompetition.staevne_kat2)
        if (this.tempCompetition.produkt_type_id !== null) {
          this.productTypeValue = { name: this.tempCompetition.produkt_type_id.produkt_type_navn, value: Number(this.tempCompetition.produkt_type_id.id) }
        }
        this.competitionRowsNameValue = []
        for (const competitionrowitem of this.tempCompetition.staevne_raekker_id) {
          this.competitionRowsNameValue.push({
            name: competitionrowitem.staevne_raekker_navn,
            value: Number(competitionrowitem.id).toString(),
            price: this.getRowPrice(competitionrowitem.staevne_raekker_navn, competitionrowitem.staevne_raekker_tilmeldingsgebyr, this.tempCompetition.produkts_id),
            productId: this.getProductId(competitionrowitem.staevne_raekker_navn, this.tempCompetition.produkts_id)
          })
        }
        /* this.competitionClubsNameValue = []
        for (const competitionclubitem of this.tempCompetition.klubber_id) {
          this.competitionClubsNameValue.push({ name: competitionclubitem.klubber_klubnavn, value: Number(competitionclubitem.id).toString() })
        } */
        this.competitionClubsNameValue = { name: (this.tempCompetition.klubber_id === null ? 'Vælg her' : this.tempCompetition.klubber_id.klubber_klubnavn), value: (this.tempCompetition.klubber_id === null ? 0 : Number(this.tempCompetition.klubber_id.id)) }
        // this.competitionClubsNameValue = { name: (this.tempCompetition.klubber_id === undefined || this.tempCompetition.klubber_id === null || this.tempCompetition.klubber_id.id === null || this.tempCompetition.klubber_id.klubber_klubnavn === null ? '' : this.tempCompetition.klubber_id.klubber_klubnavn), value: (this.tempCompetition.klubber_id === undefined || this.tempCompetition.klubber_id === null || this.tempCompetition.klubber_id.id === null || this.tempCompetition.klubber_id.klubber_klubnavn === null ? '0' : Number(this.tempCompetition.klubber_id.id).toString()) }
        console.log('Currently selected rows: ' + JSON.stringify(this.competitionRowsNameValue))

        // this.$refs.editVenueLocationEditor.setContents(this.competitionData.competitionVenueLocation, 'user')
      })
      .catch((err) => {
        this.error = err
      })
  }

  public editCompetitionRow (competitionRowId : number) : void {
    console.log('Edit competition row item with Id: ' + competitionRowId)
    this.editCompetitionRowModal = true
    this.lastEditedCompetitionRowId = competitionRowId

    Competitions.CompetitionRowsDataService.get(competitionRowId.toString())
      .then((response) => {
        this.tempCompetitionRow = response.data
        console.log(response.data)

        this.competitionRowData = { competitionRowStatus: this.tempCompetitionRow.staevne_raekker_status, competitionRowName: this.tempCompetitionRow.staevne_raekker_navn, competitionRegistrationFee: this.tempCompetitionRow.staevne_raekker_tilmeldingsgebyr, competitions: [] }
      })
      .catch((err) => {
        this.error = err
      })
  }

  public editCompetitionRankingsPoint (competitionRankingsPointId: number) : void {
    console.log('Edit competition rankings point item with Id: ' + competitionRankingsPointId)
    this.editCompetitionRankingsPointModal = true
    this.lastEditedCompetitionRankingsPointId = competitionRankingsPointId

    Competitions.CompetitionRankingsPointsDataService.get(competitionRankingsPointId.toString())
      .then((response) => {
        this.tempCompetitionRankingsPoint = response.data
        console.log(response.data)

        this.competitionRankingsPointData = { competitionRankingsPointStatus: this.tempCompetitionRankingsPoint.staevne_ranglistepoint_status, competitionPlacement: Number(this.tempCompetitionRankingsPoint.staevne_ranglistepoint_placering), competitionRankingsPointPoint: this.tempCompetitionRankingsPoint.staevne_ranglistepoint_point }
        this.competitionCategoryTitleValue = this.tempCompetitionRankingsPoint.staevne_ranglistepoint_kategori
      })
      .catch((err) => {
        this.error = err
      })
  }

  public editCompetitionResults (competitionId: number) : void {
    console.log('Edit competition results for competition item with Id: ' + competitionId)
    this.editCompetitionResultsModalTabValue = '1'
    this.lastEditedCompetitionResultsCompetitionId = competitionId

    Competitions.CompetitionsDataService.get(competitionId.toString())
      .then((response) => {
        this.tempCompetition = response.data
        console.log(response.data)
        this.selectedEventsData = (this as any).tempCompetition.staevner_tournament_events ? JSON.parse((this as any).tempCompetition.staevner_tournament_events) : {}
        this.competitionData = { competitionStatus: this.tempCompetition.staevne_status, competitionName: this.tempCompetition.staevne_navn, competitionCategory: this.tempCompetition.staevne_kategori.toString(), competitionRegistrationFrom: new Date(this.tempCompetition.produkts_id[0].produkt_datofra).toISOString().split('T')[0], competitionLastRegistrationDate: this.tempCompetition.staevne_sidstetilmelding, competitionPeriodBegin: this.locConvertFromUTCtoLocalDateTime(this.tempCompetition.staevne_start).substring(0, 19), competitionPeriodEnd: new Date(this.tempCompetition.staevne_slut).toISOString().substring(0, 19), competitionVenueLocation: this.tempCompetition.staevne_spillested, competitionClub: (this.tempCompetition.klubber_id !== undefined && this.tempCompetition.klubber_id !== null ? Number(this.tempCompetition.klubber_id.id) : 0), competitionRows: [], competitionInvitation: this.tempCompetition.staevne_invitation, competitionLeader: this.tempCompetition.staevne_staevneleder, competitionUmpire: this.tempCompetition.staevne_overdommer }
        this.competitionCategoryTitleValue = this.tempCompetition.staevne_kategori
        this.competitionRegionLimitValue = (this.tempCompetition.staevne_kat2 === null ? competitionCategory2FriendlyType.alle : this.tempCompetition.staevne_kat2)
        this.competitionRowsNameValue = []
        for (const competitionrowitem of this.tempCompetition.staevne_raekker_id) {
          this.competitionRowsNameValue.push({ name: competitionrowitem.staevne_raekker_navn, value: Number(competitionrowitem.id).toString(), price: competitionrowitem.staevne_raekker_tilmeldingsgebyr, productId: null })
        }
        // this.competitionClubsNameValue = { name: this.tempCompetition.klubber_id.klubber_klubnavn, value: Number(this.tempCompetition.klubber_id.id).toString() }

        this.fillCompetitionPlayerCategoryTitleOptionsData(competitionId)

        this.editCompetitionResultsModal = true
      })
      .catch((err) => {
        this.error = err
      })
  }

  public generateCompetitionResults () : void {
    console.log('Generate competition results for the competition with the Id: ' + this.lastEditedCompetitionResultsCompetitionId)
    // console.log('[generateCompetitionResults] csv = ' + JSON.stringify(this.csvInputDataObj))
    // console.log('[generateCompetitionResults] csv[0].name =' + this.csvInputDataObj[0].navn)
    let competitionPlayerCategory = -1 // 0 = Herre A, 1 = Herre B, 2 = Herre C; 32 = Dame A, 33 = Herre B, 34 = Herre C; 64 = Drenge A, 65 = Drenge B, 66 = Drenge C; 128 = Pige A, 129 = Pige B, 130 = Pige C
    let validCompetitionPlayerCategorySubCategory1Match = false
    let validCompetitionPlayerCategorySubCategory2Match = false
    this.editCompetitionResultsModalTabValue = '2'

    // Evaluate the selected competition player category - converting evaluated data to a number, that can be processed more easily in subsequent code.
    let endPosOfCompetitionRowTitle = this.competitionPlayerCategoryTitleValue.name.trim().indexOf(' - ')
    endPosOfCompetitionRowTitle = endPosOfCompetitionRowTitle < 0 ? this.competitionPlayerCategoryTitleValue.name.trim().length - 1 : endPosOfCompetitionRowTitle
    const competitionPlayerCategoryTitle = this.competitionPlayerCategoryTitleValue.name.trim().slice(0, endPosOfCompetitionRowTitle).toLocaleLowerCase()
    // console.log('[generateCompetitionResults] endPosOfCompetitionRowTitle = ' + endPosOfCompetitionRowTitle)
    console.log('[generateCompetitionResults] competitionPlayerCategoryTitle = ' + competitionPlayerCategoryTitle)

    if (competitionPlayerCategoryTitle.search('herre') >= 0 || competitionPlayerCategoryTitle.search('aaben') >= 0) {
      competitionPlayerCategory = 0
      validCompetitionPlayerCategorySubCategory1Match = true
    } else if (competitionPlayerCategoryTitle.search('dame') >= 0) {
      competitionPlayerCategory = 32
      validCompetitionPlayerCategorySubCategory1Match = true
    } else if (competitionPlayerCategoryTitle.search('drenge') >= 0) {
      competitionPlayerCategory = 64
      validCompetitionPlayerCategorySubCategory1Match = true
    } else if (competitionPlayerCategoryTitle.search('pige') >= 0) {
      competitionPlayerCategory = 128
      validCompetitionPlayerCategorySubCategory1Match = true
    } else if (competitionPlayerCategoryTitle.search('junior') >= 0) {
      competitionPlayerCategory = 192
      validCompetitionPlayerCategorySubCategory1Match = true
    } else if (competitionPlayerCategoryTitle.search('række') >= 0) {
      competitionPlayerCategory = 256
      validCompetitionPlayerCategorySubCategory1Match = true
    } else if (competitionPlayerCategoryTitle.search('paradart') >= 0) {
      competitionPlayerCategory = 256
      validCompetitionPlayerCategorySubCategory1Match = true
    }
    if (competitionPlayerCategoryTitle.startsWith('a-') || competitionPlayerCategoryTitle.endsWith(' a') || competitionPlayerCategoryTitle.startsWith('paradart')) {
      competitionPlayerCategory |= 0
      validCompetitionPlayerCategorySubCategory2Match = true
    } else if (competitionPlayerCategoryTitle.startsWith('b-') || competitionPlayerCategoryTitle.endsWith(' b')) {
      competitionPlayerCategory |= 1
      validCompetitionPlayerCategorySubCategory2Match = true
    } else if (competitionPlayerCategoryTitle.startsWith('c-') || competitionPlayerCategoryTitle.endsWith(' c')) {
      competitionPlayerCategory |= 2
      validCompetitionPlayerCategorySubCategory2Match = true
    } else if (competitionPlayerCategoryTitle.endsWith('single')) {
      competitionPlayerCategory |= 4
      validCompetitionPlayerCategorySubCategory2Match = true
    } else if (competitionPlayerCategoryTitle.endsWith('double') || competitionPlayerCategoryTitle.endsWith('pairs') || competitionPlayerCategoryTitle.endsWith('hold')) {
      competitionPlayerCategory |= 8
      validCompetitionPlayerCategorySubCategory2Match = true
    } else if (competitionPlayerCategoryTitle.endsWith('men') || competitionPlayerCategoryTitle.endsWith('ladies') || competitionPlayerCategoryTitle.endsWith('boys') || competitionPlayerCategoryTitle.endsWith('girls') || this.competitionPlayerCategoryTitleValue.name.trim().toLocaleLowerCase().endsWith('wheelchair') || this.competitionPlayerCategoryTitleValue.name.trim().toLocaleLowerCase().endsWith('standing')) {
      competitionPlayerCategory |= 16
      validCompetitionPlayerCategorySubCategory2Match = true
    }

    console.log('[generateCompetitionResults] competitionPlayerCategory = ' + competitionPlayerCategory)
    console.log('[generateCompetitionResults] competitionCategory = ' + this.competitionData.competitionCategory)

    if ((validCompetitionPlayerCategorySubCategory2Match && competitionPlayerCategory === 2) || (validCompetitionPlayerCategorySubCategory1Match && validCompetitionPlayerCategorySubCategory2Match)) {
      // Add some additional properties to the CSV rows object array.
      // this.csvInputDataObj.forEach(item => { delete item.memberactive })
      // this.csvInputDataObj.forEach(item => { item.memberactive = (item.licens !== undefined && Number(item.licens) > 0) })
      this.csvInputDataObj.forEach(item => { item.memberactive = (item.licens !== undefined && (Number(item.licens) > 0 || item.licens.trim() === '0')) })

      Competitions.CompetitionRankingsPointsDataService.findByCompetitionRankingsPointStatus(true)
        .then((response) => {
          this.tempCompetitionRankingsPoints = response.data
          console.log(response.data)

          this.csvInputDataObj.forEach(item => { item.points = (item.memberactive && !(item.licens.trim() === '0') ? this.calcCompetitionResultPoints(this.competitionData.competitionCategory, competitionPlayerCategory, item.place) : 0); item.pointoffset = 0 })
          this.tempCompetitionRankingsPoints = []
          console.log('[generateCompetitionResults] csv = ' + JSON.stringify(this.csvInputDataObj))
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
          console.log('[generateCompetitionResults] csv = ' + JSON.stringify(this.csvInputDataObj))
          this.editCompetitionResultsModal = false
        })
    }
  }

  // Calculate competition result points based upon the player category, the rankings point category of the competition row and the player placement and return the value of the calculated points.
  public calcCompetitionResultPoints (competRankingsPointCategory: string, competPlayerCategory: number, competPlayerPlacement: string) : number {
    let retVal = 0
    let arrIndex = 0
    let posDashSeperatorInString = 0
    let minPlacementVal = 0
    let maxPlacementVal = 0
    let rankingsPointRow = 0
    posDashSeperatorInString = competPlayerPlacement.trim().indexOf('-')
    posDashSeperatorInString = posDashSeperatorInString >= 0 ? posDashSeperatorInString : competPlayerPlacement.trim().length
    const playerMinPlacementVal = Number(competPlayerPlacement.trim().slice(0, posDashSeperatorInString))
    const playerMaxPlacementVal = Number(competPlayerPlacement.trim().slice((posDashSeperatorInString === competPlayerPlacement.trim().length) ? 0 : posDashSeperatorInString + 1))

    for (const objItem of this.tempCompetitionRankingsPoints) {
      posDashSeperatorInString = objItem.staevne_ranglistepoint_placering.trim().indexOf('-')
      posDashSeperatorInString = posDashSeperatorInString >= 0 ? posDashSeperatorInString : objItem.staevne_ranglistepoint_placering.trim().length
      minPlacementVal = Number(objItem.staevne_ranglistepoint_placering.trim().slice(0, posDashSeperatorInString))
      maxPlacementVal = Number(objItem.staevne_ranglistepoint_placering.trim().slice((posDashSeperatorInString === objItem.staevne_ranglistepoint_placering.trim().length) ? 0 : posDashSeperatorInString + 1))
      rankingsPointRow = (objItem.staevne_ranglistepoint_raekke === null ? 2 : (objItem.staevne_ranglistepoint_raekke ? 0 : 1))
      // console.log('[generateCompetitionResults] minPlacementVal / maxPlacementVal = ' + minPlacementVal + ' / ' + maxPlacementVal)
      // console.log('[generateCompetitionResults] competPlayerPlacement = ' + competPlayerPlacement)
      // console.log('[generateCompetitionResults] playerMinPlacementVal = ' + playerMinPlacementVal)
      // console.log('[generateCompetitionResults] staevne_ranglistepoint_kategori = ' + objItem.staevne_ranglistepoint_kategori.toString().trim().toLocaleLowerCase())

      if (objItem.staevne_ranglistepoint_status && objItem.staevne_ranglistepoint_kategori.toString().trim().toLocaleLowerCase() === competRankingsPointCategory) {
        // console.log('[generateCompetitionResults] First test success. [' + arrIndex + ']')
        if (playerMinPlacementVal >= minPlacementVal && playerMinPlacementVal <= maxPlacementVal) {
          // console.log('[generateCompetitionResults] Second test success. [' + arrIndex + ']')
          if (rankingsPointRow === (competPlayerCategory & 3)) {
            // console.log('[generateCompetitionResults] Third test success. [' + arrIndex + ']')
            retVal += Number(objItem.staevne_ranglistepoint_point)
          }
        }
      }

      arrIndex++
    }

    return retVal
  }

  public reduceCompetitionResultPoints (csvObjIndex: number, csvObjPlace: number) : void {
    console.log('Reduce the competition points result for player with CSV index: ' + csvObjIndex)
    const offsetValue = this.csvInputDataObj !== undefined && this.csvInputDataObj[csvObjIndex].points !== undefined ? this.csvInputDataObj[csvObjIndex].points : 0
    this.csvInputDataObj[csvObjIndex].pointoffset = -(Number(offsetValue))
    // console.log('[reduceCompetitionResultPoints] csv = ' + JSON.stringify(this.csvInputDataObj))
  }

  public restoreCompetitionResultPoints (csvObjIndex: number, csvObjPlace: number) : void {
    console.log('Restore the competition points result back to the original result for player with CSV index: ' + csvObjIndex)
    this.csvInputDataObj[csvObjIndex].pointoffset = 0
    // console.log('[resetCompetitionResultPoints] csv = ' + JSON.stringify(this.csvInputDataObj))
  }

  public reduceCompetitionResultPointsInResultList (competitionResultPlayerListIndex: number) : void {
    console.log('Reduce the competition points result for player with list index: ' + competitionResultPlayerListIndex)

    if (competitionResultPlayerListIndex >= 0 && this.staevneresultats[competitionResultPlayerListIndex] !== undefined && this.staevneresultats[competitionResultPlayerListIndex].id !== null && Number(this.staevneresultats[competitionResultPlayerListIndex].medlem_id) !== undefined && Number(this.staevneresultats[competitionResultPlayerListIndex].medlem_id.id) !== this.idOfGenericMember) {
      this.staevneresultats[competitionResultPlayerListIndex].staevne_ranglistepoint = 0
      this.staevneresultats[competitionResultPlayerListIndex].staevne_ranglistepoint = 0
      this.updateCompetitionResultListEntry(competitionResultPlayerListIndex)
    }
  }

  public restoreCompetitionResultPointsInResultList (competitionResultPlayerListIndex: number) : void {
    console.log('Restore the competition points result back to the original result for player with list index: ' + competitionResultPlayerListIndex)

    if (competitionResultPlayerListIndex >= 0 && this.staevneresultats[competitionResultPlayerListIndex] !== undefined && this.staevneresultats[competitionResultPlayerListIndex].id !== null && Number(this.staevneresultats[competitionResultPlayerListIndex].medlem_id) !== undefined && Number(this.staevneresultats[competitionResultPlayerListIndex].medlem_id.id) !== this.idOfGenericMember) {
      this.staevneresultats[competitionResultPlayerListIndex].staevne_ranglistepoint = this.staevneresultats[competitionResultPlayerListIndex].staevne_ranglistepoint_oprindelig
      this.updateCompetitionResultListEntry(competitionResultPlayerListIndex)
    }
  }

  public updateCompetitionResultListEntry (competitionResultPlayerListIndex: number) : boolean {
    console.log('Update information of competition result entry with index: ' + competitionResultPlayerListIndex)

    if (competitionResultPlayerListIndex >= 0 && this.staevneresultats[competitionResultPlayerListIndex] !== undefined && this.staevneresultats[competitionResultPlayerListIndex].id !== null) {
      const updateCompetitionResultsData = {
        /* staevne_ranglistepoint: (Number(this.csvInputDataObj[csvObjIndex].points) + Number(this.csvInputDataObj[csvObjIndex].pointoffset)) */
        staevne_ranglistepoint: Number(this.staevneresultats[competitionResultPlayerListIndex].staevne_ranglistepoint)
      }

      console.log('[updateCompetitionResultEntry] updateCompetitionResultsData = ' + JSON.stringify(updateCompetitionResultsData))

      Competitions.CompetitionResultsDataService.update(Number(this.staevneresultats[competitionResultPlayerListIndex].id).toString(), updateCompetitionResultsData)
        .then((response) => {
          // this.lastUpdatedCompetitionResultId = response.data.id
          console.log(response.data)
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })

      return true
    }

    return false
  }

  public saveUpdateCompetitionResults () : void {
    console.log('Save or update competition results for the competition with the Id: ' + this.lastEditedCompetitionResultsCompetitionId)
    // Check if there is already an existing result entry for the given result date and the given comptition and competition row and member / player.
    // If there is one then update the result of it, otherwise create at new entry.

    let csvObjIndex = 0

    for (const objItem of this.csvInputDataObj) {
      console.log('[saveUpdateCompetitionResults] memberactive = ' + objItem.memberactive)
      console.log('[saveUpdateCompetitionResults] licens is a number = ' + !isNaN(Number(objItem.licens)) + ' [' + (!isNaN(Number(objItem.licens)) ? objItem.licens : '-') + ']')
      console.log('[saveUpdateCompetitionResults] points = ' + objItem.points)
      console.log('[saveUpdateCompetitionResults] pointoffset = ' + objItem.pointoffset)
      const csvInputDataNameFieldValueFixedup = (objItem.navn === null ? '' : this.fixCSVInputName(objItem.navn))
      console.log('[saveUpdateCompetitionResults] name fixup = ***' + csvInputDataNameFieldValueFixedup + '***')
      let tempMedlemmers: SysMember[] = []
      let competitionMemberId = 0

      if (objItem.licens !== undefined && objItem.licens !== null &&
        objItem.points !== undefined && objItem.pointoffset !== undefined) {
        MembersDataService.getAll('', null, 'medlem_licens=' + Number(objItem.licens).toString())
          .then((response) => {
            tempMedlemmers = response.data
            // console.log(response.data)

            // if (tempMedlemmers.length > 0 && tempMedlemmers[0].id !== null) {
            if (tempMedlemmers.length >= 0 && isNaN(Number(objItem.navn.toString()))) {
              competitionMemberId = (tempMedlemmers.length > 0 && tempMedlemmers[0].id !== null ? Number(tempMedlemmers[0].id) : this.idOfGenericMember)
              console.log('[saveUpdateCompetitionResults] Searching for an existing competition result entry for member with Id: ' + competitionMemberId + ' ; Name : ' + csvInputDataNameFieldValueFixedup)
              console.log('[saveUpdateCompetitionResults] Searching for an existing competition result entry with competition result date Id: ' + this.competitionResultsData.competitionResultsDate)
              console.log('[saveUpdateCompetitionResults] Searching for an existing competition result entry with competition Id: ' + this.lastEditedCompetitionResultsCompetitionId)
              console.log('[saveUpdateCompetitionResults] Searching for an existing competition result entry with competition player category row Id: ' + Number(this.competitionPlayerCategoryTitleValue.value))
              tempMedlemmers = []

              if (competitionMemberId === this.idOfGenericMember) {
                // If it is a generic member (without a licens, - typical for foreign players) do a search for exact name match in the existing competition results data.
                console.log('[saveUpdateCompetitionResults] Generic member - doing a name search in the existing competition results data.')

                Competitions.CompetitionResultsDataService.findByCompetitionRowAndPlayerAltName(true, true, this.competitionResultsData.competitionResultsDate, '', null, this.lastEditedCompetitionResultsCompetitionId, Number(this.competitionPlayerCategoryTitleValue.value), (objItem.navn !== null ? csvInputDataNameFieldValueFixedup : ''))
                  .then((response) => {
                    this.tempCompetitionResults = response.data
                    console.log(response.data)

                    // if (this.tempCompetitionResults.length > 0 && this.tempCompetitionResults[0].id !== null && this.csvInputDataObj[csvObjIndex].points !== undefined && this.csvInputDataObj[csvObjIndex].pointoffset !== undefined) {
                    if (this.tempCompetitionResults.length > 0 && this.tempCompetitionResults[0].id !== null) {
                      // Update an existing competition result entry.
                      console.log('[saveUpdateCompetitionResults] Update an existing competition result entry for member with Id: ' + competitionMemberId + ' and for existing result with Id: ' + Number(this.tempCompetitionResults[0].id).toString())

                      const updateCompetitionResultsData = {
                        staevne_resultater_status: true,
                        medlem_id: competitionMemberId.toString(),
                        staevne_raekker_id: this.competitionPlayerCategoryTitleValue.value.toString(),
                        staevner_id: this.lastEditedCompetitionResultsCompetitionId.toString(),
                        staevne_resultater_dato: this.competitionResultsData.competitionResultsDate,
                        /* staevne_ranglistepoint: (Number(this.csvInputDataObj[csvObjIndex].points) + Number(this.csvInputDataObj[csvObjIndex].pointoffset)) */
                        staevne_ranglistepoint: (competitionMemberId === this.idOfGenericMember ? 0 : (Number(objItem.points) + Number(objItem.pointoffset))),
                        staevne_ranglistepoint_oprindelig: (competitionMemberId === this.idOfGenericMember ? 0 : Number(objItem.points)),
                        staevne_resultater_navn: (objItem.navn !== null ? csvInputDataNameFieldValueFixedup : ''),
                        staevne_resultater_placering: objItem.place
                      }

                      console.log('[saveUpdateCompetitionResults] ' + JSON.stringify(updateCompetitionResultsData))

                      Competitions.CompetitionResultsDataService.update(Number(this.tempCompetitionResults[0].id).toString(), updateCompetitionResultsData)
                        .then((response) => {
                          // this.lastUpdatedCompetitionResultId = response.data.id
                          console.log(response.data)

                          if (csvObjIndex >= this.csvInputDataObj.length) {
                            this.editCompetitionResultsModal = false
                          }
                        })
                        .catch((err) => {
                          this.error = err
                          // console.log(err)
                        })
                    } else {
                      // Create a new competition result entry.
                      console.log('[saveUpdateCompetitionResults] Create a new competition result entry for member with Id: ' + competitionMemberId)

                      const createCompetitionResultsData = {
                        staevne_resultater_status: true,
                        medlem_id: competitionMemberId.toString(),
                        staevne_raekker_id: this.competitionPlayerCategoryTitleValue.value.toString(),
                        staevner_id: this.lastEditedCompetitionResultsCompetitionId.toString(),
                        staevne_resultater_dato: this.competitionResultsData.competitionResultsDate,
                        /* staevne_ranglistepoint: (Number(this.csvInputDataObj[csvObjIndex].points) + Number(this.csvInputDataObj[csvObjIndex].pointoffset)) */
                        staevne_ranglistepoint: (competitionMemberId === this.idOfGenericMember ? 0 : (Number(objItem.points) + Number(objItem.pointoffset))),
                        staevne_ranglistepoint_oprindelig: (competitionMemberId === this.idOfGenericMember ? 0 : Number(objItem.points)),
                        staevne_resultater_navn: (objItem.navn !== null ? csvInputDataNameFieldValueFixedup : ''),
                        staevne_resultater_placering: objItem.place
                      }

                      console.log('[saveUpdateCompetitionResults] ' + JSON.stringify(createCompetitionResultsData))

                      Competitions.CompetitionResultsDataService.create(createCompetitionResultsData)
                        .then((response) => {
                          // this.lastUpdatedCompetitionResultId = response.data.id
                          console.log(response.data)

                          if (csvObjIndex >= this.csvInputDataObj.length) {
                            this.editCompetitionResultsModal = false
                          }
                        })
                        .catch((err) => {
                          this.error = err
                          // console.log(err)
                        })
                    }
                  })
                  .catch((err) => {
                    this.error = err
                  })
              } else {
                // Else search for matching member id value in the existing competition results data.
                Competitions.CompetitionResultsDataService.findByCompetitionRowAndMember(true, true, this.competitionResultsData.competitionResultsDate, '', null, this.lastEditedCompetitionResultsCompetitionId, Number(this.competitionPlayerCategoryTitleValue.value), competitionMemberId)
                  .then((response) => {
                    this.tempCompetitionResults = response.data
                    console.log(response.data)

                    // if (this.tempCompetitionResults.length > 0 && this.tempCompetitionResults[0].id !== null && this.csvInputDataObj[csvObjIndex].points !== undefined && this.csvInputDataObj[csvObjIndex].pointoffset !== undefined) {
                    if (this.tempCompetitionResults.length > 0 && this.tempCompetitionResults[0].id !== null) {
                      // Update an existing competition result entry.
                      console.log('[saveUpdateCompetitionResults] Update an existing competition result entry for member with Id: ' + competitionMemberId + ' and for existing result with Id: ' + Number(this.tempCompetitionResults[0].id).toString())

                      const updateCompetitionResultsData = {
                        staevne_resultater_status: true,
                        medlem_id: competitionMemberId.toString(),
                        staevne_raekker_id: this.competitionPlayerCategoryTitleValue.value.toString(),
                        staevner_id: this.lastEditedCompetitionResultsCompetitionId.toString(),
                        staevne_resultater_dato: this.competitionResultsData.competitionResultsDate,
                        /* staevne_ranglistepoint: (Number(this.csvInputDataObj[csvObjIndex].points) + Number(this.csvInputDataObj[csvObjIndex].pointoffset)) */
                        staevne_ranglistepoint: (competitionMemberId === this.idOfGenericMember ? 0 : (Number(objItem.points) + Number(objItem.pointoffset))),
                        staevne_ranglistepoint_oprindelig: (competitionMemberId === this.idOfGenericMember ? 0 : Number(objItem.points)),
                        staevne_resultater_navn: (objItem.navn !== null ? csvInputDataNameFieldValueFixedup : ''),
                        staevne_resultater_placering: objItem.place
                      }

                      console.log('[saveUpdateCompetitionResults] ' + JSON.stringify(updateCompetitionResultsData))

                      Competitions.CompetitionResultsDataService.update(Number(this.tempCompetitionResults[0].id).toString(), updateCompetitionResultsData)
                        .then((response) => {
                          // this.lastUpdatedCompetitionResultId = response.data.id
                          console.log(response.data)

                          if (csvObjIndex >= this.csvInputDataObj.length) {
                            this.editCompetitionResultsModal = false
                          }
                        })
                        .catch((err) => {
                          this.error = err
                          // console.log(err)
                        })
                    } else {
                      // Create a new competition result entry.
                      console.log('[saveUpdateCompetitionResults] Create a new competition result entry for member with Id: ' + competitionMemberId)

                      const createCompetitionResultsData = {
                        staevne_resultater_status: true,
                        medlem_id: competitionMemberId.toString(),
                        staevne_raekker_id: this.competitionPlayerCategoryTitleValue.value.toString(),
                        staevner_id: this.lastEditedCompetitionResultsCompetitionId.toString(),
                        staevne_resultater_dato: this.competitionResultsData.competitionResultsDate,
                        /* staevne_ranglistepoint: (Number(this.csvInputDataObj[csvObjIndex].points) + Number(this.csvInputDataObj[csvObjIndex].pointoffset)) */
                        staevne_ranglistepoint: (competitionMemberId === this.idOfGenericMember ? 0 : (Number(objItem.points) + Number(objItem.pointoffset))),
                        staevne_ranglistepoint_oprindelig: (competitionMemberId === this.idOfGenericMember ? 0 : Number(objItem.points)),
                        staevne_resultater_navn: (objItem.navn !== null ? csvInputDataNameFieldValueFixedup : ''),
                        staevne_resultater_placering: objItem.place
                      }

                      console.log('[saveUpdateCompetitionResults] ' + JSON.stringify(createCompetitionResultsData))

                      Competitions.CompetitionResultsDataService.create(createCompetitionResultsData)
                        .then((response) => {
                          // this.lastUpdatedCompetitionResultId = response.data.id
                          console.log(response.data)

                          if (csvObjIndex >= this.csvInputDataObj.length) {
                            this.editCompetitionResultsModal = false
                          }
                        })
                        .catch((err) => {
                          this.error = err
                          // console.log(err)
                        })
                    }
                  })
                  .catch((err) => {
                    this.error = err
                  })
              }
            }
            tempMedlemmers = []
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      }

      csvObjIndex++
    }
  }

  public cancelCompetitionRowEdit () : void {
    console.log('Cancel competition row item edit.')
    this.editCompetitionRowModal = false
    this.competitionRowData = { competitionRowStatus: true, competitionRowName: '', competitionRegistrationFee: 0, competitions: [] }
  }

  public cancelCompetitionRankingsPointEdit () : void {
    console.log('Cancel competition rankings point item edit.')
    this.editCompetitionRankingsPointModal = false
    this.competitionRankingsPointData = { competitionRankingsPointStatus: true, competitionPlacement: 0, competitionRankingsPointPoint: 0 }
  }

  public cancelCompetitionResultsEdit () : void {
    console.log('Cancel competition rankings point item edit.')
    this.editCompetitionResultsModal = false
    // this.competitionResultsData = { competitionResultsInput: '', competitionResultsDate: '' }
    this.csvInputDataObj = []

    // Dart connect result Variables
    this.dartConnectResultsData = null
    this.tempCompetitionResultsData = []
    this.isGenerateResultsDisabled = true
    this.isFromCsv = false
    this.isFromDartConnect = false
    this.selectedEventsData = {}
  }

  public statusCompetition (competitionId : number) : void {
    console.log('Change status of competition item with Id: ' + competitionId)

    Competitions.CompetitionsDataService.get(competitionId.toString())
      .then((response) => {
        this.tempCompetition = response.data
        console.log(response.data)

        const updateCompetitionData = {
          staevne_status: !this.tempCompetition.staevne_status
        }

        console.log('New status of the competition = ' + updateCompetitionData.staevne_status)

        Competitions.CompetitionsDataService.update(competitionId.toString(), updateCompetitionData)
          .then((response) => {
            // this.lastUpdatedCompetitionRowId = response.data.id
            console.log(response.data)

            const staevnersIndexToUpdateStatus = this.staevners.findIndex(x => x.id === competitionId)
            this.staevners[staevnersIndexToUpdateStatus].staevne_status = updateCompetitionData.staevne_status
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public statusCompetitionRow (competitionRowId : number) : void {
    console.log('Change status of competition row item with Id: ' + competitionRowId)

    Competitions.CompetitionRowsDataService.get(competitionRowId.toString())
      .then((response) => {
        this.tempCompetitionRow = response.data
        console.log(response.data)

        const updateCompetitionRowData = {
          staevne_raekker_status: !this.tempCompetitionRow.staevne_raekker_status
        }

        console.log('New status of the competition row = ' + updateCompetitionRowData.staevne_raekker_status)

        Competitions.CompetitionRowsDataService.update(competitionRowId.toString(), updateCompetitionRowData)
          .then((response) => {
            // this.lastUpdatedCompetitionRowId = response.data.id
            console.log(response.data)

            // const competitionRowsRowsIndexToUpdateStatus = this.competitionRowsRows.findIndex(x => x.id === competitionRowId)
            for (let competitionRowsRowsIndexToUpdateStatus = 0; competitionRowsRowsIndexToUpdateStatus < this.competitionRowsRows.length; competitionRowsRowsIndexToUpdateStatus++) {
              if (this.competitionRowsRows[competitionRowsRowsIndexToUpdateStatus].id === competitionRowId) { this.competitionRowsRows[competitionRowsRowsIndexToUpdateStatus].staevne_raekker_status = updateCompetitionRowData.staevne_raekker_status }
            }
            const staevneraekkersIndexToUpdateStatus = this.staevneraekkers.findIndex(x => x.id === competitionRowId)
            this.staevneraekkers[staevneraekkersIndexToUpdateStatus].staevne_raekker_status = updateCompetitionRowData.staevne_raekker_status
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public statusCompetitionRankingsPoint (competitionRankingsPointId: number) : void {
    console.log('Change status of competition item with Id: ' + competitionRankingsPointId)

    Competitions.CompetitionRankingsPointsDataService.get(competitionRankingsPointId.toString())
      .then((response) => {
        this.tempCompetitionRankingsPoint = response.data
        console.log(response.data)

        const updateCompetitionRankingsPointData = {
          staevne_ranglistepoint_status: !this.tempCompetitionRankingsPoint.staevne_ranglistepoint_status
        }

        console.log('New status of the competition rankings point = ' + updateCompetitionRankingsPointData.staevne_ranglistepoint_status)

        Competitions.CompetitionRankingsPointsDataService.update(competitionRankingsPointId.toString(), updateCompetitionRankingsPointData)
          .then((response) => {
            // this.lastUpdatedCompetitionRankingsPointId = response.data.id
            console.log(response.data)
            const staevneranglistepointsIndexToUpdateStatus = this.staevneranglistepoints.findIndex(x => x.id === competitionRankingsPointId)
            this.staevneranglistepoints[staevneranglistepointsIndexToUpdateStatus].staevne_ranglistepoint_status = updateCompetitionRankingsPointData.staevne_ranglistepoint_status
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public deleteCompetitionWarning (competitionId: number) : void {
    this.deleteCompetitionId = competitionId
    this.deleteCompetitionWarningModal = true
  }

  public deleteCompetition (competitionId : number) : void {
    let tempCompetitionResults: SysCompetitionResults[] = []
    let tempCompetitionRegistrations: SysCompetitionRegistration[] = []

    // First check if the competition have any results, and prevent deleteing it if true
    Competitions.CompetitionResultsDataService.getAll('', null, `staevner_id.id=${competitionId.toString()}`)
      .then((response) => {
        tempCompetitionResults = response.data
      })
      .catch((err) => {
        this.error = err
      })
      .then(() => {
        if (!(tempCompetitionResults.length > 0)) {
          Competitions.CompetitionMemberRegistrationDataService.getAll('', null, `staevner_id.id=${competitionId.toString()}`)
            .then((response) => {
              tempCompetitionRegistrations = response.data
              if (tempCompetitionRegistrations.length > 0) {
                for (const item of tempCompetitionRegistrations) {
                  console.log('Deleting registration with id: ' + item.id)

                  Competitions.CompetitionMemberRegistrationDataService.delete(Number(item.id).toString()) // Deleting the registration of the competition to be deleted
                    .then((response) => {
                      console.log(response.data)
                    })
                    .catch((err) => {
                      this.error = err
                    })
                }
              }
            })
            .catch((err) => {
              this.error = err
            })
        }
      })
      .then(() => {
        if (!(tempCompetitionResults.length > 0)) {
          console.log('Delete competition item with Id: ' + competitionId)

          Competitions.CompetitionsDataService.delete(competitionId.toString())
            .then((response) => {
              console.log(response.data)

              this.retrieveCompetitions()
              this.$Message.success({ text: 'Stævnet er blevet slettet' })
              this.deleteCompetitionWarningModal = false
            })
            .catch((err) => {
              this.error = err
              // console.log(err)
            })
        } else {
          this.$Message.warning({ text: 'Kan ikke slettes, da der er kommet resultater' })
          this.deleteCompetitionWarningModal = false
        }
      })
  }

  public deleteCompetitionRow (competitionRowId : number) : void {
    console.log('Delete competition row item with Id: ' + competitionRowId)

    Competitions.CompetitionRowsDataService.delete(competitionRowId.toString())
      .then((response) => {
        console.log(response.data)
        this.retrieveCompetitionRows()
      })
      .catch((err) => {
        this.error = err
        // console.log(err)
      })
  }

  public createCompetition () : void {
    console.log('Create competition item.')
    let tempCompetitions: SysCompetition[] = []
    const tempCompetitionRowIds = this.generateValueArrayFromObjectArray(this.competitionRowsNameValue)

    Competitions.CompetitionsDataService.findByNameMatch(true, true, this.competitionData.competitionName)
      .then(async (response) => {
        tempCompetitions = response.data
        console.log(response.data)
        let competitionRowIdMatchFlag = false

        if (tempCompetitions !== undefined && tempCompetitions.length > 0) {
          for (let arrIndex = 0; arrIndex < tempCompetitionRowIds.length; arrIndex++) {
            for (let arrIndex2 = 0; arrIndex2 < tempCompetitions.length; arrIndex2++) {
              if (tempCompetitions[arrIndex2].staevne_raekker_id.findIndex(x => x.id === Number(tempCompetitionRowIds[arrIndex])) >= 0) {
                competitionRowIdMatchFlag = true
                console.log('[createCompetition()] Id match : ' + Number(tempCompetitionRowIds[arrIndex]))
              }
            }
          }
        }
        if (competitionRowIdMatchFlag) {
          return
        }
        // Make sure that competition fields have valid data
        if (this.competitionData.competitionName.length < 1 || this.competitionData.competitionVenueLocation.length < 1 || this.competitionData.competitionPeriodBegin.length < 1 || this.competitionData.competitionPeriodEnd.length < 1 || this.competitionData.competitionLastRegistrationDate.length < 1 || this.competitionData.competitionLeader.length < 1 || this.competitionData.competitionUmpire.length < 1 || this.competitionClubsNameValue.value === 0 || this.productTypeValue.value === 0 || this.competitionRowsNameValue.length === 0) {
          this.$Message.warning({ text: 'Fejl: Et eller flere felter er ikke udfyldt korrekt' })
          return
        }
        const createProductPromise = async (params: any) => {
          return Products.ProductsDataService.create(params)
        }

        const competitionProductId: number[] = []
        const promises: Promise<any>[] = []

        // First for each row, create a coresponding product
        for (let i = 0; i < this.competitionRowsNameValue.length; i++) {
          const createProduct = {
            produkt_status: true,
            produkt_navn: this.competitionData.competitionName + ', ' + this.getRowNameFromId(Number(this.competitionRowsNameValue[i].value)),
            produkt_pris: this.competitionRowsNameValue[i].price,
            produkt_datofra: (this.competitionData.competitionRegistrationFrom.length < 1 ? (new Date()).toISOString().split('T')[0] : (new Date(this.competitionData.competitionRegistrationFrom)).toISOString().split('T')[0]),
            produkt_datotil: this.competitionData.competitionLastRegistrationDate,
            produkt_beskrivelse: 'Tilmelding til ' + this.getRowNameFromId(Number(this.competitionRowsNameValue[i].value)),
            user_id: 3, /* Fixme: should be the logged in user */
            produkt_type_id: this.productTypeValue.value
          }
          promises.push(createProductPromise(createProduct))
        }

        await Promise.all(promises)
          .then((response) => {
            for (let i = 0; i < response.length; i++) {
              competitionProductId.push(Number(response[i].data.id))
            }

            const createCompetitionData = {
              staevne_status: this.competitionData.competitionStatus,
              staevne_navn: this.competitionData.competitionName,
              staevne_kat2: this.competitionRegionLimitValue.toString(),
              staevne_kategori: this.competitionCategoryTitleValue.toString(),
              staevne_sidstetilmelding: this.competitionData.competitionLastRegistrationDate,
              staevne_start: (new Date(this.competitionData.competitionPeriodBegin)).toISOString(),
              staevne_slut: (new Date(this.competitionData.competitionPeriodEnd)).toISOString(),
              staevne_spillested: this.competitionData.competitionVenueLocation,
              klubber_id: this.competitionClubsNameValue.value,
              staevne_raekker_id: tempCompetitionRowIds,
              staevne_invitation: (this.competitionData.competitionInvitation.length === 0 ? null : this.competitionData.competitionInvitation),
              staevne_staevneleder: this.competitionData.competitionLeader,
              staevne_overdommer: this.competitionData.competitionUmpire,
              produkts_id: competitionProductId,
              produkt_type_id: this.productTypeValue.value
            }

            Competitions.CompetitionsDataService.create(createCompetitionData)
              .then((response) => {
                this.lastCreatedCompetitionId = response.data.id
                console.log(response.data)
                this.$Message.success({ text: 'Stævnet er blevet oprettet' })
                // this.competitionModal = false
                this.clearCompetitionValues()
              })
              .catch((err) => {
                this.error = err
              })
          })
          .catch((err) => {
            this.error = err
          })
      })
      .catch((err) => {
        this.error = err
        // console.log(err)
        // this.submitted = true
      })
  }

  public createCompetitionRow () : void {
    console.log('Create competition row item.')
    let createCompetitionRowData = {}

    if (this.competitionRowData.competitionRowName.length > 1) {
      createCompetitionRowData = {
        staevne_raekker_status: this.competitionRowData.competitionRowStatus,
        staevne_raekker_navn: this.competitionRowData.competitionRowName,
        staevne_raekker_tilmeldingsgebyr: Number(this.competitionRowData.competitionRegistrationFee),
        staevne_raekker_type: (Number(this.competitionRowTypesNameValue.value) > 0 && Number(this.competitionRowTypesNameValue.value) < 3) ? this.competitionRowTypesNameValue.value : '0'
      }

      console.log('Attempt to create new competition row with Id: ' + (1 + this.lastCreatedCompetitionRowId))

      Competitions.CompetitionRowsDataService.create(createCompetitionRowData)
        .then((response) => {
          this.lastCreatedCompetitionId = response.data.id
          console.log(response.data)
          this.submitted2 = true
          // this.retrieveCompetitions()
          // this.retrieveCompetitionRows()
        })
        .catch((err) => {
          this.error = err
          console.log(err)
          this.submitted2 = true
        })
        .then(() => {
          this.$Message.success({ text: 'Stævnerækken er blevet oprettet' })
          this.newCreateCompetitionRow()
          this.tabValue = 'Vis og rediger stævner'
        })
    }
  }

  public clearCompetitionValues (cancel = false) : void {
    if (!cancel) {
      this.retrieveCompetitions()
    }
    this.competitionModal = false
    // console.log('Competition item created.')
    // this.retrieveCompetitionRows()
    // this.createNewCompetitionModal = false
    // this.submitted = false
    this.productTypeValue = { name: 'Vælg produktgruppe', value: 0 }
    this.competitionData = { competitionStatus: false, competitionName: '', competitionCategory: '', competitionRegistrationFrom: '', competitionLastRegistrationDate: '', competitionPeriodBegin: '', competitionPeriodEnd: '', competitionVenueLocation: '', competitionClub: 0, competitionRows: [], competitionInvitation: [], competitionLeader: '', competitionUmpire: '' }
    // this.competitionClubsNameValue = []
    this.competitionClubsNameValue = { name: 'Vælg her', value: 0 }
    this.competitionRowsNameValue = []
    this.competitionCategoryTitleValue = competitionCategoryFriendlyType.Kategori1
    this.competitionRegionLimitValue = competitionCategory2FriendlyType.alle
    this.selectedCompetitionInvitation = ''
    this.selectedFile = ''
    this.selectedTournamentOption = null
    this.tournamentEventsOption = []
    this.selectedEventsData = {}
  }

  public newCreateCompetitionRow () : void {
    console.log('Competition row item created.')
    this.submitted2 = false
    this.competitionRowData = { competitionRowStatus: true, competitionRowName: '', competitionRegistrationFee: 0, competitions: [] }
    this.retrieveCompetitionRows()
  }

  public updateCompetitionMemberRegistration () : void {
    console.log('Update competition member registration.')

    if (this.competitionRowForMemberRegistrationNameValue.name.length > 0 && Number(this.competitionRowForMemberRegistrationNameValue.value) >= 0) {
      this.retrieveMembers()
      // this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionRowId = Number(this.competitionRowForMemberRegistrationNameValue.value)
      const tempIndexOfCompetitionRowSelected = this.competitionRowForMemberRegistrationNameOptions.findIndex(x => x.value === this.competitionRowForMemberRegistrationNameValue.value)
      this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionRowId = tempIndexOfCompetitionRowSelected >= 0 ? Number(this.competitionRowForMemberRegistrationNameOptions[tempIndexOfCompetitionRowSelected].competitionrowid) : 0
      this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionId = tempIndexOfCompetitionRowSelected >= 0 ? Number(this.competitionRowForMemberRegistrationNameOptions[tempIndexOfCompetitionRowSelected].competitionid) : 0
      const tempCompetitionRowType = this.competitionRowForMemberRegistrationNameOptions[tempIndexOfCompetitionRowSelected].competitionrowtype
      this.competitionPlayerForMemberRegistrationNameMaxSelections = (tempCompetitionRowType === null || tempCompetitionRowType < 1) ? 1 : (tempCompetitionRowType === 1 ? 2 : (tempCompetitionRowType === 2 ? 4 : 0))
      this.competitionMemberForMemberRegistrationNameMaxSelections = this.competitionPlayerForMemberRegistrationNameMaxSelections
      this.competitionForeignPlayerForMemberRegistrationNameMaxSelections = 0
      console.log('competitionPlayerForMemberRegistrationNameMaxSelections = ' + this.competitionPlayerForMemberRegistrationNameMaxSelections)
      this.competitionPlayerDisregardForeignPlayers = (tempCompetitionRowType > 1)
      this.competitionPlayerModusSelectedOption = '0'
      // this.updateCompetitionMemberRegistrationModal = true
    }
  }

  public newUpdateCompetitionMemberRegistration () : void {
    console.log('Competition registration of the member was updated.')
    this.submitted3 = false
    this.competitionMemberRegistrationData = { competitionMemberRegistrationStatus: true, competitionMemberRegistrationMembers: [0], competitionForeignPlayerRegistrationMembers: [0], competitionMemberRegistrationCompetitionRowId: 0, competitionMemberRegistrationCompetitionId: 0, competitionMemberRegistrationAddRemoveStatus: true }
    this.competitionMemberForMemberRegistrationNameValue = []
    this.checkPlayerRestrictionsFlag = 0
  }

  public updateCompetition () : void {
    console.log('Update information of competition item with Id: ' + this.lastEditedCompetitionId)

    // const mediaUploadResData: any[] = []
    // this.updateCompetitionMethodStatus = 0
    let tempCompetitions: SysCompetition[] = []
    const tempCompetitionRowIds = this.generateValueArrayFromObjectArray(this.competitionRowsNameValue)
    const tempCompetitionProducts: number[] = []

    Competitions.CompetitionsDataService.findByNameMatch(true, true, this.competitionData.competitionName)
      .then(async (response) => {
        tempCompetitions = response.data
        console.log(response.data)
        let competitionRowIdMatchFlag = false
        // Not sure why this is nessesary
        if (tempCompetitions !== undefined && tempCompetitions.length > 0) {
          for (let arrIndex = 0; arrIndex < tempCompetitionRowIds.length; arrIndex++) {
            for (let arrIndex2 = 0; arrIndex2 < tempCompetitions.length; arrIndex2++) {
              if (tempCompetitions[arrIndex2].id !== this.lastEditedCompetitionId && tempCompetitions[arrIndex2].staevne_raekker_id.findIndex(x => x.id === Number(tempCompetitionRowIds[arrIndex])) >= 0) {
                competitionRowIdMatchFlag = true
                console.log('[updateCompetition()] Id match : ' + Number(tempCompetitionRowIds[arrIndex]))
              }
            }
          }
        }
        if (competitionRowIdMatchFlag) {
          return
        }

        if (this.competitionData.competitionName === '' || this.competitionData.competitionVenueLocation === '' || this.competitionData.competitionPeriodBegin === '' || this.competitionData.competitionPeriodEnd === '' || this.competitionData.competitionLastRegistrationDate === '' || this.competitionClubsNameValue.value === 0 || this.productTypeValue.value === 0 || this.competitionData.competitionLeader === '' || this.competitionData.competitionUmpire === '' || this.competitionRowsNameValue.length === 0) {
          this.$Message.warning({ text: 'Fejl: Et eller flere felter er ikke udfyldt korrekt' })
          return
        }

        const createNewProductPromise = async (params: any) => {
          return Products.ProductsDataService.create(params)
        }

        const updateProductsPromise = async (id: number, params: any) => {
          return Products.ProductsDataService.update(id.toString(), params)
        }
        const promises: Promise<any>[] = []
        // Loop through competitionRowsNameValue, to se if any new rows, have been added, and updates all the others
        for (const row of this.competitionRowsNameValue) {
          const productIndex = this.tempCompetition.produkts_id.findIndex(el => el.produkt_navn === (this.competitionData.competitionName + ', ' + row.name))
          const productsObj = {
            produkt_status: true,
            produkt_pris: row.price,
            produkt_datotil: this.competitionData.competitionLastRegistrationDate,
            produkt_datofra: (this.competitionData.competitionRegistrationFrom.length < 1 ? (new Date()).toISOString().split('T')[0] : this.competitionData.competitionRegistrationFrom),
            produkt_navn: this.competitionData.competitionName + ', ' + row.name,
            produkt_beskrivelse: 'Tilmelding til ' + row.name,
            user_id: 3, /* Fixme: should be the logged in user */
            produkt_type_id: this.productTypeValue.value
          }
          // First case, the row have a product ascociated with it. If this is the case update products in db.
          if (row.productId !== null) {
            promises.push(updateProductsPromise(row.productId, productsObj))
          }

          // Second case, the row have product ascociated but it is not present on the competitionRowsNameValue. This happens, if a row have been deselected then seletected again.
          if (row.productId === null && productIndex !== -1) {
            const productId = Number(this.tempCompetition.produkts_id[productIndex].id)
            promises.push(updateProductsPromise(productId, productsObj))
          }

          // Third case, a new Row have been added to the array, in this case create a new Product from the data
          if (row.productId === null && productIndex === -1) {
            promises.push(createNewProductPromise(productsObj))
          }
        }

        await Promise.all(promises)
          .then((response) => {
            for (let i = 0; i < response.length; i++) {
              tempCompetitionProducts.push(Number(response[i].data.id))
            }
            // After the products have been updated, update the competition
            const updateCompetitionData = {
              staevne_status: this.competitionData.competitionStatus,
              staevne_navn: this.competitionData.competitionName,
              staevne_kategori: this.competitionCategoryTitleValue.toString(),
              staevne_kat2: this.competitionRegionLimitValue.toString(),
              staevne_sidstetilmelding: this.competitionData.competitionLastRegistrationDate,
              staevne_start: (new Date(this.competitionData.competitionPeriodBegin)).toISOString(),
              staevne_slut: (new Date(this.competitionData.competitionPeriodEnd)).toISOString(),
              staevne_spillested: this.competitionData.competitionVenueLocation,
              klubber_id: this.competitionClubsNameValue.value,
              produkts_id: tempCompetitionProducts,
              staevne_raekker_id: this.generateValueArrayFromObjectArray(this.competitionRowsNameValue),
              // staevne_invitation: mediaUploadResData[0]
              staevne_invitation: (this.competitionData.competitionInvitation.length === 0 ? null : this.competitionData.competitionInvitation),
              staevne_staevneleder: this.competitionData.competitionLeader,
              staevne_overdommer: this.competitionData.competitionUmpire,
              produkt_type_id: this.productTypeValue.value
            }

            Competitions.CompetitionsDataService.update(this.lastEditedCompetitionId.toString(), updateCompetitionData)
              .then((response) => {
                console.log(response.data)
                // this.retrieveCompetitions()
                this.$Message.success({ text: 'Stævne er blevet opdateret' })
                this.clearCompetitionValues()
                // this.competitionModal = false
              })
              .catch((err) => {
                this.error = err
              })
          })
          .catch((err) => {
            this.error = err
          })
      })
      .catch((err) => {
        this.error = err
      })
  }
  // setTimeout(() => {
  //   if (this.finishedUploadFlag === true) {
  // }, 5000)

  public updateCompetitionRow () : boolean {
    console.log('Update information of competition row item with Id: ' + this.lastEditedCompetitionRowId)

    if (this.competitionRowData.competitionRowName.length > 1 && !isNaN(Number(this.competitionRowData.competitionRegistrationFee.toString()))) {
      const updateCompetitionRowData = {
        staevne_raekker_status: this.competitionRowData.competitionRowStatus,
        staevne_raekker_navn: this.competitionRowData.competitionRowName,
        staevne_raekker_tilmeldingsgebyr: Number(this.competitionRowData.competitionRegistrationFee)
      }

      Competitions.CompetitionRowsDataService.update(this.lastEditedCompetitionRowId.toString(), updateCompetitionRowData)
        .then((response) => {
          // this.lastUpdateCompetitionRowId = response.data.id
          console.log(response.data)
          this.retrieveCompetitionRows()
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })

      this.editCompetitionRowModal = false
      this.competitionRowData = { competitionRowStatus: true, competitionRowName: '', competitionRegistrationFee: 0, competitions: [] }

      return true
    }

    return false
  }

  public updateCompetitionRankingsPoint () : boolean {
    console.log('Update information of competition rankings point item with Id: ' + this.lastEditedCompetitionRankingsPointId)

    if (this.competitionCategoryTitleValue.toString().trim().length > 0 && !isNaN(Number(this.competitionRankingsPointData.competitionRankingsPointPoint.toString())) && this.competitionRankingsPointData.competitionRankingsPointPoint > 0) {
      const updateCompetitionRankingsPointData = {
        staevne_ranglistepoint_status: this.competitionRankingsPointData.competitionRankingsPointStatus,
        staevne_ranglistepoint_kategori: this.competitionCategoryTitleValue,
        staevne_ranglistepoint_point: Number(this.competitionRankingsPointData.competitionRankingsPointPoint)
      }

      Competitions.CompetitionRankingsPointsDataService.update(this.lastEditedCompetitionRankingsPointId.toString(), updateCompetitionRankingsPointData)
        .then((response) => {
          // this.lastUpdateCompetitionRankingsPointId = response.data.id
          console.log(response.data)
          this.retrieveCompetitionRankingsPoints()
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })

      this.editCompetitionRankingsPointModal = false
      this.competitionRankingsPointData = { competitionRankingsPointStatus: true, competitionPlacement: 0, competitionRankingsPointPoint: 0 }
      return true
    }

    return false
  }

  public updateCompetitionMemberRegistrationEdit () : boolean {
    console.log('[updateCompetitionMemberRegistrationEdit()] Competition registration of the member is being updated.')
    const isCompetitionWithMemberPlayers = (Number(this.competitionPlayerModusSelectedOption) >= 0 && Number(this.competitionPlayerModusSelectedOption) <= 1)
    const isCompetitionWithForeignPlayers = (this.competitionPlayerDisregardForeignPlayers === false && Number(this.competitionPlayerModusSelectedOption) > 0)

    // Don't do anything if no member has been selected.
    if ((isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers === false && this.competitionMemberForMemberRegistrationNameValue.length > 0) ||
      (isCompetitionWithMemberPlayers === false && isCompetitionWithForeignPlayers && this.competitionForeignPlayerForMemberRegistrationNameValue.length > 0) ||
      (isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers && this.competitionMemberForMemberRegistrationNameValue.length > 0 && this.competitionForeignPlayerForMemberRegistrationNameValue.length > 0)) {
      const searchOfCompetitionRegistrationOptionsMatchCompetitionRowAtLeastOnce: boolean[] = []
      const searchOfCompetitionFPRegistrationOptionsMatchCompetitionRowAtLeastOnce: boolean[] = []
      let searchOfCompetitionRegistrationMatchCompetitionAtLeastOnce = false
      const indexOfCompetitionRegistrations = this.staevneraekkers.findIndex(x => x.id === this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionRowId)
      const tempIndexOfPreLoadedCompetition = this.staevners.findIndex(x => x.id === this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionId)
      const indexOfCompetitionMemberOptions: number[] = []
      const indexOfCompetitionForeignPlayerOptions: number[] = []
      this.competitionMemberRegistrationData.competitionMemberRegistrationMembers = []
      this.competitionMemberRegistrationData.competitionForeignPlayerRegistrationMembers = []
      this.checkPlayerRestrictionsFlag = 0

      for (let arrIndex = 0; arrIndex < this.competitionMemberForMemberRegistrationNameValue.length; arrIndex++) {
        indexOfCompetitionMemberOptions[arrIndex] = this.competitionMemberForMemberRegistrationNameOptions.findIndex(x => x.value === this.competitionMemberForMemberRegistrationNameValue[arrIndex].value)
        this.competitionMemberRegistrationData.competitionMemberRegistrationMembers[arrIndex] = this.competitionMemberForMemberRegistrationNameValue[arrIndex].value

        // Do some sanity checks on the selected players.
        if (indexOfCompetitionRegistrations >= 0) {
          // If the competition row is a 'dame' or 'pige' row, then check that the gender matches with the member.
          if (this.staevneraekkers[indexOfCompetitionRegistrations].staevne_raekker_navn.trim().toLocaleLowerCase().includes('dame') || this.staevneraekkers[indexOfCompetitionRegistrations].staevne_raekker_navn.trim().toLocaleLowerCase().includes('pige')) {
            if (Number(this.competitionMemberForMemberRegistrationNameValue[arrIndex].gender) !== 1) {
              // Indicate that the check failed.
              this.checkPlayerRestrictionsFlag |= PlayerRestrictionsCheckFailFlag.gender
            }
          }

          // If the competition row is a 'herre' or 'drenge' row, then check that the gender matches with the member.
          if (this.staevneraekkers[indexOfCompetitionRegistrations].staevne_raekker_navn.trim().toLocaleLowerCase().includes('herre') || this.staevneraekkers[indexOfCompetitionRegistrations].staevne_raekker_navn.trim().toLocaleLowerCase().includes('drenge')) {
            if (Number(this.competitionMemberForMemberRegistrationNameValue[arrIndex].gender) !== 2) {
              // Indicate that the check failed.
              this.checkPlayerRestrictionsFlag |= PlayerRestrictionsCheckFailFlag.gender
            }
          }

          // If the competition row is a 'mix double' row, then check that the members are of opposite gender and that there no more than two players registered in total.
          if (this.staevneraekkers[indexOfCompetitionRegistrations].staevne_raekker_navn.trim().toLocaleLowerCase().includes('mix') && this.staevneraekkers[indexOfCompetitionRegistrations].staevne_raekker_navn.trim().toLocaleLowerCase().includes('double')) {
            if (arrIndex > 0 && this.competitionMemberForMemberRegistrationNameValue[arrIndex].gender === this.competitionMemberForMemberRegistrationNameValue[arrIndex - 1].gender) {
              // Indicate that the check failed.
              this.checkPlayerRestrictionsFlag |= PlayerRestrictionsCheckFailFlag.gendermixdouble
            }
          }

          // If the competition row is a 'oldboys' row, then check that the members age is at least 35 years old.
          if (tempIndexOfPreLoadedCompetition >= 0 && this.staevneraekkers[indexOfCompetitionRegistrations].staevne_raekker_navn.trim().toLocaleLowerCase().includes('oldboys')) {
            if (BaseFunctions.calculateAge(this.competitionMemberForMemberRegistrationNameValue[arrIndex].birthdaydate, this.staevners[tempIndexOfPreLoadedCompetition].staevne_start) < 35) {
              // Indicate that the check failed.
              this.checkPlayerRestrictionsFlag |= PlayerRestrictionsCheckFailFlag.oldboysage
            }
          }

          // If the competition row is a 'drenge' or 'pige' row, then check that the members age is below 18 years old.
          if (tempIndexOfPreLoadedCompetition >= 0 && (this.staevneraekkers[indexOfCompetitionRegistrations].staevne_raekker_navn.trim().toLocaleLowerCase().includes('drenge') || this.staevneraekkers[indexOfCompetitionRegistrations].staevne_raekker_navn.trim().toLocaleLowerCase().includes('pige'))) {
            if (BaseFunctions.calculateAge(this.competitionMemberForMemberRegistrationNameValue[arrIndex].birthdaydate, this.staevners[tempIndexOfPreLoadedCompetition].staevne_start) >= 18) {
              // Indicate that the check failed.
              this.checkPlayerRestrictionsFlag |= PlayerRestrictionsCheckFailFlag.juniorage
            }
          }

          // If the competition row is a 'paradart' row, then check that the member is a paradart player.
          if (this.staevneraekkers[indexOfCompetitionRegistrations].staevne_raekker_navn.trim().toLocaleLowerCase().includes('paradart')) {
            if (this.competitionMemberForMemberRegistrationNameValue[arrIndex].isparadart === false) {
              // Indicate that the check failed.
              this.checkPlayerRestrictionsFlag |= PlayerRestrictionsCheckFailFlag.paradart
            }
          }
        }

        console.log('[updateCompetitionMemberRegistrationEdit()] checkPlayerRestrictionsFlag = ' + this.checkPlayerRestrictionsFlag)
      }
      if (isCompetitionWithForeignPlayers) {
        for (let arrIndex = 0; arrIndex < this.competitionForeignPlayerForMemberRegistrationNameValue.length; arrIndex++) {
          indexOfCompetitionForeignPlayerOptions[arrIndex] = this.competitionForeignPlayerForMemberRegistrationNameOptions.findIndex(x => x.value === this.competitionForeignPlayerForMemberRegistrationNameValue[arrIndex].value)
          this.competitionMemberRegistrationData.competitionForeignPlayerRegistrationMembers[arrIndex] = this.competitionForeignPlayerForMemberRegistrationNameValue[arrIndex].value

          if (indexOfCompetitionRegistrations >= 0) {
            // If the competition row is a 'dame' or 'pige' row, then check that the gender matches with the member.
            if (this.staevneraekkers[indexOfCompetitionRegistrations].staevne_raekker_navn.trim().toLocaleLowerCase().includes('dame') || this.staevneraekkers[indexOfCompetitionRegistrations].staevne_raekker_navn.trim().toLocaleLowerCase().includes('pige')) {
              if (Number(this.competitionForeignPlayerForMemberRegistrationNameValue[arrIndex].gender) !== 1) {
                // Indicate that the check failed.
                this.checkPlayerRestrictionsFlag |= PlayerRestrictionsCheckFailFlag.gender
              }
            }

            // If the competition row is a 'herre' or 'drenge' row, then check that the gender matches with the member.
            if (this.staevneraekkers[indexOfCompetitionRegistrations].staevne_raekker_navn.trim().toLocaleLowerCase().includes('herre') || this.staevneraekkers[indexOfCompetitionRegistrations].staevne_raekker_navn.trim().toLocaleLowerCase().includes('drenge')) {
              if (Number(this.competitionForeignPlayerForMemberRegistrationNameValue[arrIndex].gender) !== 2) {
                // Indicate that the check failed.
                this.checkPlayerRestrictionsFlag |= PlayerRestrictionsCheckFailFlag.gender
              }
            }

            // If the competition row is a 'mix double' row, then check that the members are of opposite gender and that there no more than two players registered in total.
            if (this.staevneraekkers[indexOfCompetitionRegistrations].staevne_raekker_navn.trim().toLocaleLowerCase().includes('mix') && this.staevneraekkers[indexOfCompetitionRegistrations].staevne_raekker_navn.trim().toLocaleLowerCase().includes('double')) {
              if (arrIndex > 0 && this.competitionForeignPlayerForMemberRegistrationNameValue[arrIndex].gender === this.competitionForeignPlayerForMemberRegistrationNameValue[arrIndex - 1].gender) {
                // Indicate that the check failed.
                this.checkPlayerRestrictionsFlag |= PlayerRestrictionsCheckFailFlag.gendermixdouble
              }
            }

            // If the competition row is a 'oldboys' row, then check that the members age is at least 35 years old.
            if (tempIndexOfPreLoadedCompetition >= 0 && this.staevneraekkers[indexOfCompetitionRegistrations].staevne_raekker_navn.trim().toLocaleLowerCase().includes('oldboys')) {
              if (BaseFunctions.calculateAge(this.competitionForeignPlayerForMemberRegistrationNameValue[arrIndex].birthdaydate, this.staevners[tempIndexOfPreLoadedCompetition].staevne_start) < 35) {
                // Indicate that the check failed.
                this.checkPlayerRestrictionsFlag |= PlayerRestrictionsCheckFailFlag.oldboysage
              }
            }

            // If the competition row is a 'drenge' or 'pige' row, then check that the members age is below 18 years old.
            if (tempIndexOfPreLoadedCompetition >= 0 && (this.staevneraekkers[indexOfCompetitionRegistrations].staevne_raekker_navn.trim().toLocaleLowerCase().includes('drenge') || this.staevneraekkers[indexOfCompetitionRegistrations].staevne_raekker_navn.trim().toLocaleLowerCase().includes('pige'))) {
              if (BaseFunctions.calculateAge(this.competitionForeignPlayerForMemberRegistrationNameValue[arrIndex].birthdaydate, this.staevners[tempIndexOfPreLoadedCompetition].staevne_start) >= 18) {
                // Indicate that the check failed.
                this.checkPlayerRestrictionsFlag |= PlayerRestrictionsCheckFailFlag.juniorage
              }
            }

            // If the competition row is a 'paradart' row, then check that the member is a paradart player.
            if (this.staevneraekkers[indexOfCompetitionRegistrations].staevne_raekker_navn.trim().toLocaleLowerCase().includes('paradart')) {
              if (this.competitionForeignPlayerForMemberRegistrationNameValue[arrIndex].isparadart === false) {
                // Indicate that the check failed.
                this.checkPlayerRestrictionsFlag |= PlayerRestrictionsCheckFailFlag.paradart
              }
            }
          }

          console.log('[updateCompetitionMemberRegistrationEdit()] checkPlayerRestrictionsFlag = ' + this.checkPlayerRestrictionsFlag)
        }
      }
      console.log('[updateCompetitionMemberRegistrationEdit()] competitionMemberForMemberRegistrationNameValue = ' + JSON.stringify(this.competitionMemberForMemberRegistrationNameValue))
      console.log('[updateCompetitionMemberRegistrationEdit()] competitionMemberRegistrationMembers = ' + JSON.stringify(this.competitionMemberRegistrationData.competitionMemberRegistrationMembers))
      console.log('[updateCompetitionMemberRegistrationEdit()] indexOfCompetitionMemberOptions = ' + JSON.stringify(indexOfCompetitionMemberOptions))
      console.log('[updateCompetitionMemberRegistrationEdit()] competitionForeignPlayerRegistrationMembers = ' + JSON.stringify(this.competitionMemberRegistrationData.competitionForeignPlayerRegistrationMembers))
      console.log('[updateCompetitionMemberRegistrationEdit()] indexOfCompetitionForeignPlayerOptions = ' + JSON.stringify(indexOfCompetitionForeignPlayerOptions))

      if (this.checkPlayerRestrictionsFlag === 0 && ((isCompetitionWithMemberPlayers ? indexOfCompetitionMemberOptions.length : 0) + (isCompetitionWithForeignPlayers ? indexOfCompetitionForeignPlayerOptions.length : 0)) > 0 &&
        ((isCompetitionWithMemberPlayers && indexOfCompetitionMemberOptions.every((indexValue) => indexValue >= 0)) ||
        (isCompetitionWithForeignPlayers && indexOfCompetitionForeignPlayerOptions.every((indexValue) => indexValue >= 0)) ||
        (isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers && indexOfCompetitionMemberOptions.every((indexValue) => indexValue >= 0) && indexOfCompetitionForeignPlayerOptions.every((indexValue) => indexValue >= 0)))) {
        // const competitionRegistrationsJSON: { id: number }[] = []
        // Fixme: this should most likely iterate over all elements of the competitionRegistrations object array in stead of just obtaining the first matching element (with findIndex). -- [DONE]
        // const indexOfCompetitionRegistrationOptions = this.competitionMemberForMemberRegistrationNameOptions[indexOfCompetitionMemberOptions].competitionRegistrations.findIndex(x => x.staevne_raekker_id === this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionRowId)
        // Search loop begin
        // const indexOfCompetitionRegistrationOptions = [[[0, 5], [1, 8]]]
        this.lastEditedCompetitionRegistrationId = 0
        const indexOfCompetitionRegistrationOptions: number[][] = []
        for (let arrIndex2 = 0; arrIndex2 < indexOfCompetitionMemberOptions.length; arrIndex2++) {
          const tempArray: number[] = []
          searchOfCompetitionRegistrationOptionsMatchCompetitionRowAtLeastOnce[arrIndex2] = false

          for (let arrIndex = 0; arrIndex < this.competitionMemberForMemberRegistrationNameOptions[indexOfCompetitionMemberOptions[arrIndex2]].competitionRegistrations.length; arrIndex++) {
            if (this.competitionMemberForMemberRegistrationNameOptions[indexOfCompetitionMemberOptions[arrIndex2]].competitionRegistrations[arrIndex].staevne_raekker_id === this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionRowId) {
              console.log('[updateCompetitionMemberRegistrationEdit()] Loop 1 check TRUE: [' + arrIndex2 + '] [' + arrIndex + ']')
              tempArray.push(arrIndex)
              searchOfCompetitionRegistrationOptionsMatchCompetitionRowAtLeastOnce[arrIndex2] = true
            } else {
              console.log('[updateCompetitionMemberRegistrationEdit()] Loop 1 check FALSE: [' + arrIndex2 + '] [' + arrIndex + ']')
            }
          }
          indexOfCompetitionRegistrationOptions[arrIndex2] = tempArray
        }
        const indexOfCompetitionFPRegistrationOptions: number[][] = []
        if (isCompetitionWithForeignPlayers) {
          for (let arrIndex2 = 0; arrIndex2 < indexOfCompetitionForeignPlayerOptions.length; arrIndex2++) {
            const tempArray: number[] = []
            searchOfCompetitionFPRegistrationOptionsMatchCompetitionRowAtLeastOnce[arrIndex2] = false

            for (let arrIndex = 0; arrIndex < this.competitionForeignPlayerForMemberRegistrationNameOptions[indexOfCompetitionForeignPlayerOptions[arrIndex2]].competitionRegistrations.length; arrIndex++) {
              if (this.competitionForeignPlayerForMemberRegistrationNameOptions[indexOfCompetitionForeignPlayerOptions[arrIndex2]].competitionRegistrations[arrIndex].staevne_raekker_id === this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionRowId) {
                console.log('[updateCompetitionMemberRegistrationEdit()] Loop 2 check TRUE: [' + arrIndex2 + '] [' + arrIndex + ']')
                tempArray.push(arrIndex)
                searchOfCompetitionFPRegistrationOptionsMatchCompetitionRowAtLeastOnce[arrIndex2] = true
              } else {
                console.log('[updateCompetitionMemberRegistrationEdit()] Loop 2 check FALSE: [' + arrIndex2 + '] [' + arrIndex + ']')
              }
            }
            indexOfCompetitionFPRegistrationOptions[arrIndex2] = tempArray
          }
        }
        // Search loop ends
        console.log('[updateCompetitionMemberRegistrationEdit()] Test search 1 result 1: ' + JSON.stringify(indexOfCompetitionRegistrationOptions))
        console.log('[updateCompetitionMemberRegistrationEdit()] Test search 1 result 2: ' + JSON.stringify(searchOfCompetitionRegistrationOptionsMatchCompetitionRowAtLeastOnce))
        console.log('[updateCompetitionMemberRegistrationEdit()] Test search 2 result 1: ' + JSON.stringify(indexOfCompetitionFPRegistrationOptions))
        console.log('[updateCompetitionMemberRegistrationEdit()] Test search 2 result 2: ' + JSON.stringify(searchOfCompetitionFPRegistrationOptionsMatchCompetitionRowAtLeastOnce))

        // Check if the selected members have at least one matching competition registration with the selected competition row.
        let competitionRegistrationIdOfMembersSelectedMatches = false
        let competitionRegistrationIdOfForeignPlayerSelectedMatches = false
        let competitionRegistrationIdOfMemberSelectedPrevious: number[] = []
        let competitionRegistrationIdOfForeignPlayerSelectedPrevious: number[] = []
        let competitionRegistrationIdOfMemberSelected: number[] = []
        let competitionRegistrationIdOfForeignPlayerSelected: number[] = []
        if (isCompetitionWithMemberPlayers) {
          for (let arrIndex4 = 0; arrIndex4 < indexOfCompetitionRegistrationOptions.length; arrIndex4++) {
            if (arrIndex4 === 0) {
              competitionRegistrationIdOfMembersSelectedMatches = true
            }
            competitionRegistrationIdOfMemberSelected = []
            for (let arrIndex5 = 0; arrIndex5 < indexOfCompetitionRegistrationOptions[arrIndex4].length; arrIndex5++) {
              competitionRegistrationIdOfMemberSelected[arrIndex5] = (indexOfCompetitionRegistrationOptions[arrIndex4][arrIndex5] !== undefined && indexOfCompetitionRegistrationOptions[arrIndex4][arrIndex5] >= 0) ? Number(this.competitionMemberForMemberRegistrationNameOptions[indexOfCompetitionMemberOptions[arrIndex4]].competitionRegistrations[indexOfCompetitionRegistrationOptions[arrIndex4][arrIndex5]].id) : 0
            }
            if (arrIndex4 > 0) {
              // Get only those Id values that matches previous match result as the new result.
              // Fixme: Id values of 0 should be ignored / skipped. [DONE]
              competitionRegistrationIdOfMemberSelected = competitionRegistrationIdOfMemberSelected.filter(e => competitionRegistrationIdOfMemberSelectedPrevious.indexOf(e) !== -1).filter(e => e !== 0)
              if (competitionRegistrationIdOfMemberSelected.length === 0) {
                competitionRegistrationIdOfMembersSelectedMatches = false
              }
              console.log('[updateCompetitionMemberRegistrationEdit()] Check for matching competition registration Ids of members selected = ' + JSON.stringify(competitionRegistrationIdOfMemberSelected))
            }
            competitionRegistrationIdOfMemberSelectedPrevious = competitionRegistrationIdOfMemberSelected
          }
        }
        if (isCompetitionWithForeignPlayers) {
          for (let arrIndex4 = 0; arrIndex4 < indexOfCompetitionFPRegistrationOptions.length; arrIndex4++) {
            if (arrIndex4 === 0) {
              competitionRegistrationIdOfForeignPlayerSelectedMatches = true
            }
            competitionRegistrationIdOfForeignPlayerSelected = []
            for (let arrIndex5 = 0; arrIndex5 < indexOfCompetitionFPRegistrationOptions[arrIndex4].length; arrIndex5++) {
              competitionRegistrationIdOfForeignPlayerSelected[arrIndex5] = (indexOfCompetitionFPRegistrationOptions[arrIndex4][arrIndex5] !== undefined && indexOfCompetitionFPRegistrationOptions[arrIndex4][arrIndex5] >= 0) ? Number(this.competitionForeignPlayerForMemberRegistrationNameOptions[indexOfCompetitionForeignPlayerOptions[arrIndex4]].competitionRegistrations[indexOfCompetitionFPRegistrationOptions[arrIndex4][arrIndex5]].id) : 0
            }
            if (arrIndex4 > 0) {
              // Get only those Id values that matches previous match result as the new result.
              // Fixme: Id values of 0 should be ignored / skipped. [DONE]
              competitionRegistrationIdOfForeignPlayerSelected = competitionRegistrationIdOfForeignPlayerSelected.filter(e => competitionRegistrationIdOfForeignPlayerSelectedPrevious.indexOf(e) !== -1).filter(e => e !== 0)
              if (competitionRegistrationIdOfForeignPlayerSelected.length === 0) {
                competitionRegistrationIdOfForeignPlayerSelectedMatches = false
              }
              console.log('[updateCompetitionMemberRegistrationEdit()] Check for matching competition registration Ids of foreign players selected = ' + JSON.stringify(competitionRegistrationIdOfForeignPlayerSelected))
            }
            competitionRegistrationIdOfForeignPlayerSelectedPrevious = competitionRegistrationIdOfForeignPlayerSelected
          }
        }
        console.log('[updateCompetitionMemberRegistrationEdit()] competitionRegistrationIdOfMembersSelectedMatches = ' + competitionRegistrationIdOfMembersSelectedMatches)
        console.log('[updateCompetitionMemberRegistrationEdit()] competitionRegistrationIdOfForeignPlayerSelectedMatches = ' + competitionRegistrationIdOfForeignPlayerSelectedMatches)

        if ((isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers === false && competitionRegistrationIdOfMembersSelectedMatches && searchOfCompetitionRegistrationOptionsMatchCompetitionRowAtLeastOnce.length > 0 && searchOfCompetitionRegistrationOptionsMatchCompetitionRowAtLeastOnce.every((matchValue) => matchValue === true)) ||
          (isCompetitionWithMemberPlayers === false && isCompetitionWithForeignPlayers && competitionRegistrationIdOfForeignPlayerSelectedMatches && searchOfCompetitionFPRegistrationOptionsMatchCompetitionRowAtLeastOnce.length > 0 && searchOfCompetitionFPRegistrationOptionsMatchCompetitionRowAtLeastOnce.every((matchValue) => matchValue === true)) ||
          (isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers && competitionRegistrationIdOfMembersSelectedMatches && searchOfCompetitionRegistrationOptionsMatchCompetitionRowAtLeastOnce.length > 0 && searchOfCompetitionRegistrationOptionsMatchCompetitionRowAtLeastOnce.every((matchValue) => matchValue === true) && competitionRegistrationIdOfForeignPlayerSelectedMatches && searchOfCompetitionFPRegistrationOptionsMatchCompetitionRowAtLeastOnce.length > 0 && searchOfCompetitionFPRegistrationOptionsMatchCompetitionRowAtLeastOnce.every((matchValue) => matchValue === true))) {
          Competitions.CompetitionMemberRegistrationDataService.getAll()
            .then((response) => {
              this.tempCompetitionRegistrations = response.data
              console.log(response.data)
              let skipCreateNewRegistrationEntry = false
              let foreignPlayerArrIndex = 0

              // for (let arrIndex = 0; arrIndex < Math.max(indexOfCompetitionRegistrationOptions[0].length, indexOfCompetitionFPRegistrationOptions[0].length); arrIndex++) {
              for (let arrIndex = 0; arrIndex < Math.max((indexOfCompetitionRegistrationOptions !== undefined && indexOfCompetitionRegistrationOptions[0] !== undefined ? indexOfCompetitionRegistrationOptions[0].length : 0), (indexOfCompetitionFPRegistrationOptions !== undefined && indexOfCompetitionFPRegistrationOptions[0] !== undefined ? indexOfCompetitionFPRegistrationOptions[0].length : 0)); arrIndex++) {
                console.log('[updateCompetitionMemberRegistrationEdit()] indexOfCompetitionRegistrationOptions / competitionMemberRegistrationCompetitionRowId = ' + JSON.stringify(indexOfCompetitionRegistrationOptions[arrIndex]) + ' / ' + this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionRowId)
                console.log('[updateCompetitionMemberRegistrationEdit()] indexOfCompetitionFPRegistrationOptions / competitionMemberRegistrationCompetitionRowId = ' + JSON.stringify(indexOfCompetitionFPRegistrationOptions[arrIndex]) + ' / ' + this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionRowId)
                foreignPlayerArrIndex = arrIndex

                if (isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers === false) {
                  this.lastEditedCompetitionRegistrationId = (indexOfCompetitionRegistrationOptions[0][arrIndex] !== undefined && Number(indexOfCompetitionRegistrationOptions[0][arrIndex]) >= 0) ? Number(this.competitionMemberForMemberRegistrationNameOptions[indexOfCompetitionMemberOptions[0]].competitionRegistrations[indexOfCompetitionRegistrationOptions[0][arrIndex]].id) : 0
                }
                if (isCompetitionWithMemberPlayers === false && isCompetitionWithForeignPlayers) {
                  // Also try to obtain a Id of the competition registration, if no member was selected but at least one foreign player was selected.
                  this.lastEditedCompetitionRegistrationId = (indexOfCompetitionFPRegistrationOptions[0][arrIndex] !== undefined && Number(indexOfCompetitionFPRegistrationOptions[0][arrIndex]) >= 0) ? Number(this.competitionForeignPlayerForMemberRegistrationNameOptions[indexOfCompetitionForeignPlayerOptions[0]].competitionRegistrations[indexOfCompetitionFPRegistrationOptions[0][arrIndex]].id) : 0
                }
                if (isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers) {
                  const tempLastEditedCompetitionRegistrationId = (indexOfCompetitionRegistrationOptions[0][arrIndex] !== undefined && Number(indexOfCompetitionRegistrationOptions[0][arrIndex]) >= 0) ? Number(this.competitionMemberForMemberRegistrationNameOptions[indexOfCompetitionMemberOptions[0]].competitionRegistrations[indexOfCompetitionRegistrationOptions[0][arrIndex]].id) : 0
                  const maxLoopIndex4 = (indexOfCompetitionFPRegistrationOptions !== undefined && indexOfCompetitionFPRegistrationOptions[0] !== undefined ? indexOfCompetitionFPRegistrationOptions[0].length : 0)
                  skipCreateNewRegistrationEntry = true

                  for (let arrIndex4 = 0; arrIndex4 < maxLoopIndex4; arrIndex4++) {
                    if (tempLastEditedCompetitionRegistrationId === ((indexOfCompetitionFPRegistrationOptions[0][arrIndex4] !== undefined && Number(indexOfCompetitionFPRegistrationOptions[0][arrIndex4]) >= 0) ? Number(this.competitionForeignPlayerForMemberRegistrationNameOptions[indexOfCompetitionForeignPlayerOptions[0]].competitionRegistrations[indexOfCompetitionFPRegistrationOptions[0][arrIndex4]].id) : 0)) {
                      console.log('[updateCompetitionMemberRegistrationEdit()] Value mismatch between a the member registration Id of a member and the member registration Id of a foreign player!')
                      this.lastEditedCompetitionRegistrationId = tempLastEditedCompetitionRegistrationId
                      foreignPlayerArrIndex = arrIndex4
                      skipCreateNewRegistrationEntry = false
                    } else {
                      console.log('[updateCompetitionMemberRegistrationEdit()] Value mismatch between a the member registration Id of a member and the member registration Id of a foreign player!')
                      if (arrIndex === Math.max((indexOfCompetitionRegistrationOptions !== undefined && indexOfCompetitionRegistrationOptions[0] !== undefined ? indexOfCompetitionRegistrationOptions[0].length : 0), (indexOfCompetitionFPRegistrationOptions !== undefined && indexOfCompetitionFPRegistrationOptions[0] !== undefined ? indexOfCompetitionFPRegistrationOptions[0].length : 0)) - 1) {
                        skipCreateNewRegistrationEntry = false
                      }
                    }
                    console.log('[updateCompetitionMemberRegistrationEdit()] Value of the member registration Id of a member = ' + this.lastEditedCompetitionRegistrationId)
                    console.log('[updateCompetitionMemberRegistrationEdit()] Value of the member registration Id of a foreign player = ' + ((indexOfCompetitionFPRegistrationOptions[0][arrIndex4] !== undefined && Number(indexOfCompetitionFPRegistrationOptions[0][arrIndex4]) >= 0) ? Number(this.competitionForeignPlayerForMemberRegistrationNameOptions[indexOfCompetitionForeignPlayerOptions[0]].competitionRegistrations[indexOfCompetitionFPRegistrationOptions[0][arrIndex4]].id) : 0))
                  }
                }
                console.log('[updateCompetitionMemberRegistrationEdit()] lastEditedCompetitionRegistrationId = ' + this.lastEditedCompetitionRegistrationId)

                if (this.lastEditedCompetitionRegistrationId > 0) {
                  // If there are more than one member selected for registration then try to update lastEditedCompetitionRegistrationId with the competition registration Id that is most relevant.
                  // Prefer highest matching Id value and prefer as Id that is associated with the competition that has been selected.
                  if (competitionRegistrationIdOfMemberSelected.length > 0) {
                    let lastCompetitionRegistrationIdWithMatchingCompetition = -1
                    let lastCompetitionRegistrationIdWithoutMatchingCompetition = -1

                    for (let arrIndex4 = 0; arrIndex4 < competitionRegistrationIdOfMemberSelected.length; arrIndex4++) {
                      const indexOfTempCompetitionRegistrations = this.tempCompetitionRegistrations.findIndex(x => x.id === competitionRegistrationIdOfMemberSelected[arrIndex4])
                      this.tempCompetitionRegistration = indexOfTempCompetitionRegistrations >= 0 ? this.tempCompetitionRegistrations[indexOfTempCompetitionRegistrations] : {} as SysCompetitionRegistration
                      const tempIndexOfCompetition = this.tempCompetitionRegistration.staevner_id.findIndex(x => x.id === this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionId)

                      if (tempIndexOfCompetition >= 0) {
                        lastCompetitionRegistrationIdWithMatchingCompetition = competitionRegistrationIdOfMemberSelected[arrIndex4] > lastCompetitionRegistrationIdWithMatchingCompetition ? competitionRegistrationIdOfMemberSelected[arrIndex4] : lastCompetitionRegistrationIdWithMatchingCompetition
                      } else {
                        lastCompetitionRegistrationIdWithoutMatchingCompetition = competitionRegistrationIdOfMemberSelected[arrIndex4] > lastCompetitionRegistrationIdWithoutMatchingCompetition ? competitionRegistrationIdOfMemberSelected[arrIndex4] : lastCompetitionRegistrationIdWithoutMatchingCompetition
                      }
                    }
                    if (isCompetitionWithForeignPlayers) {
                      for (let arrIndex4 = 0; arrIndex4 < competitionRegistrationIdOfForeignPlayerSelected.length; arrIndex4++) {
                        const indexOfTempCompetitionRegistrations = this.tempCompetitionRegistrations.findIndex(x => x.id === competitionRegistrationIdOfForeignPlayerSelected[arrIndex4])
                        this.tempCompetitionRegistration = indexOfTempCompetitionRegistrations >= 0 ? this.tempCompetitionRegistrations[indexOfTempCompetitionRegistrations] : {} as SysCompetitionRegistration
                        const tempIndexOfCompetition = this.tempCompetitionRegistration.staevner_id.findIndex(x => x.id === this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionId)

                        if (tempIndexOfCompetition >= 0) {
                          lastCompetitionRegistrationIdWithMatchingCompetition = competitionRegistrationIdOfForeignPlayerSelected[arrIndex4] > lastCompetitionRegistrationIdWithMatchingCompetition ? competitionRegistrationIdOfForeignPlayerSelected[arrIndex4] : lastCompetitionRegistrationIdWithMatchingCompetition
                        } else {
                          lastCompetitionRegistrationIdWithoutMatchingCompetition = competitionRegistrationIdOfForeignPlayerSelected[arrIndex4] > lastCompetitionRegistrationIdWithoutMatchingCompetition ? competitionRegistrationIdOfForeignPlayerSelected[arrIndex4] : lastCompetitionRegistrationIdWithoutMatchingCompetition
                        }
                      }
                    }

                    if (lastCompetitionRegistrationIdWithMatchingCompetition >= 0) {
                      console.log('[updateCompetitionMemberRegistrationEdit()] Matching competition - Updating lastEditedCompetitionRegistrationId = ' + lastCompetitionRegistrationIdWithMatchingCompetition)
                      this.lastEditedCompetitionRegistrationId = lastCompetitionRegistrationIdWithMatchingCompetition
                    } else {
                      if (lastCompetitionRegistrationIdWithoutMatchingCompetition >= 0) {
                        console.log('[updateCompetitionMemberRegistrationEdit()] Updating lastEditedCompetitionRegistrationId = ' + lastCompetitionRegistrationIdWithoutMatchingCompetition)
                        this.lastEditedCompetitionRegistrationId = lastCompetitionRegistrationIdWithoutMatchingCompetition
                      } else {
                        console.log('[updateCompetitionMemberRegistrationEdit()] Warning: No suitable competition registration Id for updating lastEditedCompetitionRegistrationId was found! - This should normally not happen.')
                      }
                    }
                  }

                  const indexOfTempCompetitionRegistrations = this.tempCompetitionRegistrations.findIndex(x => x.id === this.lastEditedCompetitionRegistrationId)
                  this.tempCompetitionRegistration = indexOfTempCompetitionRegistrations >= 0 ? this.tempCompetitionRegistrations[indexOfTempCompetitionRegistrations] : {} as SysCompetitionRegistration

                  // const tempIndexOfCompetitionRowSelected = this.competitionRowForMemberRegistrationNameOptions.findIndex(x => x.value === this.competitionRowForMemberRegistrationNameValue.value)
                  // const tempIndexOfCompetition = this.tempCompetitionRegistration.staevner_id.findIndex(x => x.id === Number(this.competitionRowForMemberRegistrationNameOptions[tempIndexOfCompetitionRowSelected].competitionid))
                  const tempIndexOfCompetition = this.tempCompetitionRegistration.staevner_id.findIndex(x => x.id === this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionId)

                  if (tempIndexOfCompetition >= 0) {
                    searchOfCompetitionRegistrationMatchCompetitionAtLeastOnce = true
                  }
                  if (Number(this.tempCompetitionRegistration.staevne_raekker_id.id) !== this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionRowId) {
                    console.log('[updateCompetitionMemberRegistrationEdit()] Warning: Competition row Ids do not match! ' + this.tempCompetitionRegistration.staevne_raekker_id.id + ' vs. ' + this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionRowId)
                  }

                  if (tempIndexOfCompetition >= 0 && this.competitionMemberRegistrationData.competitionMemberRegistrationAddRemoveStatus === true &&
                    ((isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers === false && indexOfCompetitionRegistrationOptions.every((indexValue) => (indexValue[arrIndex] !== undefined && indexValue[arrIndex] >= 0))) ||
                    (isCompetitionWithMemberPlayers === false && isCompetitionWithForeignPlayers && indexOfCompetitionFPRegistrationOptions.every((indexValue) => (indexValue[foreignPlayerArrIndex] !== undefined && indexValue[foreignPlayerArrIndex] >= 0))) ||
                    (isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers && indexOfCompetitionRegistrationOptions.every((indexValue) => (indexValue[arrIndex] !== undefined && indexValue[arrIndex] >= 0)) && indexOfCompetitionFPRegistrationOptions.every((indexValue) => (indexValue[foreignPlayerArrIndex] !== undefined && indexValue[foreignPlayerArrIndex] >= 0))))) {
                    // Update an existing competition registration entry.
                    console.log('[updateCompetitionMemberRegistrationEdit()] Updating an existing competition registration entry.')
                    if ((isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers === false && this.competitionMemberForMemberRegistrationNameValue[0].name.length > 1 && Number(this.competitionMemberForMemberRegistrationNameValue[0].value) > 0) ||
                      (isCompetitionWithMemberPlayers === false && isCompetitionWithForeignPlayers && this.competitionForeignPlayerForMemberRegistrationNameValue[0].name.length > 1 && Number(this.competitionForeignPlayerForMemberRegistrationNameValue[0].value) > 0) ||
                      (isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers && this.competitionMemberForMemberRegistrationNameValue[0].name.length > 1 && Number(this.competitionMemberForMemberRegistrationNameValue[0].value) > 0 && this.competitionForeignPlayerForMemberRegistrationNameValue[0].name.length > 1 && Number(this.competitionForeignPlayerForMemberRegistrationNameValue[0].value) > 0)) {
                      const updateCompetitionRegistrationData = {
                        staevne_tilmelding_status: this.competitionMemberRegistrationData.competitionMemberRegistrationStatus,
                        // medlem_id: this.competitionMemberRegistrationData.competitionMemberRegistrationMembers,
                        // staevner_id: this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionId,
                        staevne_raekker_id: this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionRowId
                      }

                      // this.lastEditedCompetitionRegistrationId = Number(this.competitionMemberForMemberRegistrationNameOptions[indexOfCompetitionMemberOptions].competitionRegistrations[indexOfCompetitionRegistrationOptions[arrIndex]].id)

                      Competitions.CompetitionMemberRegistrationDataService.update(this.lastEditedCompetitionRegistrationId.toString(), updateCompetitionRegistrationData)
                        .then((response) => {
                          // this.lastUpdateCompetitionRegistrationId = response.data.id
                          console.log(response.data)
                          this.retrieveCompetitionRows()
                          this.retrieveCompetitionRegistrations()
                        })
                        .catch((err) => {
                          this.error = err
                          // console.log(err)
                        })

                      this.editRegistrationModal = false
                      this.retrieveCompetitionRegistrations()
                      // this.updateCompetitionMemberRegistrationModal = false
                      this.competitionMemberRegistrationData = { competitionMemberRegistrationStatus: true, competitionMemberRegistrationMembers: [0], competitionForeignPlayerRegistrationMembers: [0], competitionMemberRegistrationCompetitionRowId: 0, competitionMemberRegistrationCompetitionId: 0, competitionMemberRegistrationAddRemoveStatus: true }
                      this.competitionMemberForMemberRegistrationNameValue = []
                      return true
                    }
                  } else if (this.competitionMemberRegistrationData.competitionMemberRegistrationAddRemoveStatus === false &&
                      ((isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers === false && indexOfCompetitionRegistrationOptions.every((indexValue) => (indexValue[arrIndex] !== undefined && indexValue[arrIndex] >= 0))) ||
                      (isCompetitionWithMemberPlayers === false && isCompetitionWithForeignPlayers && indexOfCompetitionFPRegistrationOptions.every((indexValue) => (indexValue[foreignPlayerArrIndex] !== undefined && indexValue[foreignPlayerArrIndex] >= 0))) ||
                      (isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers && indexOfCompetitionRegistrationOptions.every((indexValue) => (indexValue[arrIndex] !== undefined && indexValue[arrIndex] >= 0)) && indexOfCompetitionFPRegistrationOptions.every((indexValue) => (indexValue[foreignPlayerArrIndex] !== undefined && indexValue[foreignPlayerArrIndex] >= 0))))) {
                    // Remove an existing competition registration entry.
                    console.log('[updateCompetitionMemberRegistrationEdit()] Remove an existing competition registration entry.')
                    if ((isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers === false && this.competitionMemberForMemberRegistrationNameValue[0].name.length > 1 && Number(this.competitionMemberForMemberRegistrationNameValue[0].value) > 0) ||
                      (isCompetitionWithMemberPlayers === false && isCompetitionWithForeignPlayers && this.competitionForeignPlayerForMemberRegistrationNameValue[0].name.length > 1 && Number(this.competitionForeignPlayerForMemberRegistrationNameValue[0].value) > 0) ||
                      (isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers && this.competitionMemberForMemberRegistrationNameValue[0].name.length > 1 && Number(this.competitionMemberForMemberRegistrationNameValue[0].value) > 0 && this.competitionForeignPlayerForMemberRegistrationNameValue[0].name.length > 1 && Number(this.competitionForeignPlayerForMemberRegistrationNameValue[0].value) > 0)) {
                      // this.lastEditedCompetitionRegistrationId = (indexOfCompetitionRegistrationOptions[0][arrIndex] !== undefined && indexOfCompetitionRegistrationOptions[0][arrIndex] >= 0) ? Number(this.competitionMemberForMemberRegistrationNameOptions[indexOfCompetitionMemberOptions[0]].competitionRegistrations[indexOfCompetitionRegistrationOptions[0][arrIndex]].id) : 0
                      const competitionRegistrationsJSON: { id: number }[][] = []
                      const competitionRegistrationsFPJSON: { id: number }[][] = []
                      console.log('[updateCompetitionMemberRegistrationEdit()] Checkpoint 1')
                      if (isCompetitionWithMemberPlayers) {
                        for (let arrIndex3 = 0; arrIndex3 < indexOfCompetitionMemberOptions.length; arrIndex3++) {
                          const tempArray: { id: number }[] = []
                          // this.competitionMemberForMemberRegistrationNameOptions[indexOfCompetitionMemberOptions[arrIndex3]].competitionRegistrations.forEach((obj) => { if (Number(obj.id) !== this.lastEditedCompetitionRegistrationId) { competitionRegistrationsJSON[arrIndex3].push({ id: Number(obj.id) }) } })
                          this.competitionMemberForMemberRegistrationNameOptions[indexOfCompetitionMemberOptions[arrIndex3]].competitionRegistrations.forEach((obj) => { if (Number(obj.id) !== this.lastEditedCompetitionRegistrationId) { tempArray.push({ id: Number(obj.id) }); competitionRegistrationsJSON[arrIndex3] = tempArray } })
                        }
                      }
                      if (isCompetitionWithForeignPlayers) {
                        for (let arrIndex3 = 0; arrIndex3 < indexOfCompetitionForeignPlayerOptions.length; arrIndex3++) {
                          const tempArray: { id: number }[] = []
                          // this.competitionForeignPlayerForMemberRegistrationNameOptions[indexOfCompetitionForeignPlayerOptions[arrIndex3]].competitionRegistrations.forEach((obj) => { if (Number(obj.id) !== this.lastEditedCompetitionRegistrationId) { competitionRegistrationsFPJSON[arrIndex3].push({ id: Number(obj.id) }) } })
                          this.competitionForeignPlayerForMemberRegistrationNameOptions[indexOfCompetitionForeignPlayerOptions[arrIndex3]].competitionRegistrations.forEach((obj) => { if (Number(obj.id) !== this.lastEditedCompetitionRegistrationId) { tempArray.push({ id: Number(obj.id) }); competitionRegistrationsFPJSON[arrIndex3] = tempArray } })
                        }
                      }
                      console.log('[updateCompetitionMemberRegistrationEdit()] competitionRegistrationsJSON (competition registration Ids) = ' + JSON.stringify(competitionRegistrationsJSON))
                      console.log('[updateCompetitionMemberRegistrationEdit()] competitionRegistrationsFPJSON (competition registration Ids) = ' + JSON.stringify(competitionRegistrationsFPJSON))

                      if ((tempIndexOfCompetition === -1 && this.tempCompetitionRegistration.staevner_id.length < 1) || (tempIndexOfCompetition >= 0 && this.tempCompetitionRegistration.staevner_id.length === 1)) {
                        // Remove the competition registration entry and its relation in the data of the member.
                        // The situation where both tempIndexOfCompetition === -1 and this.tempCompetitionRegistration.staevner_id.length < 1 should normally never happen, - however the possibility of removing stray entries should be there in case something previously has gone wrong.
                        console.log('[updateCompetitionMemberRegistrationEdit()] Remove the competition registration entry and its relation in the data of the member.')
                        // Temporarily preserve information about competition players registration Ids in local variables, because there is a great likelihood that the informations in the global variables will be cleared asynchronusly while they are still being processed after this point in the code path.
                        const tempCompetitionMemberRegistrationMembers = this.competitionMemberRegistrationData.competitionMemberRegistrationMembers
                        const tempCompetitionForeignPlayerRegistrationMembers = this.competitionMemberRegistrationData.competitionForeignPlayerRegistrationMembers
                        console.log('[updateCompetitionMemberRegistrationEdit()] tempCompetitionForeignPlayerRegistrationMembers = ' + JSON.stringify(tempCompetitionMemberRegistrationMembers))
                        console.log('[updateCompetitionMemberRegistrationEdit()] tempCompetitionForeignPlayerRegistrationMembers = ' + JSON.stringify(tempCompetitionForeignPlayerRegistrationMembers))

                        // indexOfCompetitionMemberOptions.length and this.competitionMemberForMemberRegistrationNameValue.length should have the same value here, - if not then something is wrong.

                        if (isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers) {
                          // Handle the situation with both member and foreign players.
                          const loopLength = this.competitionMemberForMemberRegistrationNameValue.length
                          for (let arrIndex3 = 0; arrIndex3 < loopLength; arrIndex3++) {
                            const updateMemberData = {
                              staevne_tilmelding_id: competitionRegistrationsJSON[arrIndex3]
                            }

                            MembersDataService.update(tempCompetitionMemberRegistrationMembers[arrIndex3], updateMemberData)
                              .then((response) => {
                                // this.lastUpdatedMemberId = response.data.id
                                console.log(response.data)
                                console.log('[updateCompetitionMemberRegistrationEdit()] Loop index 3 = ' + arrIndex3 + ' / Length = ' + loopLength)

                                // Fixme: There should be a check if there are more than one player related to the competition registration, and only delete if there is less than the selected amount of players plus one. [DONE]
                                // if (this.tempCompetitionRegistration.medlem_id.length < (loopLength + 1) && arrIndex3 === loopLength - 1) {
                                if (arrIndex3 === loopLength - 1) {
                                  // Update also for the foreign players situation.
                                  const loopLength2 = this.competitionForeignPlayerForMemberRegistrationNameValue.length
                                  for (let arrIndex6 = 0; arrIndex6 < loopLength2; arrIndex6++) {
                                    const updateForeignPlayerData = {
                                      staevne_tilmelding_id: competitionRegistrationsFPJSON[arrIndex6]
                                    }

                                    // console.log('[updateCompetitionMemberRegistrationEdit()] updateForeignPlayerData = ' + JSON.stringify(updateForeignPlayerData))

                                    ForeignPlayersDataService.update(tempCompetitionForeignPlayerRegistrationMembers[arrIndex6], updateForeignPlayerData)
                                      .then((response) => {
                                        // this.lastUpdatedForeignPlayerId = response.data.id
                                        console.log(response.data)
                                        console.log('[updateCompetitionMemberRegistrationEdit()] Loop index 6 = ' + arrIndex6 + ' / Length = ' + loopLength2)

                                        // Fixme: There should be a check if there are more than one member related to the competition registration, and only delete if there is less than the selected amount of members plus one. [DONE]
                                        if (this.tempCompetitionRegistration.medlem_id.length < (loopLength + 1) && this.tempCompetitionRegistration.usp_id.length < (loopLength2 + 1) && arrIndex6 === loopLength2 - 1) {
                                          Competitions.CompetitionMemberRegistrationDataService.delete(this.lastEditedCompetitionRegistrationId.toString())
                                            .then((response) => {
                                              // this.lastUpdateCompetitionRegistrationId = response.data.id
                                              console.log(response.data)
                                              this.retrieveCompetitionRows()
                                              this.retrieveCompetitionRegistrations()
                                            })
                                            .catch((err) => {
                                              this.error = err
                                              // console.log(err)
                                            })
                                        }
                                      })
                                      .catch((err) => {
                                        this.error = err
                                        // console.log(err)
                                      })
                                  }
                                }
                              })
                              .catch((err) => {
                                this.error = err
                                // console.log(err)
                              })
                          }
                        } else if (isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers === false) {
                          // Handle the situation with no foreign but only member players.
                          const loopLength = this.competitionMemberForMemberRegistrationNameValue.length
                          for (let arrIndex3 = 0; arrIndex3 < loopLength; arrIndex3++) {
                            const updateMemberData = {
                              staevne_tilmelding_id: competitionRegistrationsJSON[arrIndex3]
                            }

                            MembersDataService.update(tempCompetitionMemberRegistrationMembers[arrIndex3], updateMemberData)
                              .then((response) => {
                                // this.lastUpdatedMemberId = response.data.id
                                console.log(response.data)
                                console.log('[updateCompetitionMemberRegistrationEdit()] Loop index = ' + arrIndex3 + ' / Length = ' + loopLength)

                                // Fixme: There should be a check if there are more than one member related to the competition registration, and only delete if there is less than the selected amount of members plus one. [DONE]
                                if (this.tempCompetitionRegistration.medlem_id.length < (loopLength + 1) && arrIndex3 === loopLength - 1) {
                                  Competitions.CompetitionMemberRegistrationDataService.delete(this.lastEditedCompetitionRegistrationId.toString())
                                    .then((response) => {
                                      // this.lastUpdateCompetitionRegistrationId = response.data.id
                                      console.log(response.data)
                                      this.retrieveCompetitionRows()
                                      this.retrieveCompetitionRegistrations()
                                    })
                                    .catch((err) => {
                                      this.error = err
                                      // console.log(err)
                                    })
                                }
                              })
                              .catch((err) => {
                                this.error = err
                                // console.log(err)
                              })
                          }
                        } else if (isCompetitionWithMemberPlayers === false && isCompetitionWithForeignPlayers) {
                          // Handle the situation with no member but only foreign players.
                          const loopLength = this.competitionForeignPlayerForMemberRegistrationNameValue.length
                          for (let arrIndex3 = 0; arrIndex3 < loopLength; arrIndex3++) {
                            const updateForeignPlayerData = {
                              staevne_tilmelding_id: competitionRegistrationsFPJSON[arrIndex3]
                            }

                            ForeignPlayersDataService.update(tempCompetitionForeignPlayerRegistrationMembers[arrIndex3], updateForeignPlayerData)
                              .then((response) => {
                                // this.lastUpdatedForeignPlayerId = response.data.id
                                console.log(response.data)
                                console.log('[updateCompetitionMemberRegistrationEdit()] Loop index = ' + arrIndex3 + ' / Length = ' + loopLength)

                                // Fixme: There should be a check if there are more than one foreign player related to the competition registration, and only delete if there is less than the selected amount of foreign players plus one. [DONE]
                                if (this.tempCompetitionRegistration.usp_id.length < (loopLength + 1) && arrIndex3 === loopLength - 1) {
                                  Competitions.CompetitionMemberRegistrationDataService.delete(this.lastEditedCompetitionRegistrationId.toString())
                                    .then((response) => {
                                      // this.lastUpdateCompetitionRegistrationId = response.data.id
                                      console.log(response.data)
                                      this.retrieveCompetitionRows()
                                      this.retrieveCompetitionRegistrations()
                                    })
                                    .catch((err) => {
                                      this.error = err
                                      // console.log(err)
                                    })
                                }
                              })
                              .catch((err) => {
                                this.error = err
                                // console.log(err)
                              })
                          }
                        }

                        this.editRegistrationModal = false
                        this.retrieveCompetitionRegistrations()
                        // this.updateCompetitionMemberRegistrationModal = false
                        this.competitionMemberRegistrationData = { competitionMemberRegistrationStatus: true, competitionMemberRegistrationMembers: [0], competitionForeignPlayerRegistrationMembers: [0], competitionMemberRegistrationCompetitionRowId: 0, competitionMemberRegistrationCompetitionId: 0, competitionMemberRegistrationAddRemoveStatus: true }
                        this.competitionMemberForMemberRegistrationNameValue = []
                        return true
                      } else {
                        // Remove only the relevant competition Id entry from the competition registration.
                        console.log('[updateCompetitionMemberRegistrationEdit()] Remove only the relevant competition Id entry from the competition registration.')
                        const competitionRegistrationsJSON: { id: number }[] = []
                        this.tempCompetitionRegistration.staevner_id.forEach((obj) => { if (Number(obj.id) !== this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionId) { competitionRegistrationsJSON.push({ id: Number(obj.id) }) } })
                        // competitionRegistrationsJSON.push({ id: this.lastCreatedCompetitionRegistrationId })
                        console.log('[updateCompetitionMemberRegistrationEdit()] competitionRegistrationsJSON (competition Ids) = ' + JSON.stringify(competitionRegistrationsJSON))

                        const updateCompetitionRegistrationData = {
                          staevner_id: competitionRegistrationsJSON
                        }

                        Competitions.CompetitionMemberRegistrationDataService.update(this.lastEditedCompetitionRegistrationId.toString(), updateCompetitionRegistrationData)
                          .then((response) => {
                            // this.lastUpdateCompetitionRegistrationId = response.data.id
                            console.log(response.data)
                            this.retrieveCompetitionRows()
                            this.retrieveCompetitionRegistrations()
                          })
                          .catch((err) => {
                            this.error = err
                            // console.log(err)
                          })
                      }

                      // Regardless of whether an entry was removed or not, exit the search loop as nothing further should be done.
                      this.editRegistrationModal = false
                      this.retrieveCompetitionRegistrations()
                      // this.updateCompetitionMemberRegistrationModal = false
                      this.competitionMemberRegistrationData = { competitionMemberRegistrationStatus: true, competitionMemberRegistrationMembers: [0], competitionForeignPlayerRegistrationMembers: [0], competitionMemberRegistrationCompetitionRowId: 0, competitionMemberRegistrationCompetitionId: 0, competitionMemberRegistrationAddRemoveStatus: true }
                      this.competitionMemberForMemberRegistrationNameValue = []
                      return true
                    }
                  } else if (tempIndexOfCompetition === -1 || (isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers === false && indexOfCompetitionRegistrationOptions[arrIndex] === undefined) || (isCompetitionWithMemberPlayers === false && isCompetitionWithForeignPlayers && indexOfCompetitionFPRegistrationOptions[foreignPlayerArrIndex] === undefined) || (isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers && indexOfCompetitionRegistrationOptions[arrIndex] === undefined && indexOfCompetitionFPRegistrationOptions[foreignPlayerArrIndex] === undefined)) {
                    if (tempIndexOfCompetition === -1) {
                      // The competition row exists but does not have the competition. Just add the new competition to the existing competition registration instead of creating a new registration entry.
                      console.log('[updateCompetitionMemberRegistrationEdit()] Add a new competition entry to the existing competition registration entry.')
                      const competitionRegistrationsJSON: { id: number }[] = []
                      this.tempCompetitionRegistration.staevner_id.forEach(function (obj) { competitionRegistrationsJSON.push({ id: Number(obj.id) }) })
                      competitionRegistrationsJSON.push({ id: this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionId })
                      console.log('[updateCompetitionMemberRegistrationEdit()] competitionRegistrationsJSON = ' + JSON.stringify(competitionRegistrationsJSON))

                      const updateCompetitionRegistrationData = {
                        staevner_id: competitionRegistrationsJSON
                      }

                      Competitions.CompetitionMemberRegistrationDataService.update(this.lastEditedCompetitionRegistrationId.toString(), updateCompetitionRegistrationData)
                        .then((response) => {
                          // this.lastUpdateCompetitionRegistrationId = response.data.id
                          console.log(response.data)
                          this.retrieveCompetitionRows()
                          this.retrieveCompetitionRegistrations()
                        })
                        .catch((err) => {
                          this.error = err
                          // console.log(err)
                        })

                      this.editRegistrationModal = false
                      this.retrieveCompetitionRegistrations()
                      // this.updateCompetitionMemberRegistrationModal = false
                      this.competitionMemberRegistrationData = { competitionMemberRegistrationStatus: true, competitionMemberRegistrationMembers: [0], competitionForeignPlayerRegistrationMembers: [0], competitionMemberRegistrationCompetitionRowId: 0, competitionMemberRegistrationCompetitionId: 0, competitionMemberRegistrationAddRemoveStatus: true }
                      this.competitionMemberForMemberRegistrationNameValue = []
                      return true
                    } else {
                      // What to do in this situation?
                      console.log('[updateCompetitionMemberRegistrationEdit()] What to do in this situation?')

                      return true
                    }
                  }
                }
              }

              // If no suitable match found during the search.
              if (skipCreateNewRegistrationEntry === false && this.competitionMemberRegistrationData.competitionMemberRegistrationAddRemoveStatus === true &&
                ((isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers === false && searchOfCompetitionRegistrationOptionsMatchCompetitionRowAtLeastOnce.some((matchValue) => matchValue === false)) ||
                (isCompetitionWithMemberPlayers === false && isCompetitionWithForeignPlayers && searchOfCompetitionFPRegistrationOptionsMatchCompetitionRowAtLeastOnce.some((matchValue) => matchValue === false)) ||
                (isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers && searchOfCompetitionRegistrationOptionsMatchCompetitionRowAtLeastOnce.some((matchValue) => matchValue === false) && searchOfCompetitionFPRegistrationOptionsMatchCompetitionRowAtLeastOnce.some((matchValue) => matchValue === false)) ||
                searchOfCompetitionRegistrationMatchCompetitionAtLeastOnce === false)) {
                // Create a new competition registration entry.
                console.log('[updateCompetitionMemberRegistrationEdit()] Creating a new competition registration entry - [2].')
                if ((isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers === false && this.competitionMemberForMemberRegistrationNameValue[0].name.length > 1 && Number(this.competitionMemberForMemberRegistrationNameValue[0].value) > 0) ||
                  (isCompetitionWithMemberPlayers === false && isCompetitionWithForeignPlayers && this.competitionForeignPlayerForMemberRegistrationNameValue[0].name.length > 1 && Number(this.competitionForeignPlayerForMemberRegistrationNameValue[0].value) > 0) ||
                  (isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers && this.competitionMemberForMemberRegistrationNameValue[0].name.length > 1 && Number(this.competitionMemberForMemberRegistrationNameValue[0].value) > 0 && this.competitionForeignPlayerForMemberRegistrationNameValue[0].name.length > 1 && Number(this.competitionForeignPlayerForMemberRegistrationNameValue[0].value) > 0)) {
                  const competitionMembersJSON: { id: number }[] = []
                  const competitionForeignPlayersJSON: { id: number }[] = []
                  this.competitionMemberRegistrationData.competitionMemberRegistrationMembers.forEach(function (obj: number) { competitionMembersJSON.push({ id: Number(obj) }) })
                  this.competitionMemberRegistrationData.competitionForeignPlayerRegistrationMembers.forEach(function (obj: number) { competitionForeignPlayersJSON.push({ id: Number(obj) }) })
                  console.log('[updateCompetitionMemberRegistrationEdit()] competitionMembersJSON = ' + JSON.stringify(competitionMembersJSON))
                  console.log('[updateCompetitionMemberRegistrationEdit()] competitionMembersJSON = ' + JSON.stringify(competitionMembersJSON))

                  const createCompetitionRegistrationData = {
                    staevne_tilmelding_status: this.competitionMemberRegistrationData.competitionMemberRegistrationStatus,
                    medlem_id: competitionMembersJSON,
                    usp_id: competitionForeignPlayersJSON,
                    staevner_id: [{ id: this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionId }],
                    staevne_raekker_id: this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionRowId
                  }

                  console.log('[updateCompetitionMemberRegistrationEdit()] competitionMemberRegistrationMembers = ' + JSON.stringify(this.competitionMemberRegistrationData.competitionMemberRegistrationMembers))

                  Competitions.CompetitionMemberRegistrationDataService.create(createCompetitionRegistrationData)
                    .then((response) => {
                      this.lastCreatedCompetitionRegistrationId = response.data.id
                      console.log(response.data)
                    })
                    .catch((err) => {
                      this.error = err
                      // console.log(err)
                    })

                  this.editRegistrationModal = false
                  this.retrieveCompetitionRegistrations()
                  // this.updateCompetitionMemberRegistrationModal = false
                  this.competitionMemberRegistrationData = { competitionMemberRegistrationStatus: true, competitionMemberRegistrationMembers: [0], competitionForeignPlayerRegistrationMembers: [0], competitionMemberRegistrationCompetitionRowId: 0, competitionMemberRegistrationCompetitionId: 0, competitionMemberRegistrationAddRemoveStatus: true }
                  this.competitionMemberForMemberRegistrationNameValue = []
                  return true
                }
              }
            })
            .catch((err) => {
              this.error = err
              // If this piont is ever reached then exit the method in stead of continuing the search loop, because something is most likely not right with the API access.
              console.log('[updateCompetitionMemberRegistrationEdit()] Fatal error!')
              return true
            })

          return true
        } else {
          if ((isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers === false && competitionRegistrationIdOfMembersSelectedMatches) || (isCompetitionWithMemberPlayers === false && isCompetitionWithForeignPlayers && competitionRegistrationIdOfForeignPlayerSelectedMatches) || (isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers && competitionRegistrationIdOfMembersSelectedMatches && competitionRegistrationIdOfForeignPlayerSelectedMatches)) {
            console.log('[updateCompetitionMemberRegistrationEdit()] The selected member(s) do(es) not already have a competition registration with a matching competition row.')
          } else {
            console.log('[updateCompetitionMemberRegistrationEdit()] The selected member(s) do(es) not already have a competition registration with a matching competition row and same players.')
          }
          // The selected player(s) do(es) not already have a competition registration with a matching competition row.
          if (this.competitionMemberRegistrationData.competitionMemberRegistrationAddRemoveStatus === true) {
            // Create a new competition registration entry.
            if ((isCompetitionWithMemberPlayers && this.competitionMemberForMemberRegistrationNameValue[0].name.length > 1 && Number(this.competitionMemberForMemberRegistrationNameValue[0].value) > 0) ||
              (isCompetitionWithForeignPlayers && this.competitionForeignPlayerForMemberRegistrationNameValue[0].name.length > 1 && Number(this.competitionForeignPlayerForMemberRegistrationNameValue[0].value) > 0) ||
              (isCompetitionWithMemberPlayers && isCompetitionWithForeignPlayers && this.competitionMemberForMemberRegistrationNameValue[0].name.length > 1 && Number(this.competitionMemberForMemberRegistrationNameValue[0].value) > 0 && this.competitionForeignPlayerForMemberRegistrationNameValue[0].name.length > 1 && Number(this.competitionForeignPlayerForMemberRegistrationNameValue[0].value) > 0)) {
              console.log('[updateCompetitionMemberRegistrationEdit()] Creating a new competition registration entry - [1].')
              const competitionMembersJSON: { id: number }[] = []
              const competitionForeignPlayersJSON: { id: number }[] = []
              this.competitionMemberRegistrationData.competitionMemberRegistrationMembers.forEach(function (obj: number) { competitionMembersJSON.push({ id: Number(obj) }) })
              this.competitionMemberRegistrationData.competitionForeignPlayerRegistrationMembers.forEach(function (obj: number) { competitionForeignPlayersJSON.push({ id: Number(obj) }) })
              console.log('[updateCompetitionMemberRegistrationEdit()] competitionMembersJSON = ' + JSON.stringify(competitionMembersJSON))
              console.log('[updateCompetitionMemberRegistrationEdit()] competitionForeignPlayersJSON = ' + JSON.stringify(competitionForeignPlayersJSON))

              const createCompetitionRegistrationData = {
                staevne_tilmelding_status: this.competitionMemberRegistrationData.competitionMemberRegistrationStatus,
                medlem_id: competitionMembersJSON,
                usp_id: competitionForeignPlayersJSON,
                staevner_id: [{ id: this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionId }],
                staevne_raekker_id: this.competitionMemberRegistrationData.competitionMemberRegistrationCompetitionRowId
              }

              console.log('[updateCompetitionMemberRegistrationEdit()] competitionMemberRegistrationMembers = ' + JSON.stringify(this.competitionMemberRegistrationData.competitionMemberRegistrationMembers))
              console.log('[updateCompetitionMemberRegistrationEdit()] competitionForeignPlayerRegistrationMembers = ' + JSON.stringify(this.competitionMemberRegistrationData.competitionForeignPlayerRegistrationMembers))

              Competitions.CompetitionMemberRegistrationDataService.create(createCompetitionRegistrationData)
                .then((response) => {
                  this.lastCreatedCompetitionRegistrationId = response.data.id
                  console.log(response.data)
                  this.retrieveCompetitionRegistrations()
                })
                .catch((err) => {
                  this.error = err
                  // console.log(err)
                })

              this.editRegistrationModal = false
              this.retrieveCompetitionRegistrations()
              // this.updateCompetitionMemberRegistrationModal = false
              this.competitionMemberRegistrationData = { competitionMemberRegistrationStatus: true, competitionMemberRegistrationMembers: [0], competitionForeignPlayerRegistrationMembers: [0], competitionMemberRegistrationCompetitionRowId: 0, competitionMemberRegistrationCompetitionId: 0, competitionMemberRegistrationAddRemoveStatus: true }
              this.competitionMemberForMemberRegistrationNameValue = []
              return true
            }
          }
        }
      }
    }

    return false
  }

  public cancelUpdateCompetitionMemberRegistrationEdit () : void {
    console.log('Competition registration of the member was canceled.')
    this.editRegistrationModal = false
    // this.updateCompetitionMemberRegistrationModal = false
    this.submitted3 = false
    this.competitionMemberRegistrationData = { competitionMemberRegistrationStatus: true, competitionMemberRegistrationMembers: [0], competitionForeignPlayerRegistrationMembers: [0], competitionMemberRegistrationCompetitionRowId: 0, competitionMemberRegistrationCompetitionId: 0, competitionMemberRegistrationAddRemoveStatus: true }
    this.competitionMemberForMemberRegistrationNameValue = []
    this.competitionRowForMemberRegistrationNameValue = { name: '', value: '' }
    this.checkPlayerRestrictionsFlag = 0
  }

  public buildColumnDataObj () : void {
    let arrIndex = 0
    let arrIndex2 = 0
    // const localcompetitionRowsRows = []
    // console.log('localcompetitionRowsRows[] data = ' + JSON.stringify(localcompetitionRowsRows))
    this.competitionRowsRowsForRegistration = []
    this.competitionRowForMemberRegistrationNameOptions = []
    for (const objItem of this.competitionRowsForRegistration) {
      // (this.staevneraekkers[arrIndex]).push(objItem.klubber_id)
      for (const objItem2 of objItem.staevner_id) {
        this.competitionRowsRows[arrIndex2] = {
          key: arrIndex2,
          id: Number(objItem.id),
          staevne_raekker_status: objItem.staevne_raekker_status,
          staevne_raekker_navn: (objItem.staevne_raekker_navn === null ? '' : objItem.staevne_raekker_navn),
          staevne_kategori: (objItem2.staevne_kategori === null ? '' : objItem2.staevne_kategori.toString()),
          staevne_navn: (objItem2.staevne_navn === null ? '' : objItem2.staevne_navn.toString()),
          staevne_spillested: (objItem2.staevne_spillested === null ? '' : objItem2.staevne_spillested),
          staevne_start: (objItem2.staevne_start === null ? '' : objItem2.staevne_start),
          staevne_slut: (objItem2.staevne_slut === null ? '' : objItem2.staevne_slut),
          staevne_sidstetilmelding: (objItem2.staevne_sidstetilmelding === null ? '' : objItem2.staevne_sidstetilmelding),
          staevne_raekker_tilmeldingsgebyr: (objItem.staevne_raekker_tilmeldingsgebyr === null ? 0 : objItem.staevne_raekker_tilmeldingsgebyr),
          staevne_raekker_type: (objItem.staevne_raekker_type === null ? 0 : objItem.staevne_raekker_type)
        }
        this.competitionRowForMemberRegistrationNameOptions.push({ name: this.competitionRowsRows[arrIndex2].staevne_navn + ' - ' + this.competitionRowsRows[arrIndex2].staevne_raekker_navn + ' - Start: ' + (this.getTimeOfDay(this.competitionRowsRows[arrIndex2].staevne_start)), value: arrIndex2.toString(), competitionrowid: Number(objItem.id).toString(), competitionid: Number(objItem2.id).toString(), competitionrowtype: Number(objItem.staevne_raekker_type) })
        arrIndex2++
      }
      arrIndex++
    }

    if (this.filterRegistrationByCompetitionsValue.id !== '0') {
      const filterBy = this.filterRegistrationByCompetitionsValue.name
      console.log(filterBy)
      this.competitionRowForMemberRegistrationNameOptions = this.competitionRowForMemberRegistrationNameOptions.filter(function (el) { return el.name.includes(filterBy) })
    }
    // this.competitionRowsRows = localcompetitionRowsRows
    // console.log('competitionRowsRows[] data = ' + JSON.stringify(this.competitionRowsRows))
    console.log('competitionRowForMemberRegistrationNameOptions[] data = ' + JSON.stringify(this.competitionRowForMemberRegistrationNameOptions))
    console.log(this.competitionRowForMemberRegistrationNameOptions)
  }

  public buildColumnDataObjForRows () : void {
    let arrIndex = 0
    let arrIndex2 = 0
    // const localcompetitionRowsRows = []
    // console.log('localcompetitionRowsRows[] data = ' + JSON.stringify(localcompetitionRowsRows))
    this.competitionRowsRows = []
    this.competitionRowForMemberRegistrationNameOptions = []
    for (const objItem of this.staevneraekkers) {
      // (this.staevneraekkers[arrIndex]).push(objItem.klubber_id)
      for (const objItem2 of objItem.staevner_id) {
        this.competitionRowsRows[arrIndex2] = {
          key: arrIndex2,
          id: Number(objItem.id),
          staevne_raekker_status: objItem.staevne_raekker_status,
          staevne_raekker_navn: (objItem.staevne_raekker_navn === null ? '' : objItem.staevne_raekker_navn),
          staevne_kategori: (objItem2.staevne_kategori === null ? '' : objItem2.staevne_kategori.toString()),
          staevne_navn: (objItem2.staevne_navn === null ? '' : objItem2.staevne_navn.toString()),
          staevne_spillested: (objItem2.staevne_spillested === null ? '' : objItem2.staevne_spillested),
          staevne_start: (objItem2.staevne_start === null ? '' : objItem2.staevne_start),
          staevne_slut: (objItem2.staevne_slut === null ? '' : objItem2.staevne_slut),
          staevne_sidstetilmelding: (objItem2.staevne_sidstetilmelding === null ? '' : objItem2.staevne_sidstetilmelding),
          staevne_raekker_tilmeldingsgebyr: (objItem.staevne_raekker_tilmeldingsgebyr === null ? 0 : objItem.staevne_raekker_tilmeldingsgebyr),
          staevne_raekker_type: (objItem.staevne_raekker_type === null ? 0 : objItem.staevne_raekker_type)
        }
        // this.competitionRowForMemberRegistrationNameOptions.push({ name: this.competitionRowsRows[arrIndex2].staevne_navn + ' - ' + this.competitionRowsRows[arrIndex2].staevne_raekker_navn + ' - Start: ' + (new Date(this.competitionRowsRows[arrIndex2].staevne_start)).toLocaleDateString('da-DK', { hour: 'numeric', minute: 'numeric' }), value: arrIndex2.toString(), competitionrowid: Number(objItem.id).toString(), competitionid: Number(objItem2.id).toString(), competitionrowtype: Number(objItem.staevne_raekker_type) })
        arrIndex2++
      }
      arrIndex++
    }
    // this.competitionRowsRows = localcompetitionRowsRows
    // console.log('competitionRowsRows[] data = ' + JSON.stringify(this.competitionRowsRows))
    console.log('competitionRowForMemberRegistrationNameOptions[] data = ' + JSON.stringify(this.competitionRowForMemberRegistrationNameOptions))
  }

  public refreshShowCompetitionRegistrations () : void {
    this.retrieveCompetitionRegistrations()
  }

  public refreshShowCompetitionResults () : void {
    this.retrieveCompetitionResults()
  }

  public retrieveMembers () : void {
    console.log('Fetching members data from API.')

    // Fetch first data from the API about member players.
    MembersDataService.getAll()
      .then((response) => {
        this.medlemmers = response.data
        // console.log(response.data)

        let arrIndex = 0
        this.competitionMemberForMemberRegistrationNameOptions = []
        for (const objItem of this.medlemmers) {
          if (objItem.medlem_status === true) {
            this.competitionMemberForMemberRegistrationNameOptions[arrIndex] = {
              key: arrIndex,
              name: objItem.user_id.firstname + ' ' + objItem.user_id.lastname + ' [' + (objItem.medlem_licens === null ? '' : objItem.medlem_licens) + '], Klub: ' + (objItem.klubber_id === undefined || objItem.klubber_id === null ? 'Ingen' : objItem.klubber_id.klubber_klubnavn),
              value: Number(objItem.id).toString(),
              gender: Number(objItem.koen_id.id).toString(),
              birthdaydate: objItem.medlem_foedselsdag,
              isparadart: objItem.medlem_paradart,
              clubid: (objItem.klubber_id === undefined || objItem.klubber_id === null ? 0 : Number(objItem.klubber_id.id)),
              competitionRegistrations: objItem.staevne_tilmelding_id
            }
            arrIndex++
          }
        }
        this.medlemmers = []

        // Then fetch data from the API about foreign players.
        ForeignPlayersDataService.getAll()
          .then((response) => {
            this.udspillers = response.data
            console.log(response.data)

            let arrIndex = 0
            this.competitionForeignPlayerForMemberRegistrationNameOptions = []
            for (const objItem of this.udspillers) {
              if (objItem.ud_spiller_status === true) {
                this.competitionForeignPlayerForMemberRegistrationNameOptions[arrIndex] = {
                  key: arrIndex,
                  name: objItem.ud_spiller_fornavn + ' ' + objItem.ud_spiller_efternavn + ', Land: ' + (objItem.ud_spiller_land === undefined || objItem.ud_spiller_land === null ? 'Ingen' : objItem.ud_spiller_land),
                  value: Number(objItem.id).toString(),
                  gender: Number(objItem.koen_id.id).toString(),
                  birthdaydate: objItem.ud_foedselsdag,
                  isparadart: objItem.ud_spiller_paradart,
                  country: objItem.ud_spiller_land,
                  competitionRegistrations: objItem.staevne_tilmelding_id
                }
                arrIndex++
              }
            }
            this.udspillers = []
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      })
      .catch((err) => {
        this.error = err
        // console.log(err)
      })
  }

  public retrieveClubs () : void {
    console.log('Fetching club data from API.')
    if (this.competitionClubsNameOptions.length === 0) {
      ClubsDataService.getAll()
        .then((resonse) => {
          this.klubbers = resonse.data

          for (const item of this.klubbers) {
            this.competitionClubsNameOptions.push({
              name: item.klubber_klubnavn,
              value: Number(item.id)
            })
          }
          this.competitionClubsNameOptions.sort((a, b) => a.name.localeCompare(b.name))
          this.competitionClubsNameOptions.unshift({ name: 'Vælg her', value: 0 })
        })
        .catch((err) => {
          this.error = err
        })
    }
  }

  public retrieveCompetitionRankingsPoints () : void {
    console.log('Fetching competition rankings point data from API.')

    if (this.competitionPointSearchTerm.length > 0) {
      Competitions.CompetitionRankingsPointsDataService.findBySearchTerm((this.filterStatusStringValue4 === 'Aktive'), (this.filterStatusStringValue4 === 'Vis alt'), this.competitionPointSearchTerm, this.currentListSortingOrder3)
        .then((response) => {
          this.staevneranglistepoints = response.data
          console.log(response.data)

          this.forceRerenderCompetitionsList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    } else {
      if (this.filterStatusStringValue4 === 'Vis alt') {
        Competitions.CompetitionRankingsPointsDataService.getAll(this.currentListSortingOrder3)
          .then((response) => {
            this.staevneranglistepoints = response.data
            console.log(response.data)

            this.forceRerenderCompetitionsList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      } else {
        Competitions.CompetitionRankingsPointsDataService.findByCompetitionRankingsPointStatus((this.filterStatusStringValue4 === 'Aktive'), this.currentListSortingOrder3)
          .then((response) => {
            this.staevneranglistepoints = response.data
            console.log(response.data)

            this.forceRerenderCompetitionsList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      }
    }
  }

  public deleteRegistrationWarning (registrationId: number) : void {
    this.deleteRegistrationId = registrationId
    this.deleteRegistrationWarningModal = true
  }

  public deleteRegistration (registrationId: number) : void {
    console.log('Deleting registration entry with id: ' + registrationId)
    Competitions.CompetitionMemberRegistrationDataService.delete(registrationId.toString())
      .then((resonse) => {
        console.log(resonse.data)
        this.retrieveCompetitionRegistrations()
        this.deleteRegistrationWarningModal = false
      })
      .catch((err) => {
        this.error = err
      })
  }

  public createSeriesFilterForRegistration () : void {
    console.log('Fetching series ascociated the competition: ' + this.filterRegistrationByCompetitionsValue.name)
    this.filterRegistrationBySeriesOptions = []

    if (this.filterRegistrationByCompetitionsValue.id !== '0') {
      for (const objItem of <any> this.filterRegistrationByCompetitionsValue.rows) {
        this.filterRegistrationBySeriesOptions.push({ name: objItem.staevne_raekker_navn, id: objItem.id })
      }
      this.filterRegistrationBySeriesOptions.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
      this.filterRegistrationBySeriesOptions.unshift({ name: 'Vis alt', id: 0 })
    }
  }

  public async retrieveCompetitionRegistrations () : Promise<void> {
    console.log('Fetching competition registrations data from API.')
    const maxLength = 1000 // Maximum two bytes per char.
    let parameterCharCnt = 0
    let indexPosMark = 0
    let stringToAdd = ''
    let posInStringMark: number[] = [0]
    let partialTempUser: SysUser[] = []

    if (this.filterStatusStringValue3 === 'Vis alt') {
      // Competitions.CompetitionMemberRegistrationDataService.getAll(this.currentListSortingOrder3)
      Competitions.CompetitionMemberRegistrationDataService.getAll('staevne_raekker_id.staevne_raekker_navn:asc', null, this.extraRegistrationParameter)
        .then(async (response) => {
          this.staevnetilmeldings = response.data
          this.filteredStaevnetilmeldings = this.staevnetilmeldings
          console.log(response.data)

          this.forceRerenderCompetitionsList()
          this.$forceUpdate()

          let arrIndex = 0
          let memberPlayersUserIdParamOptions = ''
          let foreignPlayersIdParamOptions = ''
          let trackFirstMemberPlayerFound = false
          let trackFirstForeignPlayerFound = false

          for (const objItem of this.staevnetilmeldings) {
            for (let arrIndex2 = 0; arrIndex2 < objItem.medlem_id.length; arrIndex2++) {
              if (objItem.medlem_id[arrIndex2] !== undefined && objItem.medlem_id[arrIndex2].id !== null) {
                // memberPlayersUserIdParamOptions += ((trackFirstMemberPlayerFound === false && arrIndex2 === 0 ? 'id=' : '&id=') + Number(objItem.medlem_id[arrIndex2].user_id).toString())
                stringToAdd = ('&id=' + Number(objItem.medlem_id[arrIndex2].user_id).toString())

                if (parameterCharCnt + stringToAdd.length > maxLength) {
                  posInStringMark.push(memberPlayersUserIdParamOptions.length)
                  indexPosMark++
                  parameterCharCnt = 0
                }

                memberPlayersUserIdParamOptions += stringToAdd
                parameterCharCnt += stringToAdd.length

                trackFirstMemberPlayerFound = true
              }
            }

            for (let arrIndex2 = 0; arrIndex2 < objItem.usp_id.length; arrIndex2++) {
              console.log('[retrieveCompetitionRegistrations()] ... ')
              if (objItem.usp_id[arrIndex2] !== undefined && objItem.usp_id[arrIndex2].id !== null) {
                foreignPlayersIdParamOptions += ((trackFirstForeignPlayerFound === false && arrIndex2 === 0 ? 'id=' : '&id=') + Number(objItem.usp_id[arrIndex2].id).toString())

                trackFirstForeignPlayerFound = true
              }
            }

            arrIndex++
          }

          // console.log('[retrieveCompetitionRegistrations()] memberPlayersUserIdParamOptions = ' + memberPlayersUserIdParamOptions)
          // console.log('[retrieveCompetitionRegistrations()] foreignPlayersIdParamOptions = ' + foreignPlayersIdParamOptions)

          for (indexPosMark = 0; indexPosMark <= (posInStringMark.length - 1); indexPosMark++) {
            try {
              partialTempUser = (await UsersDataService.asyncGetAll('', null, memberPlayersUserIdParamOptions.slice(posInStringMark[indexPosMark], (indexPosMark === (posInStringMark.length - 1) ? memberPlayersUserIdParamOptions.length : (posInStringMark[indexPosMark + 1]))).slice(1))).data
              this.tempCompetitionRegistrationUsers = [...this.tempCompetitionRegistrationUsers, ...partialTempUser]
            } catch (err) {
              console.log(err)
            }
          }

          memberPlayersUserIdParamOptions = ''
          posInStringMark = []

          this.tempCompetitionRegistrationForeignPlayers = (await ForeignPlayersDataService.asyncGetAll('', null, foreignPlayersIdParamOptions)).data

          // UsersDataService.getAll('', null, memberPlayersUserIdParamOptions)
          //   .then((response) => {
          //     this.tempCompetitionRegistrationUsers = response.data
          //     console.log(response.data)

          //     ForeignPlayersDataService.getAll('', null, foreignPlayersIdParamOptions)
          //       .then((response) => {
          //         this.tempCompetitionRegistrationForeignPlayers = response.data
          //         console.log(response.data)
          //       })
          //       .catch((err) => {
          //         this.error = err
          //         // console.log(err)
          //       })
          //   })
          //   .catch((err) => {
          //     this.error = err
          //     // console.log(err)
          //   })
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    } else {
      // Competitions.CompetitionMemberRegistrationDataService.findByCompetitionRegistrationStatus((this.filterStatusStringValue3 === 'Aktive'), this.currentListSortingOrder3)
      Competitions.CompetitionMemberRegistrationDataService.findByCompetitionRegistrationStatus((this.filterStatusStringValue3 === 'Aktive'), 'staevne_raekker_id.staevne_raekker_navn:asc', null, this.extraRegistrationParameter)
        .then(async (response) => {
          this.staevnetilmeldings = response.data
          console.log(response.data)

          this.forceRerenderCompetitionsList()
          this.$forceUpdate()

          let arrIndex = 0
          let memberPlayersUserIdParamOptions = ''
          let foreignPlayersIdParamOptions = ''
          let trackFirstMemberPlayerFound = false
          let trackFirstForeignPlayerFound = false

          for (const objItem of this.staevnetilmeldings) {
            for (let arrIndex2 = 0; arrIndex2 < objItem.medlem_id.length; arrIndex2++) {
              if (objItem.medlem_id[arrIndex2] !== undefined && objItem.medlem_id[arrIndex2].id !== null) {
                // memberPlayersUserIdParamOptions += ((trackFirstMemberPlayerFound === false && arrIndex2 === 0 ? 'id=' : '&id=') + Number(objItem.medlem_id[arrIndex2].user_id).toString())
                stringToAdd = ('&id=' + Number(objItem.medlem_id[arrIndex2].user_id).toString())

                if (parameterCharCnt + stringToAdd.length > maxLength) {
                  posInStringMark.push(memberPlayersUserIdParamOptions.length)
                  indexPosMark++
                  parameterCharCnt = 0
                }

                memberPlayersUserIdParamOptions += stringToAdd
                parameterCharCnt += stringToAdd.length

                trackFirstMemberPlayerFound = true
              }
            }

            for (let arrIndex2 = 0; arrIndex2 < objItem.usp_id.length; arrIndex2++) {
              console.log('[retrieveCompetitionRegistrations()] ... ')
              if (objItem.usp_id[arrIndex2] !== undefined && objItem.usp_id[arrIndex2].id !== null) {
                foreignPlayersIdParamOptions += ((trackFirstForeignPlayerFound === false && arrIndex2 === 0 ? 'id=' : '&id=') + Number(objItem.usp_id[arrIndex2].id).toString())
                trackFirstForeignPlayerFound = true
              }
            }

            arrIndex++
          }

          // console.log('[retrieveCompetitionRegistrations()] memberPlayersUserIdParamOptions = ' + memberPlayersUserIdParamOptions)
          // console.log('[retrieveCompetitionRegistrations()] foreignPlayersIdParamOptions = ' + foreignPlayersIdParamOptions)

          for (indexPosMark = 0; indexPosMark <= (posInStringMark.length - 1); indexPosMark++) {
            try {
              partialTempUser = (await UsersDataService.asyncGetAll('', null, memberPlayersUserIdParamOptions.slice(posInStringMark[indexPosMark], (indexPosMark === (posInStringMark.length - 1) ? memberPlayersUserIdParamOptions.length : (posInStringMark[indexPosMark + 1]))).slice(1))).data
              this.tempCompetitionRegistrationUsers = [...this.tempCompetitionRegistrationUsers, ...partialTempUser]
            } catch (err) {
              console.log(err)
            }
          }

          memberPlayersUserIdParamOptions = ''
          posInStringMark = []

          this.tempCompetitionRegistrationForeignPlayers = (await ForeignPlayersDataService.asyncGetAll('', null, foreignPlayersIdParamOptions)).data

          // UsersDataService.getAll('', null, memberPlayersUserIdParamOptions)
          //   .then((response) => {
          //     this.tempCompetitionRegistrationUsers = response.data
          //     console.log(response.data)

          //     ForeignPlayersDataService.getAll('', null, foreignPlayersIdParamOptions)
          //       .then((response) => {
          //         this.tempCompetitionRegistrationForeignPlayers = response.data
          //         console.log(response.data)
          //       })
          //       .catch((err) => {
          //         this.error = err
          //         // console.log(err)
          //       })
          //   })
          //   .catch((err) => {
          //     this.error = err
          //     // console.log(err)
          //   })
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    }
  }

  public retrieveCompetitionResults () : void {
    if (Number(this.filterCompetitionNameValue.value) === 0) { // Prevents retrieving results without a limiting parameter.
      return
    }
    if (!this.foreignCompetitionResults) {
      Competitions.CompetitionResultsDataService.getAll('', null, ('staevner_id.id=' + this.filterCompetitionNameValue.value))
        .then((response) => {
          this.tempStaevneresults = response.data

          for (const objItem of this.tempStaevneresults) {
            this.filterCompetitionRowOptions.push({ name: objItem.staevne_raekker_id.staevne_raekker_navn, id: Number(objItem.staevne_raekker_id.id) })
          }
          // Removes duplicates from the filterCompetitionRowOptions array.
          this.filterCompetitionRowOptions = this.filterCompetitionRowOptions.filter((a, index, b) => b.findIndex(t => t.name === a.name) === index)

          this.staevneresultats = this.tempStaevneresults

          // Sorting the results.
          this.staevneresultats.sort(function (a, b) {
            if (a.staevne_raekker_id.staevne_raekker_navn === b.staevne_raekker_id.staevne_raekker_navn) {
              return parseInt(a.staevne_resultater_placering) - parseInt(b.staevne_resultater_placering)
            }

            return a.staevne_raekker_id.staevne_raekker_navn > b.staevne_raekker_id.staevne_raekker_navn ? 1 : -1
          })

          let membersUserIdParamOptions = ''
          for (const objItem of this.tempStaevneresults) {
            if (objItem.medlem_id !== undefined && objItem.medlem_id !== null && objItem.medlem_id.id !== undefined && objItem.medlem_id.id !== null && objItem.medlem_id.id !== 17) {
              membersUserIdParamOptions += '&id=' + Number(objItem.medlem_id.user_id).toString()
            }
          }
          membersUserIdParamOptions = membersUserIdParamOptions.substring(1)
          console.log(membersUserIdParamOptions)

          this.retrieveUsersFromId(membersUserIdParamOptions)
          this.forceRerenderCompetitionsList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
        })
    } else {
      Competitions.CompetitionForeignResultsDataService.getAll('', null, ('ud_staevner_id.id=' + this.filterCompetitionNameValue.value))
        .then((response) => {
          this.tempUdStaevneresultats = response.data

          for (const objItem of this.tempUdStaevneresultats) {
            this.filterCompetitionRowOptions.push({ name: objItem.staevne_raekker_id.staevne_raekker_navn, id: Number(objItem.staevne_raekker_id.id) })
          }

          // Removes duplicates from the filterCompetitionRowOptions array.
          this.filterCompetitionRowOptions = this.filterCompetitionRowOptions.filter((a, index, b) => b.findIndex(t => t.name === a.name) === index)

          this.udStaevneresultats = this.tempUdStaevneresultats
          this.udStaevneresultats.sort(function (a, b) {
            if (a.staevne_raekker_id.staevne_raekker_navn === b.staevne_raekker_id.staevne_raekker_navn) {
              return parseInt(a.ud_resultat_placering) - parseInt(b.ud_resultat_placering)
            }

            return a.staevne_raekker_id.staevne_raekker_navn > b.staevne_raekker_id.staevne_raekker_navn ? 1 : -1
          })

          let membersUserIdParamOptions = ''
          for (const objItem of this.tempUdStaevneresultats) {
            if (objItem.medlem_id !== undefined && objItem.medlem_id !== null && objItem.medlem_id.id !== undefined && objItem.medlem_id.id !== null && objItem.medlem_id.id !== 17) {
              membersUserIdParamOptions += '&id=' + Number(objItem.medlem_id.user_id).toString()
            }
          }
          membersUserIdParamOptions = membersUserIdParamOptions.substring(1)

          this.retrieveUsersFromId(membersUserIdParamOptions)
          this.forceRerenderCompetitionsList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
        })
    }
  }

  public retrieveUsersFromId (userIds = '') : void {
    if (userIds !== '') {
      UsersDataService.getAll('', null, userIds)
        .then((response) => {
          this.tempCompetitionResultUsers = response.data
          console.log(this.tempCompetitionResultUsers)
        })
        .catch((err) => {
          this.error = err
        })
    }
  }

  // public retrieveCompetitionResults () : void {
  //   console.log('Fetching competition results data from API.')

  //   if (this.filterStatusStringValue5 === 'Vis alt') {
  //     // Competitions.CompetitionMemberRegistrationDataService.getAll(this.currentListSortingOrder3)
  //     Competitions.CompetitionResultsDataService.getAll('', null, ((Number(this.filterCompetitionNameValue.value) > 0 ? 'staevner_id.id=' + this.filterCompetitionNameValue.value : undefined) + (Number(this.filterCompetitionRowValue.value) > 0 ? '&staevne_raekker_id=' + this.filterCompetitionRowValue.value : '')))
  //       .then((response) => {
  //         this.staevneresultats = response.data
  //         // console.log(response.data)
  //         if (Number(this.filterCompetitionNameValue.value) > 0) {
  //           for (const objItem of this.staevneresultats) {
  //             this.filterCompetitionRowOptions.push({ name: objItem.staevne_raekker_id.staevne_raekker_navn, value: Number(objItem.staevne_raekker_id.id).toString() })
  //           }
  //           console.log(this.filterCompetitionRowOptions)
  //           this.filterCompetitionRowOptions = this.filterCompetitionRowOptions.filter((a, index, b) => b.findIndex(t => t.name === a.name) === index)
  //         }

  //         // Sorting the array by row then the placement within that row.
  //         this.staevneresultats.sort(function (a, b) {
  //           if (a.staevne_raekker_id.staevne_raekker_navn === b.staevne_raekker_id.staevne_raekker_navn) {
  //             return parseInt(a.staevne_resultater_placering) - parseInt(b.staevne_resultater_placering)
  //           }
  //           return a.staevne_raekker_id.staevne_raekker_navn > b.staevne_raekker_id.staevne_raekker_navn ? 1 : -1
  //         })

  //         this.forceRerenderCompetitionsList()
  //         this.$forceUpdate()

  //         let arrIndex = 0
  //         let membersUserIdParamOptions = ''
  //         for (const objItem of this.staevneresultats) {
  //           if (objItem.medlem_id !== undefined && objItem.medlem_id.id !== undefined && objItem.medlem_id.id !== null) {
  //             membersUserIdParamOptions += ((arrIndex === 0 ? 'id=' : '&id=') + Number(objItem.medlem_id.bruger_id).toString())
  //           }

  //           arrIndex++
  //         }
  //         console.log('[retrieveCompetitionResults] membersUserIdParamOptions = ' + membersUserIdParamOptions)

  //         // Sorting the array by row then the placement within that row.
  //         this.staevneresultats.sort(function (a, b) {
  //           if (a.staevne_raekker_id.staevne_raekker_navn === b.staevne_raekker_id.staevne_raekker_navn) {
  //             return parseInt(a.staevne_resultater_placering) - parseInt(b.staevne_resultater_placering)
  //           }
  //           return a.staevne_raekker_id.staevne_raekker_navn > b.staevne_raekker_id.staevne_raekker_navn ? 1 : -1
  //         })

  //         UsersDataService.getAll('', null, membersUserIdParamOptions)
  //           .then((response) => {
  //             this.tempCompetitionResultUsers = response.data
  //             console.log(response.data)
  //           })
  //           .catch((err) => {
  //             this.error = err
  //             // console.log(err)
  //           })
  //       })
  //       .catch((err) => {
  //         this.error = err
  //         // console.log(err)
  //       })
  //   } else {
  //     // Competitions.CompetitionMemberRegistrationDataService.findByCompetitionRegistrationStatus((this.filterStatusStringValue3 === 'Aktive'), this.currentListSortingOrder3)
  //     Competitions.CompetitionResultsDataService.findByCompetitionResultStatus((this.filterStatusStringValue5 === 'Aktive'), '', null, (Number(this.filterCompetitionNameValue.value) > 0 ? 'staevner_id.id=' + this.filterCompetitionNameValue.value : undefined))
  //       .then((response) => {
  //         this.staevneresultats = response.data
  //         // console.log(response.data)

  //         let arrIndex = 0
  //         let membersUserIdParamOptions = ''
  //         for (const objItem of this.staevneresultats) {
  //           if (objItem.medlem_id !== undefined && objItem.medlem_id.id !== null) {
  //             membersUserIdParamOptions += ((arrIndex === 0 ? 'id=' : '&id=') + Number(objItem.medlem_id.bruger_id).toString())
  //           }

  //           arrIndex++
  //         }
  //         console.log('[retrieveCompetitionResults] membersUserIdParamOptions = ' + membersUserIdParamOptions)

  //         UsersDataService.getAll('', null, membersUserIdParamOptions)
  //           .then((response) => {
  //             this.tempCompetitionResultUsers = response.data
  //             console.log(response.data)
  //           })
  //           .catch((err) => {
  //             this.error = err
  //             // console.log(err)
  //           })

  //         this.forceRerenderCompetitionsList()
  //         this.$forceUpdate()
  //       })
  //       .catch((err) => {
  //         this.error = err
  //         // console.log(err)
  //       })
  //   }
  // }

  public retrieveCompetitionRows () : void {
    console.log('Fetching competition rows data from API.')

    if (this.competitionRowSearchTerm.length > 0) {
      Competitions.CompetitionRowsDataService.findBySearchTerm((this.filterStatusStringValue === 'Aktive'), (this.filterStatusStringValue === 'Vis alt'), this.competitionRowSearchTerm, this.currentCompetitionRowsSortingOrder)
        .then((response) => {
          this.staevneraekkers = response.data
          // console.log(response.data)

          this.fillCompetitionRowsNameOptionsData()
          this.buildColumnDataObjForRows()
          this.forceRerenderCompetitionsList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    } else {
      if (this.filterStatusStringValue === 'Vis alt') {
        Competitions.CompetitionRowsDataService.getAll(this.currentCompetitionRowsSortingOrder)
          .then((response) => {
            this.staevneraekkers = response.data
            // console.log(response.data)

            this.fillCompetitionRowsNameOptionsData()
            this.buildColumnDataObjForRows()
            this.forceRerenderCompetitionsList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      } else {
        Competitions.CompetitionRowsDataService.findByCompetitionRowStatus((this.filterStatusStringValue === 'Aktive'), this.currentCompetitionRowsSortingOrder)
          .then((response) => {
            this.staevneraekkers = response.data
            // console.log(response.data)

            this.fillCompetitionRowsNameOptionsData()
            this.buildColumnDataObjForRows()
            this.forceRerenderCompetitionsList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      }
    }
  }

  public retrieveCompetitions () : void {
    console.log('Counting Competitions')
    let countFilter = ''

    if (this.filterStatusStringValue2 === 'Vis alt') {
      countFilter = this.competitionHeld
    } else if (this.filterStatusStringValue2 === 'Aktive') {
      countFilter = 'staevne_status=true' + (this.competitionHeld === '' ? '' : '&' + this.competitionHeld)
    } else {
      countFilter = 'staevne_status=false' + (this.competitionHeld === '' ? '' : '&' + this.competitionHeld)
    }

    Competitions.CompetitionsDataService.getCount(countFilter)
      .then((response) => {
        this.totalPages = response.data
        this.totalNumberOfPages = Math.ceil(this.totalPages / this.pageSizeValue)
      })
      .catch((err) => {
        this.error = err
      })
    console.log('Fetching competitions data from API.')

    if (this.competitionSearchTerm.length > 0) {
      Competitions.CompetitionsDataService.findBySearchTerm((this.filterStatusStringValue2 === 'Aktive'), (this.filterStatusStringValue2 === 'Vis alt'), this.competitionSearchTerm, this.currentCompetitionSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, `${this.competitionHeld}`)
        .then((response) => {
          this.staevners = response.data
          // console.log(response.data)

          // this.fillCompetitionNameOptionsData()
          this.forceRerenderCompetitionsList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    } else {
      if (this.filterStatusStringValue2 === 'Vis alt') {
        // Competitions.CompetitionsDataService.getAll(this.currentListSortingOrder2, { slicemode: 131, start: 0, limit: -1, page: 1, pagesize: 2, totalcount: 4 })
        Competitions.CompetitionsDataService.getAll(this.currentCompetitionSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, `${this.competitionHeld}`)
          .then((response) => {
            this.staevners = response.data
            // console.log(response.data)

            // this.fillCompetitionNameOptionsData()
            this.forceRerenderCompetitionsList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      } else {
        Competitions.CompetitionsDataService.findByCompetitionStatus((this.filterStatusStringValue2 === 'Aktive'), this.currentCompetitionSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, `${this.competitionHeld}`)
          .then((response) => {
            this.staevners = response.data
            // console.log(response.data)

            // this.fillCompetitionNameOptionsData()
            this.forceRerenderCompetitionsList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      }
    }
  }

  public retrieveCompetitionsForRegistration () : void {
    console.log('Fetching competitions data from API.')
    this.filterRegistrationByCompetitionsOptions = [{ name: 'Intet stævne', id: '0', rows: [] }]
    let nowDate = Date.now()

    BaseFunctions.getDatetimeFromServer()
      .then((response) => {
        nowDate = response.data.slice(0, 11)
      })
      .catch((data) => {
        console.log('Failed to fetch date from server: ' + JSON.stringify(data))
      })
      .finally(() => {
        Competitions.CompetitionsDataService.getAll('', null, `staevne_slut_gte=${nowDate}`)
          .then((response) => {
            this.competitions = response.data

            for (const item of this.competitions) {
              this.filterRegistrationByCompetitionsOptions.push({ name: item.staevne_navn, id: Number(item.id).toString(), rows: item.staevne_raekker_id })
            }
            console.log(this.filterRegistrationByCompetitionsOptions)
          })
          .catch((err) => {
            this.error = err
          })
      })
  }

  public seriesSorting (sortBy: string) : void {
    if (sortBy === 'reset') {
      this.currentSeriesSortingOrder = ''
      this.oldSeriesSortingOrder = ''
    }
    if (sortBy === 'seriesName') {
      if (this.oldSeriesSortingOrder === '' || this.oldSeriesSortingOrder !== sortBy) {
        this.currentSeriesSortingOrder = 'staevne_raekker_navn:asc'
        this.oldSeriesSortingOrder = sortBy
      } else {
        this.currentSeriesSortingOrder = 'staevne_raekker_navn:desc'
        this.oldSeriesSortingOrder = ''
      }
    }
    if (sortBy === 'price') {
      if (this.oldSeriesSortingOrder === '' || this.oldSeriesSortingOrder !== sortBy) {
        this.currentSeriesSortingOrder = 'staevne_raekker_tilmeldingsgebyr:asc'
        this.oldSeriesSortingOrder = sortBy
      } else {
        this.currentSeriesSortingOrder = 'staevne_raekker_tilmeldingsgebyr:desc'
        this.oldSeriesSortingOrder = ''
      }
    }
    if (sortBy === 'type') {
      if (this.oldSeriesSortingOrder === '' || this.oldSeriesSortingOrder !== sortBy) {
        this.currentSeriesSortingOrder = 'staevne_raekker_type:asc'
        this.oldSeriesSortingOrder = sortBy
      } else {
        this.currentSeriesSortingOrder = 'staevne_raekker_type:desc'
        this.oldSeriesSortingOrder = ''
      }
    }

    this.retrieveAllSeries()
  }

  public retrieveAllSeries () : void {
    this.seriesDrawerRight = true

    Competitions.CompetitionRowsDataService.getAll(this.currentSeriesSortingOrder, null, '')
      .then((response) => {
        this.allSeries = response.data
      })
      .catch((err) => {
        this.error = err
      })
  }

  public retrieveCompetitionRowsForRegistration () : void {
    console.log('Fetching competition rows from API.')

    Competitions.CompetitionRowsDataService.getAll('', null, this.extraRegistrationParameter)
      .then((response) => {
        this.competitionRowsForRegistration = response.data

        this.buildColumnDataObj()
      })
      .catch((err) => {
        this.error = err
      })
  }

  public editPlayerRegistration () : void {
    this.editRegistrationModal = true
    this.retrieveCompetitionRowsForRegistration()
  }

  public getUserFirstName (userId: number | null) : string {
    let retVal = '????'
    const indexUsers = this.tempCompetitionRegistrationUsers.findIndex(x => x.id === userId)

    if (indexUsers !== undefined && this.tempCompetitionRegistrationUsers[indexUsers] !== undefined) {
      retVal = this.tempCompetitionRegistrationUsers[indexUsers].firstname
    }

    return retVal
  }

  public getUserLastName (userId: number | null) : string {
    let retVal = '????'
    const indexUsers = this.tempCompetitionRegistrationUsers.findIndex(x => x.id === userId)

    if (indexUsers !== undefined && this.tempCompetitionRegistrationUsers[indexUsers] !== undefined) {
      retVal = this.tempCompetitionRegistrationUsers[indexUsers].lastname
    }

    return retVal
  }

  public getUserEmail (userId: number | null) : string {
    let retVal = '????'
    const indexUsers = this.tempCompetitionRegistrationUsers.findIndex(x => x.id === userId)

    if (indexUsers !== undefined && this.tempCompetitionRegistrationUsers[indexUsers] !== undefined) {
      retVal = this.tempCompetitionRegistrationUsers[indexUsers].email
    }

    return retVal
  }

  public getISOCode (country: string | undefined) : string {
    let retVal = ''
    const indexCountry = countryList.findIndex(x => x.name === country)

    if (indexCountry !== undefined && countryList[indexCountry] !== undefined) {
      retVal = countryList[indexCountry].code3
    }

    return retVal
  }

  public exportDartConnect () : void {
    let exportDartConnect: SysCompetitionRegistration[] = []

    if (this.filterRegistrationByCompetitionsValue.id !== '0') {
      Competitions.CompetitionMemberRegistrationDataService.getAll('', null, `staevner_id=${this.filterRegistrationByCompetitionsValue.id}`)
        .then((response) => {
          exportDartConnect = response.data
          console.log(exportDartConnect)

          let exportArray: {licens: string, gender: string, iso: string; emailAddress: string, firstName: string, LastName: string, }[] = []

          for (const item of exportDartConnect) {
            if (item.medlem_id.length > 0) {
              for (const medlem of item.medlem_id) {
                exportArray.push({
                  licens: (medlem.medlem_licens === null ? '' : medlem.medlem_licens.toString()),
                  gender: (medlem.koen_id === 1 ? 'F' : 'M'),
                  iso: 'DNK',
                  emailAddress: this.getUserEmail(medlem.user_id),
                  firstName: this.getUserFirstName(medlem.user_id),
                  LastName: this.getUserLastName(medlem.user_id)
                })
              }
            }
            if (item.usp_id.length > 0) {
              for (const foreign of item.usp_id) {
                exportArray.push({
                  licens: '',
                  gender: (foreign.koen_id === 1 ? 'F' : 'M'),
                  iso: this.getISOCode(foreign.ud_spiller_land),
                  emailAddress: foreign.ud_spiller_mail,
                  firstName: foreign.ud_spiller_fornavn,
                  LastName: foreign.ud_spiller_efternavn
                })
              }
            }
          }
          exportArray = exportArray.filter((value, index, self) => index === self.findIndex((t) => (t.licens === value.licens && t.gender === value.gender && t.iso === value.iso && t.emailAddress === value.emailAddress && t.firstName === value.firstName && t.LastName === value.LastName)))
          console.log(exportArray)

          const data: any[] = exportArray.map(item => {
            return {
              OrgPlayerId: item.licens,
              Gender: item.gender,
              ISOCountryCode: item.iso,
              EmailAddress: item.emailAddress,
              FirstName: item.firstName,
              LastName: item.LastName
            }
          })
          const fileName = this.filterRegistrationByCompetitionsValue.name.replaceAll(' ', '_')
          const exportType = exportFromJSON.types.xls

          exportFromJSON({ data, fileName, exportType })
        })
        .catch((err) => {
          this.error = err
        })
    }
  }

  get isDisabledRegisterDCPlayers () : boolean {
    if (this.filteredStaevnetilmeldings?.length > 0) {
      return false
    } else {
      return true
    }
  }

  get selctedCompetitionObject () : any {
    const selectedCompObject = this.competitions.find((item) => item.id === Number(this.filterRegistrationByCompetitionsValue.id))
    return selectedCompObject
  }

  // Register players for Dart Connect tournament.
  public registerPlayersForTheTournament () : void {
    if (this.filterRegistrationByCompetitionsValue.id !== '0' && this.selctedCompetitionObject?.staevner_tournament_id) {
      let exportDartConnect: SysCompetitionRegistration[] = []
      Competitions.CompetitionMemberRegistrationDataService.getAll('', null, `staevner_id=${this.filterRegistrationByCompetitionsValue.id}`)
        .then((response) => {
          console.log('getRegisteredPlayers:', response.data)
          exportDartConnect = response.data

          let exportArray: {org_player_id: string, gender: string, iso3_country: string; email: string, first_name: string, last_name: string, date_of_birth: string, chalkboard_name: string }[] = []

          for (const item of exportDartConnect) {
            if (item.medlem_id.length > 0) {
              for (const medlem of item.medlem_id) {
                exportArray.push({
                  date_of_birth: medlem.medlem_foedselsdag ? medlem.medlem_foedselsdag : '',
                  org_player_id: (medlem.medlem_licens === null ? '' : medlem.medlem_licens.toString()),
                  gender: (medlem.koen_id === 1 ? 'F' : 'M'),
                  iso3_country: 'DNK',
                  email: this.getUserEmail(medlem.user_id),
                  first_name: this.getUserFirstName(medlem.user_id),
                  last_name: this.getUserLastName(medlem.user_id),
                  chalkboard_name: ''
                })
              }
            }
            if (item.usp_id.length > 0) {
              for (const foreign of item.usp_id) {
                exportArray.push({
                  date_of_birth: foreign.ud_foedselsdag ? foreign.ud_foedselsdag : '',
                  org_player_id: '',
                  gender: (foreign.koen_id === 1 ? 'F' : 'M'),
                  iso3_country: this.getISOCode(foreign.ud_spiller_land),
                  email: foreign.ud_spiller_mail,
                  first_name: foreign.ud_spiller_fornavn,
                  last_name: foreign.ud_spiller_efternavn,
                  chalkboard_name: ''
                })
              }
            }
          }
          exportArray = exportArray.filter((value, index, self) => index === self.findIndex((t) => (t.org_player_id === value.org_player_id && t.gender === value.gender && t.iso3_country === value.iso3_country && t.email === value.email && t.first_name === value.first_name && t.last_name === value.last_name)))
          DartConnect.DartConnectDataService.getTournamentPlayers(this.selctedCompetitionObject.staevner_tournament_id)
          // DartConnect.DartConnectDataService.getTournamentPlayers('mt_arno_10')
            .then((players) => {
              const registeredPlayers = players.data
              // Fillter out already registerd players
              exportArray = exportArray.filter((obj1: any) =>
                !registeredPlayers.some((obj2: any) => obj1.email === obj2.email)
              )

              if (exportArray.length > 0) {
                exportArray.map((item) => {
                  DartConnect.DartConnectDataService.setPlayerToTournament(this.selctedCompetitionObject.staevner_tournament_id, item)
                    .then((response) => {
                      console.log('RegisteredPlayer', response)
                      if (response?.response?.data?.message) {
                        this.$Message.warning({ text: `${item.first_name} ${item.last_name} :${response?.response?.data?.message}` })
                      }
                    })
                    .catch((error) => {
                      console.log('RegisterPlayerErr', error)
                    })
                })
              } else {
                this.$Message.warning({ text: 'No new players to register' })
              }
            })
            .catch((error) => {
              console.log('Player error', error)
            })
        })
        .catch((err) => {
          console.log('getRegisteredPlayers:', err)
        })
    } else {
      this.$Message.warning({ text: 'Please select Dart Connect staevner_tournament_id' })
    }
  }

  public async retrieveExportData () : Promise<void> {
    // First ensure that a competition is selected
    if (this.filterRegistrationByCompetitionsValue.id !== '0') {
      this.exportLoader = true
      // Fetch the competition from API.
      const retrieveCompetitionPromise = Competitions.CompetitionsDataService.get(this.filterRegistrationByCompetitionsValue.id)
      // Fetch registrations.
      const retrieveCompetitionRegistrationPromise = Competitions.CompetitionMemberRegistrationDataService.getAll('', null, `staevner_id=${this.filterRegistrationByCompetitionsValue.id}` + (this.filterRegistrationsBySeriesValue.id === 0 ? '' : `&staevne_raekker_id=${this.filterRegistrationsBySeriesValue.id}`))

      await Promise.all([retrieveCompetitionPromise, retrieveCompetitionRegistrationPromise])
        .then(async (response) => {
          const competition = response[0].data as SysCompetition
          const registrations = response[1].data as SysCompetitionRegistration[]

          console.log('[retrieveExportData()] competition = ' + JSON.stringify(competition))
          console.log('[retrieveExportData()] registrations = ' + JSON.stringify(registrations))

          if (this.filterRegistrationsBySeriesValue.id === 0) {
            for (const item of this.filterRegistrationBySeriesOptions) {
              console.log(item.name + ' registered: ' + registrations.filter(el => Number(el.staevne_raekker_id.id) === item.id).length)
              let rankingsSeries = item.name.toLowerCase()

              if (registrations.filter(el => Number(el.staevne_raekker_id.id) === item.id).length > 0) {
                const registeredPlayers = registrations.filter(el => Number(el.staevne_raekker_id.id) === item.id)

                if (competition.staevne_kategori !== competitionCategoryFriendlyType.Kategori4 && (item.name.toLowerCase().slice(-1) === 'a' || item.name.toLowerCase().includes('single') || item.name.toLowerCase() === 'paradart')) {
                  // The series is a series that have seeding.
                  // Fetch some results.
                  const data: { Navne: string, Seeded: string, Klub: string, Licens: string | number, Række: string, Betalt: string }[] = []
                  let resultsParamenter = ''
                  let foreignResultsParamenter = ''
                  let oldResultsParamenter = ''
                  let maxSeeded = 0
                  let rankingCalcType = -1
                  const dateParameter = CommonFunctions.getLastYearDateString(competition.staevne_sidstetilmelding)
                  console.log('[retrieveExportData()] Start dato: ' + dateParameter)
                  console.log('[retrieveExportData()] Sidste tilmelding : ' + competition.staevne_sidstetilmelding)

                  switch (true) {
                    case item.name.toLowerCase().includes('herre'):
                      // resultsParamenter = '_where[_or][0][staevne_raekker_id.staevne_raekker_navn_contains]=herre&_where[_or][1][0][staevne_raekker_id.staevne_raekker_navn_contains]=c-rækken&_where[_or][1][1][medlem_id.koen_id]=2'
                      resultsParamenter = '_where[_or][0][staevne_raekker_id.staevne_raekker_navn_contains]=herre&_where[_or][1][staevne_raekker_id.staevne_raekker_navn_contains]=aaben&_where[_or][2][0][staevne_raekker_id.staevne_raekker_navn_contains]=c-rækken&_where[_or][2][1][medlem_id.koen_id]=2'
                      foreignResultsParamenter = 'staevne_raekker_id.staevne_raekker_navn_contains=herre'
                      // oldResultsParamenter = '_where[_or][0][Klasse_contains]=her'
                      oldResultsParamenter = 'Klasse_contains=her'
                      maxSeeded = 16
                      rankingsSeries = 'herrer'
                      rankingCalcType = 0
                      break
                    case item.name.toLowerCase().includes('aaben'):
                      // resultsParamenter = '_where[_or][0][staevne_raekker_id.staevne_raekker_navn_contains]=herre&_where[_or][1][0][staevne_raekker_id.staevne_raekker_navn_contains]=c-rækken&_where[_or][1][1][medlem_id.koen_id]=2'
                      resultsParamenter = '_where[_or][0][staevne_raekker_id.staevne_raekker_navn_contains]=herre&_where[_or][1][staevne_raekker_id.staevne_raekker_navn_contains]=aaben&_where[_or][2][0][staevne_raekker_id.staevne_raekker_navn_contains]=c-rækken&_where[_or][2][1][medlem_id.koen_id]=2'
                      foreignResultsParamenter = 'staevne_raekker_id.staevne_raekker_navn_contains=herre'
                      // oldResultsParamenter = '_where[_or][0][Klasse_contains]=her'
                      oldResultsParamenter = 'Klasse_contains=her'
                      maxSeeded = 16
                      rankingsSeries = 'herrer'
                      rankingCalcType = 0
                      break
                    case item.name.toLowerCase().includes('dame'):
                      resultsParamenter = '_where[_or][0][staevne_raekker_id.staevne_raekker_navn_contains]=dame&_where[_or][1][0][staevne_raekker_id.staevne_raekker_navn_contains]=c-rækken&_where[_or][1][0][medlem_id.koen_id]=1'
                      foreignResultsParamenter = 'staevne_raekker_id.staevne_raekker_navn_contains=dame'
                      // oldResultsParamenter = '_where[_or][0][Klasse_contains]=dam'
                      oldResultsParamenter = 'Klasse_contains=dam'
                      maxSeeded = 8
                      rankingsSeries = 'damer'
                      rankingCalcType = 0
                      break
                    case item.name.toLowerCase().includes('drenge'):
                      resultsParamenter = '_where[_or][0][staevne_raekker_id.staevne_raekker_navn_contains]=drenge&_where[_or][1][staevne_raekker_id.staevne_raekker_navn_contains]=junior'
                      foreignResultsParamenter = 'staevne_raekker_id.staevne_raekker_navn_contains=drenge'
                      // oldResultsParamenter = '_where[_or][0][Klasse_contains]=dre'
                      oldResultsParamenter = 'Klasse_contains=dre'
                      maxSeeded = 4
                      rankingsSeries = 'dreng'
                      rankingCalcType = 0
                      break
                    case item.name.toLowerCase().includes('pige'):
                      resultsParamenter = 'staevne_raekker_id.staevne_raekker_navn_contains=pige'
                      foreignResultsParamenter = 'staevne_raekker_id.staevne_raekker_navn_contains=pige'
                      // oldResultsParamenter = '_where[_or][0][Klasse_contains]=pig'
                      oldResultsParamenter = 'Klasse_contains=pig'
                      maxSeeded = 4
                      rankingsSeries = 'pige'
                      rankingCalcType = 0
                      break
                    case item.name.toLowerCase() === 'paradart':
                      resultsParamenter = 'staevne_raekker_id.staevne_raekker_navn_contains=para'
                      foreignResultsParamenter = 'staevne_raekker_id.staevne_raekker_navn_contains=paradart'
                      // oldResultsParamenter = '_where[_or][0][Klasse_contains]=par'
                      oldResultsParamenter = 'Klasse_contains=par'
                      maxSeeded = 8
                      rankingsSeries = 'paradart'
                      rankingCalcType = 0
                      break
                    default:
                      resultsParamenter = ''
                      foreignResultsParamenter = ''
                      oldResultsParamenter = ''
                      maxSeeded = 0
                  }

                  const resultsPromise = Competitions.CompetitionResultsDataService.getAll('', null, (resultsParamenter + `&staevne_resultater_dato_gte=${dateParameter}&staevne_resultater_dato_lte=${competition.staevne_sidstetilmelding}&medlem_id_ne=null&medlem_id.id_ne=17&staevne_ranglistepoint_ne=0`))
                  const foreignResultsPromise = Competitions.CompetitionForeignResultsDataService.getAll('', null, (foreignResultsParamenter + `&ud_resultat_dato_gte=${dateParameter}&ud_resultat_dato_lte=${competition.staevne_sidstetilmelding}&medlem_id_ne=null&medlem_id.id_ne=17&ud_resultat_ranglistepoint_ne=0`))
                  // const oldResultsPromise = Competitions.OldCompetitionResultsDataService.getAll('', null, (oldResultsParamenter + `&Dato_gte=${dateParameter}&Dato_lte=${competition.staevne_sidstetilmelding}`))
                  // await Promise.all([resultsPromise, foreignResultsPromise, oldResultsPromise])
                  await Promise.all([resultsPromise, foreignResultsPromise])
                    .then((response) => {
                      const results = response[0].data as SysCompetitionResults[]
                      const foreignResults = response[1].data as SysForeignCompetitionResults[]
                      // const oldResults = response[2].data as SysOldCompetitionResults[]
                      const oldResults = [] as SysOldCompetitionResults[]
                      // console.log('[retrieveExportData()] results = ' + JSON.stringify(results))
                      // console.log('[retrieveExportData()] foreignResults = ' + JSON.stringify(foreignResults))
                      // console.log('[retrieveExportData()] oldResults = ' + JSON.stringify(oldResults))
                      const rankingInformation: { id: number, licens: number, total: number }[] = []

                      // Process information from regular competition results.
                      for (const result of results) {
                        const indexId = rankingInformation.findIndex(el => Number(el.id) === Number(result.medlem_id.id))

                        if (indexId === -1) {
                          rankingInformation.push({
                            id: (result.medlem_id.id === undefined ? 0 : Number(result.medlem_id.id)),
                            licens: (result.medlem_id.medlem_licens === undefined ? 0 : Number(result.medlem_id.medlem_licens)),
                            total: (rankingCalcType >= 0 ? results.filter(el => Number(el.medlem_id.id) === Number(result.medlem_id.id) && (el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes(rankingsSeries.substring(0, 3)) || (rankingsSeries === 'herrer' && el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('aaben')) || ((rankingsSeries === 'herrer' || rankingsSeries === 'damer') && el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('c-rækken')) || ((rankingsSeries.includes('dreng') || rankingsSeries.includes('boy') || rankingsSeries.includes('pige') || rankingsSeries.includes('girl') || rankingsSeries.includes('junior')) && el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('juniorsingle')))).reduce(function (acc, obj) { return acc + obj.staevne_ranglistepoint }, 0) + foreignResults.filter(el => Number(el.medlem_id.id) === Number(result.medlem_id.id) && (el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes(rankingsSeries.substring(0, 3)) || ((rankingsSeries === 'herrer' || rankingsSeries === 'damer') && el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('c-rækken')) || ((rankingsSeries.includes('dreng') || rankingsSeries.includes('boy') || rankingsSeries.includes('pige') || rankingsSeries.includes('girl') || rankingsSeries.includes('junior')) && el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('juniorsingle')))).reduce(function (acc, obj) { return acc + obj.ud_resultat_ranglistepoint }, 0) + oldResults.filter(el => Number(el.Licens) === Number(result.medlem_id.medlem_licens)).reduce(function (acc, obj) { return acc + obj.Point }, 0)
                              : results.filter(el => Number(el.medlem_id.id) === Number(result.medlem_id.id)).reduce(function (acc, obj) { return acc + obj.staevne_ranglistepoint }, 0) + foreignResults.filter(el => Number(el.medlem_id.id) === Number(result.medlem_id.id)).reduce(function (acc, obj) { return acc + obj.ud_resultat_ranglistepoint }, 0) + oldResults.filter(el => Number(el.Licens) === Number(result.medlem_id.medlem_licens)).reduce(function (acc, obj) { return acc + obj.Point }, 0))
                            // total: results.filter(el => Number(el.medlem_id.id) === Number(result.medlem_id.id)).reduce(function (acc, obj) { return acc + obj.staevne_ranglistepoint }, 0) + foreignResults.filter(el => Number(el.medlem_id.id) === Number(result.medlem_id.id)).reduce(function (acc, obj) { return acc + obj.ud_resultat_ranglistepoint }, 0) + oldResults.filter(el => Number(el.Licens) === Number(result.medlem_id.medlem_licens)).reduce(function (acc, obj) { return acc + obj.Point }, 0)
                          })
                          // console.log('[retrieveExportData()] Member Id: ' + Number(result.medlem_id.id) + ' ;  Adding old results = ' + oldResults.filter(el => Number(el.Licens) === Number(result.medlem_id.medlem_licens)).reduce(function (acc, obj) { return acc + obj.Point }, 0))
                        }
                      }

                      // Process information from foreign players competition results.
                      for (const foreignResult of foreignResults) {
                        const indexId = rankingInformation.findIndex(el => Number(el.id) === Number(foreignResult.medlem_id.id))
                        const indexLic = registeredPlayers.findIndex(el => el.medlem_id.length > 0 && Number(el.medlem_id[0].id) === Number(foreignResult.medlem_id.id))

                        if (indexId === -1) {
                          rankingInformation.push({
                            id: (foreignResult.medlem_id.id === undefined ? 0 : Number(foreignResult.medlem_id.id)),
                            licens: (indexLic >= 0 && registeredPlayers[indexLic].medlem_id.length === 1 && registeredPlayers[indexLic].medlem_id !== null && registeredPlayers[indexLic].medlem_id[0].medlem_licens !== null ? registeredPlayers[indexLic].medlem_id[0].medlem_licens : 0),
                            total: (rankingCalcType >= 0 ? foreignResults.filter(el => Number(el.medlem_id.id) === Number(foreignResult.medlem_id.id) && (el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes(rankingsSeries.substring(0, 3)) || (rankingsSeries === 'herrer' && el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('aaben')) || ((rankingsSeries === 'herrer' || rankingsSeries === 'damer') && el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('c-rækken')) || ((rankingsSeries.includes('dreng') || rankingsSeries.includes('boy') || rankingsSeries.includes('pige') || rankingsSeries.includes('girl') || rankingsSeries.includes('junior')) && el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('juniorsingle')))).reduce(function (acc, obj) { return acc + obj.ud_resultat_ranglistepoint }, 0)
                              : foreignResults.filter(el => Number(el.medlem_id.id) === Number(foreignResult.medlem_id.id)).reduce(function (acc, obj) { return acc + obj.ud_resultat_ranglistepoint }, 0))
                            // total: foreignResults.filter(el => Number(el.medlem_id.id) === Number(foreignResult.medlem_id.id)).reduce(function (acc, obj) { return acc + obj.ud_resultat_ranglistepoint }, 0)
                          })
                        }
                      }

                      // Process information from old competition results.
                      for (const oldResult of oldResults) {
                        const indexId = registeredPlayers.findIndex(el => el.medlem_id.length > 0 && Number(el.medlem_id[0].medlem_licens) === Number(oldResult.Licens))
                        const indexLic = rankingInformation.findIndex(el => Number(el.licens) === Number(oldResult.Licens))

                        if (indexLic === -1) {
                          rankingInformation.push({
                            id: (indexId >= 0 && registeredPlayers[indexId].medlem_id.length === 1 && registeredPlayers[indexId].medlem_id !== null && registeredPlayers[indexId].medlem_id[0].id !== null ? Number(registeredPlayers[indexId].medlem_id[0].id) : 0),
                            licens: (oldResult.Licens === undefined ? 0 : Number(oldResult.Licens)),
                            total: oldResults.filter(el => Number(el.Licens) === Number(oldResult.Licens)).reduce(function (acc, obj) { return acc + obj.Point }, 0)
                          })
                        }
                      }

                      rankingInformation.sort((a, b) => b.total - a.total)
                      // console.log('[retrieveExportData()] rankingInformation = ' + JSON.stringify(rankingInformation))

                      for (let i = 0, seeded = 1; i < rankingInformation.length && seeded <= maxSeeded; i++) {
                        const index = registeredPlayers.findIndex(el => el.medlem_id.length > 0 && Number(el.medlem_id[0].id) === Number(rankingInformation[i].id))

                        if (index !== -1) {
                          data.push({
                            Navne: this.getNamesOfPlayers(registeredPlayers[index].medlem_id, registeredPlayers[index].usp_id),
                            Seeded: seeded.toString(),
                            Klub: this.getTextOfClubOrCountryFromObject(registeredPlayers[index].medlem_id, registeredPlayers[index].usp_id),
                            Licens: (registeredPlayers[index].medlem_id.length === 1 && registeredPlayers[index].medlem_id !== null && registeredPlayers[index].medlem_id[0].medlem_licens !== null ? registeredPlayers[index].medlem_id[0].medlem_licens : ''),
                            Række: item.name,
                            Betalt: (registeredPlayers[index].staevne_tilmelding_status ? 'Ja' : 'Nej')
                          })

                          seeded++
                          registeredPlayers.splice(index, 1)
                        }
                      }

                      if (registeredPlayers.length > 0) {
                        for (const player of registeredPlayers) {
                          data.push({
                            Navne: this.getNamesOfPlayers(player.medlem_id, player.usp_id),
                            Seeded: '',
                            Klub: this.getTextOfClubOrCountryFromObject(player.medlem_id, player.usp_id),
                            Licens: (player.medlem_id.length === 1 && player.medlem_id !== null && player.medlem_id[0].medlem_licens !== null ? player.medlem_id[0].medlem_licens : ''),
                            Række: item.name,
                            Betalt: (player.staevne_tilmelding_status ? 'Ja' : 'Nej')
                          })
                        }
                      }

                      const fileName = 'tilmeldinger_' + competition.staevne_navn.replaceAll(' ', '_') + '_' + item.name.replaceAll(' ', '_')
                      const exportType = exportFromJSON.types.xls
                      exportFromJSON({ data, fileName, exportType })
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                } else {
                  const data: { Navne: string, Klub: string, Licens: string | number, Række: string, Betalt: string }[] = []

                  for (const player of registeredPlayers) {
                    data.push({
                      Navne: this.getNamesOfPlayers(player.medlem_id, player.usp_id),
                      Klub: this.getTextOfClubOrCountryFromObject(player.medlem_id, player.usp_id),
                      Licens: (player.medlem_id.length === 1 && player.medlem_id !== null && player.medlem_id[0].medlem_licens !== null ? player.medlem_id[0].medlem_licens : ''),
                      Række: item.name,
                      Betalt: (player.staevne_tilmelding_status ? 'Ja' : 'Nej')
                    })
                  }

                  const fileName = 'tilmeldinger_' + competition.staevne_navn.replaceAll(' ', '_') + '_' + item.name.replaceAll(' ', '_')
                  const exportType = exportFromJSON.types.xls
                  exportFromJSON({ data, fileName, exportType })
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

    this.exportLoader = false
  }

  // public retrieveExportData () : void {
  //   let exportData: any[] = []

  //   if (this.filterRegistrationByCompetitionsValue.id !== '0') {
  //     Competitions.CompetitionMemberRegistrationDataService.getAll('', null, `staevner_id=${this.filterRegistrationByCompetitionsValue.id}`) // Initial fetch
  //       .then((response) => {
  //         exportData = response.data
  //         console.log(exportData)

  //         exportData = exportData.map((option) => {
  //           const newProp = {
  //             names: this.getNamesOfPlayers(option.medlem_id, option.usp_id),
  //             countryOrClub: this.getTextOfClubOrCountryFromObject(option.medlem_id, option.usp_id)
  //           }

  //           return Object.assign(option, newProp)
  //         })
  //         exportData = exportData.map(item => {
  //           return {
  //             Navne: item.names,
  //             Klub: item.countryOrClub,
  //             Licens: (item.medlem_id.length === 1 && item.medlem_id !== null && item.medlem_id[0].medlem_licens !== null ? item.medlem_id[0].medlem_licens : ''),
  //             Række: item.staevne_raekker_id.staevne_raekker_navn,
  //             Betalt: (item.staevne_tilmelding_status ? 'Ja' : 'Nej')
  //           }
  //         })
  //         for (const item of <any> this.filterRegistrationByCompetitionsValue.rows) {
  //           const data = exportData.filter((element) => { return (element.Række === item.staevne_raekker_navn) })
  //           const fileName = 'tilmeldinger_' + this.filterRegistrationByCompetitionsValue.name.replaceAll(' ', '_') + '_' + item.staevne_raekker_navn.replaceAll(' ', '_')
  //           const exportType = exportFromJSON.types.xls

  //           console.log(data)

  //           if (data.length !== 0) {
  //             exportFromJSON({ data, fileName, exportType })
  //           }
  //         }
  //       })
  //       .catch((data) => {
  //         console.log('Error fetching registered Members: ' + JSON.stringify(data))
  //       })
  //   }
  // }

  public getNamesOfPlayers (objArray: { id: number | null, medlem_licens: number, user_id: number | null }[], objArray2: { id: number | null, ud_spiller_fornavn: string, ud_spiller_efternavn: string }[] = []) : string {
    let retVal = ''
    for (const objItem of objArray) {
      retVal = retVal + this.getUserNameFromCompetitionRegistrationsUsers(objItem.user_id) + (Number(objItem.id) === Number(objArray[objArray.length - 1].id) ? '' : ', ')
    }
    let firstLoop = true
    for (const objItem of objArray2) {
      retVal = retVal + (firstLoop && retVal.length > 0 ? ', ' : '') + (objItem.ud_spiller_fornavn === null ? '' : objItem.ud_spiller_fornavn + ' ') + (objItem.ud_spiller_efternavn === null ? '' : objItem.ud_spiller_efternavn) + (Number(objItem.id) === Number(objArray2[objArray2.length - 1].id) ? '' : ', ')
      firstLoop = false
    }

    return retVal
  }

  // All methods related to createing and editing foreign results
  @Watch('foreignCompetitionValue')
  onForeignCompetitionValueChange () : void {
    if (this.existingForeignCompetition && this.foreignCompetitionValue.id !== 0) {
      this.foreignPlayerCompetitionData.foreignCompetitionName = this.foreignCompetitionValue.name
      this.foreignPlayerCompetitionData.foreignCompetitionDate = this.foreignCompetitionValue.date
    }
  }

  @Watch('foreignPlayerCompetitionData.foreignCompetitionDate')
  onDateChange () : void {
    this.foreignCompetitionDate = this.foreignPlayerCompetitionData.foreignCompetitionDate
  }

  @Watch('existingForeignCompetition')
  onCheckValueChange (newVal: boolean) : void {
    if (!newVal) {
      this.foreignPlayerCompetitionData = { foreignCompetitionName: '', foreignCompetitionDate: '' }
    }
  }

  @Watch('backSpace', { deep: true })
  onBackSpacePress () : void {
    if (this.backSpace === 'Backspace' && this.memberSearchInputString.length !== 0) {
      this.memberSearchInputString = this.memberSearchInputString.substring(0, this.memberSearchInputString.length - 1)
      this.backSpace = ''
    }
  }

  @Watch('memberSearchInputString', { deep: true })
  watchInputString () : void {
    console.log(this.memberSearchInputString)
    if (this.memberSearchInputString.length > 3) {
      this.memberSearchTerm = this.memberSearchInputString
      this.findMembers()
    }
  }

  @Watch('foreignCompetitionRankingValue')
  onPlacementChange () : void {
    if (this.foreignCompetitionRankingValue.index !== -1) {
      this.foreignCompetitionPoints = this.foreignCompetitionRankingValue.awardedPoints
      console.log(this.foreignCompetitionPoints + ' : ' + this.foreignCompetitionRankingValue.awardedPoints)
    }
  }

  @Watch('foreignCompetitionResults')
  onResusltsBooleanValueChange (newVal : boolean) : void {
    this.filterCompetitionNameValue = { name: 'Intet stævne', value: '0' }
    if (newVal) {
      this.filterCompetitionNameOptions = this.tempFilterForeignCompetitionNameOptions
    }
    if (!newVal) {
      this.filterCompetitionNameOptions = this.tempFilterCompetitionNameOptions
    }
  }

  @Watch('historicalCompetitionResultsValue')
  onResultsValueChange () : void {
    this.filterCompetitionNameValue = { name: 'Intet stævne', value: '0' }
    this.fillForeignCompetitionNameOptionsData()
    this.fillCompetitionNameOptionsData()
  }

  public addForeignResults () : void {
    this.foreignCompetitionResultsModal = true
    this.retrieveForeignCompetitions()
    this.retrieveForeignCompetitionRankingPoints()
    this.retrieveRowForForeignCompetitions()
  }

  public retrieveRowForForeignCompetitions () : void {
    let tempRows: SysCompetitionRows[] = []
    this.foreignCompetitionRowsOptions = []

    Competitions.CompetitionRowsDataService.getAll('', null, 'staevne_raekker_type=0')
      .then((response) => {
        tempRows = response.data

        for (const item of tempRows) {
          this.foreignCompetitionRowsOptions.push({ id: Number(item.id), name: item.staevne_raekker_navn })
        }
      })
      .catch((err) => {
        this.error = err
      })
  }

  public retrieveForeignCompetitionRankingPoints () : void {
    this.foreignCompetitionRankingOptions = []
    let foreignCompetitionRankingPoints: SysCompetitionRankingsPoint[] = []

    Competitions.CompetitionRankingsPointsDataService.getAll('', null, 'staevne_ranglistepoint_kategori=kategori4')
      .then((response) => {
        foreignCompetitionRankingPoints = response.data
        console.log(foreignCompetitionRankingPoints)

        for (let i = 0; i < foreignCompetitionRankingPoints.length; i++) {
          this.foreignCompetitionRankingOptions.push({ placement: foreignCompetitionRankingPoints[i].staevne_ranglistepoint_placering, awardedPoints: foreignCompetitionRankingPoints[i].staevne_ranglistepoint_point, index: i })
        }
        this.foreignCompetitionRankingOptions.sort((a, b) => a.index - b.index)
        console.log(this.foreignCompetitionRankingOptions)
      })
      .catch((err) => {
        this.error = err
      })
  }

  public retrieveForeignCompetitions () : void {
    this.foreignCompetitionOptions = []
    Competitions.ForeignCompetitionDataService.getAll('', null, '')
      .then((response) => {
        this.foreignCompetitons = response.data

        for (const item of this.foreignCompetitons) {
          this.foreignCompetitionOptions.push({ name: item.ud_staevne_navn, id: Number(item.id), date: item.ud_staevne_dato })
        }
      })
      .catch((err) => {
        this.error = err
      })
  }

  public editForeignCompetition (id: number) : void {
    let tempForeignCompetition = {} as SysForeignCompetition
    this.lastEditedForeignCompetition = id

    Competitions.ForeignCompetitionDataService.get(id.toString())
      .then((response) => {
        tempForeignCompetition = response.data

        this.foreignPlayerCompetitionData = { foreignCompetitionName: tempForeignCompetition.ud_staevne_navn, foreignCompetitionDate: tempForeignCompetition.ud_staevne_dato }
        this.editForeignCompetitionModal = true
      })
      .catch((err) => {
        this.error = err
      })
  }

  public cancelForeignCompetitionEdit () : void {
    this.foreignPlayerCompetitionData = { foreignCompetitionName: '', foreignCompetitionDate: '' }
  }

  public updateForeignCompetition () : boolean {
    if (this.foreignPlayerCompetitionData.foreignCompetitionName.length > 1 && this.foreignPlayerCompetitionData.foreignCompetitionDate !== '') {
      const updateForeignCompetitionData = {
        ud_staevne_navn: this.foreignPlayerCompetitionData.foreignCompetitionName,
        ud_staevne_dato: this.foreignPlayerCompetitionData.foreignCompetitionDate
      }

      Competitions.ForeignCompetitionDataService.update(this.lastEditedForeignCompetition.toString(), updateForeignCompetitionData)
        .then((response) => {
          console.log(response.data)

          this.editForeignCompetitionModal = false
          this.$Message.success({ text: 'Stævnet er blevet opdateret' })
          this.retrieveForeignCompetitions()

          return true
        })
        .catch((err) => {
          this.error = err

          return false
        })
    }
    return false
  }

  public clearInput () : void {
    this.memberSearchInputString = ''
  }

  public findMembers () : void {
    this.findMemberOptions = [{ id: 0, name: 'Vælg medlem' }]

    MembersDataService.findByNameAndLicenseSearchTerm(this.memberSearchTerm)
      .then((response) => {
        this.tempMembers = response.data
        for (const member of this.tempMembers) {
          this.findMemberOptions.push({
            id: Number(member.id),
            name: member.user_id.firstname + ' ' + member.user_id.lastname + ' [' + (member.medlem_licens === null ? 'Ingen' : member.medlem_licens) + '] '
          })
        }
        console.log('FindMemberOptions: ' + JSON.stringify(this.findMemberOptions))
      })
      .catch((err) => {
        this.error = err
      })
  }

  public addResultsToArray () : boolean {
    if (this.findMemberValue.id === 0 ||
      this.foreignCompetitionRowsValue.id === 0 ||
      this.foreignCompetitionDate === '' ||
      this.foreignCompetitionRankingValue.index === -1 ||
      this.foreignCompetitionPoints <= 0) {
      this.$Message.warning({ text: 'Et eller flere felter er ikke udfyldt korrekt' })

      return false
    }
    this.foreignCompetitionResultsArray.push({
      name: this.findMemberValue.name,
      memberId: this.findMemberValue.id,
      rowName: this.foreignCompetitionRowsValue.name,
      rowId: this.foreignCompetitionRowsValue.id,
      date: this.foreignCompetitionDate,
      placement: this.foreignCompetitionRankingValue.placement,
      points: this.foreignCompetitionPoints,
      numberInArray: this.foreignCompetitionResultsArray.length
    })
    this.resetForeignPlayerCompetitionData()

    return true
  }

  public resetForeignPlayerCompetitionData () : void {
    this.findMemberValue = { id: 0, name: 'Vælg medlem' }
    this.memberSearchInputString = ''
    this.memberSearchTerm = ''
    this.foreignCompetitionRowsValue = { id: 0, name: 'Vælg række' }
    this.foreignCompetitionRankingValue = { placement: 'Vælg placering', awardedPoints: 0, index: -1 }
    this.foreignCompetitionPoints = 0
    this.findMemberOptions = [{ id: 0, name: 'Vælg medlem' }]
  }

  public removeResult (index: number) : void {
    this.foreignCompetitionResultsArray.splice(index, 1)
  }

  public createForeignResults () : boolean {
    const asyncCreateForeignCompetitionResults = async (params: any) => {
      await Competitions.CompetitionForeignResultsDataService.create(params)
        .then((response) => {
          console.log(response.data)
        })
        .catch((err) => {
          this.error = err
        })
    }
    if (this.existingForeignCompetition === false) {
      if (this.foreignPlayerCompetitionData.foreignCompetitionName === '') {
        this.$Message.warning({ text: 'Der mangler et stævnenavn' })

        return false
      }
      if (this.foreignPlayerCompetitionData.foreignCompetitionDate === '') {
        this.$Message.warning({ text: 'Der mangler en dato' })

        return false
      }

      let foreignCompetitionId: number
      const createForeignCompetitionData = {
        ud_staevne_navn: this.foreignPlayerCompetitionData.foreignCompetitionName,
        ud_staevne_dato: this.foreignPlayerCompetitionData.foreignCompetitionDate
      }

      Competitions.ForeignCompetitionDataService.create(createForeignCompetitionData)
        .then((response) => {
          foreignCompetitionId = response.data.id

          if (this.foreignCompetitionResultsArray.length === 0) {
            this.$Message.success({ text: this.foreignPlayerCompetitionData.foreignCompetitionName + ' er blevet oprettet' })

            return true
          }
          const promises = []
          for (const item of this.foreignCompetitionResultsArray) {
            const createForeignResultsData = {
              medlem_id: item.memberId,
              ud_resultat_placering: item.placement,
              ud_resultat_dato: item.date,
              ud_resultat_ranglistepoint: item.points,
              staevne_raekker_id: item.rowId,
              ud_staevner_id: foreignCompetitionId
            }

            promises.push(asyncCreateForeignCompetitionResults(createForeignResultsData))
          }
          Promise.all(promises)
            .then((response) => {
              console.log(response)

              this.emptyForeignResultsDataFields()
              this.foreignCompetitionResultsModal = false
              this.$Message.success({ text: 'Aller udenlandske resulter er blevet registreret' })

              return true
            })
            .catch((err) => {
              this.error = err
            })
        })
        .catch((err) => {
          this.error = err
        })
    } else {
      if (this.foreignCompetitionValue.id === 0) {
        this.$Message.warning({ text: 'Vælg venligst et stævne' })

        return false
      }
      if (this.foreignCompetitionResultsArray.length === 0) {
        this.$Message.warning({ text: 'Ingen resultater at oprette' })

        return false
      }

      const promises = []
      for (const item of this.foreignCompetitionResultsArray) {
        const createForeignResultsData = {
          medlem_id: item.memberId,
          ud_resultat_placering: item.placement,
          ud_resultat_dato: item.date,
          ud_resultat_ranglistepoint: item.points,
          staevne_raekker_id: item.rowId,
          ud_staevner_id: this.foreignCompetitionValue.id
        }

        promises.push(asyncCreateForeignCompetitionResults(createForeignResultsData))
      }
      Promise.all(promises)
        .then((response) => {
          console.log(response)

          this.emptyForeignResultsDataFields()
          this.foreignCompetitionResultsModal = false
          this.$Message.success({ text: 'Aller udenlandske resulter er blevet registreret' })

          return true
        })
        .catch((err) => {
          this.error = err
        })
    }

    return false
  }

  public emptyForeignResultsDataFields () : void {
    this.resetForeignPlayerCompetitionData()
    this.foreignCompetitionResultsArray = []
    this.foreignCompetitionValue = { name: 'Vælg stævne', id: 0, date: '' }
    this.existingForeignCompetition = false
    this.foreignPlayerCompetitionData = { foreignCompetitionName: '', foreignCompetitionDate: '' }
  }

  // When clicking on one of the tabs executed different methods, usefull for limiting fethes from api
  public retrieveVariousData () : void {
    if (this.tabValue === 'Vis og rediger resultater') {
      this.fillCompetitionNameOptionsData()
      this.fillForeignCompetitionNameOptionsData()
    }
  }

  public fillCompetitionNameOptionsData () : void {
    this.tempFilterCompetitionNameOptions = [{ name: 'Intet stævne', value: '0' }]
    const currentYearPeriod = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString()
    let tempStaevners: SysCompetition[] = []
    let extraParameters = 'staevne_slut_gte=' + currentYearPeriod

    if (this.historicalCompetitionResultsValue !== 'Seneste år') {
      extraParameters = 'staevne_slut_gte=' + this.historicalCompetitionResultsValue + '-01-01&staevne_slut_lte=' + this.historicalCompetitionResultsValue + '-12-31'
    }

    Competitions.CompetitionsDataService.getAll('', null, extraParameters)
      .then((response) => {
        tempStaevners = response.data

        if (tempStaevners.length > 0) {
          for (const item of tempStaevners) {
            this.tempFilterCompetitionNameOptions.push({ name: item.staevne_navn, value: (item.id === undefined ? '0' : Number(item.id).toString()) })
          }
          console.log('Competition names = ' + JSON.stringify(this.tempFilterCompetitionNameOptions))
        }

        if (!this.foreignCompetitionResults) {
          this.filterCompetitionNameOptions = this.tempFilterCompetitionNameOptions
        }
      })
      .catch((err) => {
        this.error = err
      })
  }

  public fillForeignCompetitionNameOptionsData () : void {
    console.log(this.historicalCompetitionResultsValue)
    this.tempFilterForeignCompetitionNameOptions = [{ name: 'Intet stævne', value: '0' }]
    const currentYearPeriod = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString()
    let tempUdStaevners: SysForeignCompetition[] = []
    let extraParameter = 'ud_staevne_dato_gte=' + currentYearPeriod

    if (this.historicalCompetitionResultsValue !== 'Seneste år') {
      extraParameter = 'ud_staevne_dato_gte=' + this.historicalCompetitionResultsValue + '-01-01&ud_staevne_dato_lte=' + this.historicalCompetitionResultsValue + '-12-31'
    }

    Competitions.ForeignCompetitionDataService.getAll('', null, extraParameter)
      .then((response) => {
        tempUdStaevners = response.data

        if (tempUdStaevners.length > 0) {
          for (const item of tempUdStaevners) {
            this.tempFilterForeignCompetitionNameOptions.push({ name: item.ud_staevne_navn, value: (item.id === undefined ? '0' : Number(item.id).toString()) })
          }
          console.log('Foreign Competition names = ' + JSON.stringify(this.tempFilterForeignCompetitionNameOptions))
        }
        if (this.foreignCompetitionResults) {
          this.filterCompetitionNameOptions = this.tempFilterForeignCompetitionNameOptions
        }
      })
      .catch((err) => {
        this.error = err
      })
  }

  public deleteResultWarning (resultId: number) : void {
    this.foreignResultId = resultId
    this.deleteResultWarningModal = true
  }

  public deleteForeignResult (resultId: number) : void {
    Competitions.CompetitionForeignResultsDataService.delete(resultId.toString())
      .then((response) => {
        console.log(response.data)

        this.deleteResultWarningModal = false
        this.retrieveCompetitionResults()
      })
      .catch((err) => {
        this.error = err
      })
  }

  @Watch('deleteResultWarningModal')
  onWarningModalToggleChange (newVal: boolean) : void {
    if (!newVal) {
      this.foreignResultId = 0
    }
  }

  public retrieveProductTypeOptions () : void {
    if (this.productTypeOptions.length === 0) {
      Products.ProductTypesDataService.getAll('', null, 'produkt_type_status=true')
        .then((response) => {
          const tempProductType = response.data as SysProductProductType[]

          for (const item of tempProductType) {
            this.productTypeOptions.push({ name: item.produkt_type_navn, value: Number(item.id) })
          }
          this.productTypeOptions.sort((a, b) => a.name.localeCompare(b.name))
          this.productTypeOptions.unshift({ name: 'Vælg produktgruppe', value: 0 })
        })
        .catch((err) => {
          this.error = err
        })
    }
  }

  public newCompetition () : void {
    // this.retrieveProductTypeOptions()
    this.createNewCompetition = true
    this.competitionModal = true
  }

  public retrieveDartConnectTournamentData () : void {
    DartConnect.DartConnectDataService.getDartConnectTournaments()
      .then((response) => {
        this.tournamentsOption = response.data
      })
      .catch((error) => {
        console.log('ERROR', error)
      })
  }

  public setTournament () : void {
    if (this.selectedTournamentOption) {
      const updateCompetitionResultsData = { staevner_tournament_id: (this as any).selectedTournamentOption.id.toString() }
      Competitions.CompetitionsDataService.update(this.lastEditedCompetitionId.toString(), updateCompetitionResultsData)
        .then((response) => {
          console.log('RESPONSE', response)
          this.$Message.success({ text: 'staevner_tournament_id added' })
        })
        .catch((error) => {
          console.log('ERROR', error)
          this.$Message.warning({ text: 'Not added' })
        })
    }
  }

  @Watch('tempCompetition.staevner_registration_status')
  onTempCompetitionChange () : void {
    this.isEventRegistrationOpen = (this as any).tempCompetition.staevner_registration_status
  }

  @Watch('isEventRegistrationOpen')
  onEventRegistrationStatusChange () : void {
    if (this.tempCompetition && ((this as any).tempCompetition?.staevner_registration_status) !== this.isEventRegistrationOpen) {
      const updatedCompetitionRegistrationStatus = { staevner_registration_status: this.isEventRegistrationOpen }
      Competitions.CompetitionsDataService.update(this.lastEditedCompetitionId.toString(), updatedCompetitionRegistrationStatus)
        .then((response) => {
          this.tempCompetition = response.data
          const msg = this.isEventRegistrationOpen ? 'Open' : 'Closed'
          this.$Message.success({ text: `Regitrstion is ${msg}` })
        })
        .catch((error) => {
          console.log('ERROR', error)
          this.$Message.warning({ text: 'Somthing went to wrong' })
        })
    }
  }

  get isSetDissable () : boolean {
    if (this.selectedTournamentOption) {
      return false
    } else {
      return true
    }
  }

  @Watch('selectedTournamentOption')
  onSelectedTournamentOptionValueChange (newVal: any) : void {
    this.tournamentEventsOption = []
    this.retrieveDartConnectTournamentEventData()
  }

  public retrieveDartConnectTournamentEventData () : void {
    if (this.selectedTournamentOption) {
      DartConnect.DartConnectDataService.getDartConnectTournamentEvents((this as any).selectedTournamentOption.id.toString())
        .then((response) => {
          const tournamentEvents = response.data
          // tournamentEvents = tournamentEvents.filter((event: any) => event.bracket_type === 'elimination')
          this.tournamentEventsOption = tournamentEvents
        })
        .catch((error) => {
          console.log('ERROR', error)
        })
    }
  }

  public async updateDartConnectEvents () : Promise<void> {
    const updateCompetitionEventsData = { staevner_tournament_events: JSON.stringify(this.selectedEventsData) }
    Competitions.CompetitionsDataService.update(this.lastEditedCompetitionId.toString(), updateCompetitionEventsData)
      .then((response) => {
        console.log('RESPONSE', response)
        this.$Message.success({ text: 'Event set' })
      })
      .catch((error) => {
        console.log('ERROR', error)
        this.$Message.warning({ text: 'Not added' })
      })
  }

  @Watch('competitionPlayerCategoryTitleValue')
  onCompetitionPlayerCategoryValueChange (newVal: any) : void {
    const isSelectedEvent = (this as any).selectedEventsData[this.competitionPlayerCategoryTitleValue.value]
    if (isSelectedEvent) {
      this.isGenerateResultsDisabled = false
      this.getDartConnectResults(isSelectedEvent)
    } else {
      this.$Message.warning({ text: 'dart_connect Event not found' })
      this.isGenerateResultsDisabled = true
    }
  }

  public async getDartConnectResults (rowData: any) : Promise<void> {
    const tournamentEventsMatchResultsPromise = DartConnect.DartConnectDataService.getDartConnectTournamentsEventsMatchResults(rowData.tournament_id, rowData.id)
    await Promise.all([tournamentEventsMatchResultsPromise])
      .then((response) => {
        this.dartConnectResultsData = response[0]
        if (this.dartConnectResultsData) {
          this.setDartConnectResults()
        }
      })
      .catch((error) => {
        console.log('DART_CONNECT_EVENT_Match_ERROR', error)
      })
  }

  public setDartConnectResults () : void {
    const resultsArr = []
    const resultsData = []
    const ranglistepointsIndex = []

    for (const resultDataItem in this.dartConnectResultsData) {
      const resultDataItemValues = this.dartConnectResultsData[resultDataItem]
      for (let i = 0; i < resultDataItemValues.length; i++) {
        if (resultDataItemValues[i].match_score) {
          if (resultDataItemValues[i].match_score[0] < resultDataItemValues[i].match_score[2]) {
            resultsArr.push({ licens: resultDataItemValues[i].competitor_home.org_player_id, navn: resultDataItemValues[i].competitor_home.full_name })
          } else {
            resultsArr.push({ licens: resultDataItemValues[i].competitor_away.org_player_id, navn: resultDataItemValues[i].competitor_away.full_name })
          }
        }
      }
    }

    // For Final winner
    const finalMatchData = this.dartConnectResultsData.T2
    if (finalMatchData[0].match_score) {
      if (finalMatchData[0].match_score[0] > finalMatchData[0].match_score[2]) {
        resultsArr.push({ licens: finalMatchData[0].competitor_home.org_player_id, navn: finalMatchData[0].competitor_home.full_name })
      } else {
        resultsArr.push({ licens: finalMatchData[0].competitor_away.org_player_id, navn: finalMatchData[0].competitor_away.full_name })
      }
    }

    for (let i = 0; i < Math.round(Math.sqrt(resultsArr.length)); i++) {
      for (let j = 1; j <= Math.pow(2, i); j++) {
        ranglistepointsIndex.push(Math.pow(2, i) + 1 + '-' + Math.pow(2, i + 1))
      }
    }
    ranglistepointsIndex.shift()
    ranglistepointsIndex.unshift('1', '2')
    resultsArr.reverse()
    for (let i = 0; i < resultsArr.length; i++) {
      resultsData.push({ ...resultsArr[i], place: ranglistepointsIndex[i] })
    }
    this.tempCompetitionResultsData = resultsData
  }

  public generateCompetitionResultsFromDartConnect () : void {
    console.log('Generate competition results for the competition with the Id: ' + this.lastEditedCompetitionResultsCompetitionId)
    let competitionPlayerCategory = -1 // 0 = Herre A, 1 = Herre B, 2 = Herre C; 32 = Dame A, 33 = Herre B, 34 = Herre C; 64 = Drenge A, 65 = Drenge B, 66 = Drenge C; 128 = Pige A, 129 = Pige B, 130 = Pige C
    let validCompetitionPlayerCategorySubCategory1Match = false
    let validCompetitionPlayerCategorySubCategory2Match = false
    let isCompetitionPlayerCategoryJunior = false
    let competitionEventType = ''
    this.editCompetitionResultsModalTabValue = '2'

    // Evaluate the selected competition player category - converting evaluated data to a number, that can be processed more easily in subsequent code.
    let endPosOfCompetitionRowTitle = this.competitionPlayerCategoryTitleValue.name.trim().indexOf(' - ')
    endPosOfCompetitionRowTitle = endPosOfCompetitionRowTitle < 0 ? this.competitionPlayerCategoryTitleValue.name.trim().length - 1 : endPosOfCompetitionRowTitle
    const competitionPlayerCategoryTitle = this.competitionPlayerCategoryTitleValue.name.trim().slice(0, endPosOfCompetitionRowTitle).toLocaleLowerCase()
    console.log('[generateCompetitionResults] competitionPlayerCategoryTitle = ' + competitionPlayerCategoryTitle)

    if (competitionPlayerCategoryTitle.search('herre') >= 0) {
      competitionPlayerCategory = 0
      validCompetitionPlayerCategorySubCategory1Match = true
    } else if (competitionPlayerCategoryTitle.search('dame') >= 0) {
      competitionPlayerCategory = 32
      validCompetitionPlayerCategorySubCategory1Match = true
    } else if (competitionPlayerCategoryTitle.search('drenge') >= 0) {
      competitionPlayerCategory = 64
      isCompetitionPlayerCategoryJunior = true
      validCompetitionPlayerCategorySubCategory1Match = true
    } else if (competitionPlayerCategoryTitle.search('pige') >= 0) {
      competitionPlayerCategory = 128
      isCompetitionPlayerCategoryJunior = true
      validCompetitionPlayerCategorySubCategory1Match = true
    } else if (competitionPlayerCategoryTitle.search('junior') >= 0) {
      competitionPlayerCategory = 192
      isCompetitionPlayerCategoryJunior = true
      validCompetitionPlayerCategorySubCategory1Match = true
    } else if (competitionPlayerCategoryTitle.search('række') >= 0) {
      competitionPlayerCategory = 256
      validCompetitionPlayerCategorySubCategory1Match = true
    } else if (competitionPlayerCategoryTitle.search('paradart') >= 0) {
      competitionPlayerCategory = 256
      validCompetitionPlayerCategorySubCategory1Match = true
    }
    if (competitionPlayerCategoryTitle.startsWith('a-') || competitionPlayerCategoryTitle.endsWith(' a') || competitionPlayerCategoryTitle.startsWith('paradart')) {
      competitionPlayerCategory |= 0
      competitionEventType = 'A'
      validCompetitionPlayerCategorySubCategory2Match = true
    } else if (competitionPlayerCategoryTitle.startsWith('b-') || competitionPlayerCategoryTitle.endsWith(' b')) {
      competitionPlayerCategory |= 1
      competitionEventType = 'B'
      validCompetitionPlayerCategorySubCategory2Match = true
    } else if (competitionPlayerCategoryTitle.startsWith('c-') || competitionPlayerCategoryTitle.endsWith(' c')) {
      competitionPlayerCategory |= 2
      validCompetitionPlayerCategorySubCategory2Match = true
    } else if (competitionPlayerCategoryTitle.endsWith('single')) {
      competitionPlayerCategory |= 4
      validCompetitionPlayerCategorySubCategory2Match = true
    } else if (competitionPlayerCategoryTitle.endsWith('double') || competitionPlayerCategoryTitle.endsWith('pairs') || competitionPlayerCategoryTitle.endsWith('hold')) {
      competitionPlayerCategory |= 8
      validCompetitionPlayerCategorySubCategory2Match = true
    } else if (competitionPlayerCategoryTitle.endsWith('men') || competitionPlayerCategoryTitle.endsWith('ladies') || competitionPlayerCategoryTitle.endsWith('boys') || competitionPlayerCategoryTitle.endsWith('girls') || this.competitionPlayerCategoryTitleValue.name.trim().toLocaleLowerCase().endsWith('wheelchair') || this.competitionPlayerCategoryTitleValue.name.trim().toLocaleLowerCase().endsWith('standing')) {
      competitionPlayerCategory |= 16
      validCompetitionPlayerCategorySubCategory2Match = true
    }

    console.log('[generateCompetitionResults] competitionPlayerCategory = ' + competitionPlayerCategory)
    console.log('[generateCompetitionResults] competitionCategory = ' + this.competitionData.competitionCategory)
    if ((this.competitionData.competitionCategory === 'kategori1' || this.competitionData.competitionCategory === 'kategori3' || this.competitionData.competitionCategory === 'kategori4') && validCompetitionPlayerCategorySubCategory2Match) {
      this.tempCompetitionResultsData.forEach(item => { item.memberactive = (item.licens && (Number(item.licens) > 0 || item.licens?.trim() === '0')) })

      // Competitions.CompetitionRankingsPointsDataService.findByCompetitionRankingsPointStatus(true)
      Competitions.CompetitionRankingsPointsDataService.findByCompetitionRankingsPointStatus(true, '', null, `staevne_ranglistepoint_kategori=${this.competitionData.competitionCategory}`)
        .then((response) => {
          this.tempCompetitionRankingsPoints = response.data
          this.tempCompetitionResultsData.forEach(item => { item.points = (item.memberactive && !(item.licens?.trim() === '0') ? this.calcCompetitionResultPointsOfDartConnectForJunior(this.competitionData.competitionCategory, competitionPlayerCategory, item.place, isCompetitionPlayerCategoryJunior, competitionEventType) : 0); item.pointoffset = 0 })
          this.tempCompetitionRankingsPoints = []
        })
        .catch((err) => {
          this.error = err
          this.editCompetitionResultsModal = false
        })
    } else if ((validCompetitionPlayerCategorySubCategory2Match && competitionPlayerCategory === 2) || (validCompetitionPlayerCategorySubCategory1Match && validCompetitionPlayerCategorySubCategory2Match)) {
      this.tempCompetitionResultsData.forEach(item => { item.memberactive = (item.licens && (Number(item.licens) > 0 || item.licens?.trim() === '0')) })

      // Competitions.CompetitionRankingsPointsDataService.findByCompetitionRankingsPointStatus(true)
      Competitions.CompetitionRankingsPointsDataService.findByCompetitionRankingsPointStatus(true, '', null, `staevne_ranglistepoint_kategori=${this.competitionData.competitionCategory}`)
        .then((response) => {
          this.tempCompetitionRankingsPoints = response.data
          console.log('RESPONSE...', response.data)
          this.tempCompetitionResultsData.forEach(item => { item.points = (item.memberactive && !(item.licens?.trim() === '0') ? this.calcCompetitionResultPointsOfDartConnect(this.competitionData.competitionCategory, competitionPlayerCategory, item.place) : 0); item.pointoffset = 0 })
          this.tempCompetitionRankingsPoints = []
        })
        .catch((err) => {
          this.error = err
          console.log('ERROR...', err)
          this.editCompetitionResultsModal = false
        })
    } else {
      this.tempCompetitionResultsData.forEach(item => { item.points = 0; item.pointoffset = 0 })
    }
  }

  // Calculate competition result points based upon the player category, the rankings point category of the competition row and the player placement and return the value of the calculated points.
  public calcCompetitionResultPointsOfDartConnect (competRankingsPointCategory: string, competPlayerCategory: number, competPlayerPlacement: string) : number {
    let retVal = 0
    let arrIndex = 0
    let posDashSeperatorInString = 0
    let minPlacementVal = 0
    let maxPlacementVal = 0
    let rankingsPointRow = 0
    posDashSeperatorInString = competPlayerPlacement?.trim().indexOf('-')
    posDashSeperatorInString = posDashSeperatorInString >= 0 ? posDashSeperatorInString : competPlayerPlacement?.trim().length
    const playerMinPlacementVal = Number(competPlayerPlacement?.trim().slice(0, posDashSeperatorInString))
    const playerMaxPlacementVal = Number(competPlayerPlacement?.trim().slice((posDashSeperatorInString === competPlayerPlacement?.trim().length) ? 0 : posDashSeperatorInString + 1))

    for (const objItem of this.tempCompetitionRankingsPoints) {
      posDashSeperatorInString = objItem.staevne_ranglistepoint_placering?.trim().indexOf('-')
      posDashSeperatorInString = posDashSeperatorInString >= 0 ? posDashSeperatorInString : objItem.staevne_ranglistepoint_placering?.trim().length
      minPlacementVal = Number(objItem.staevne_ranglistepoint_placering?.trim().slice(0, posDashSeperatorInString))
      maxPlacementVal = Number(objItem.staevne_ranglistepoint_placering?.trim().slice((posDashSeperatorInString === objItem.staevne_ranglistepoint_placering?.trim().length) ? 0 : posDashSeperatorInString + 1))
      rankingsPointRow = (objItem.staevne_ranglistepoint_raekke === null ? 2 : (objItem.staevne_ranglistepoint_raekke ? 0 : 1))

      if (objItem.staevne_ranglistepoint_status && objItem.staevne_ranglistepoint_kategori.toString()?.trim().toLocaleLowerCase() === competRankingsPointCategory) {
        if (playerMinPlacementVal >= minPlacementVal && playerMinPlacementVal <= maxPlacementVal) {
          if (rankingsPointRow === (competPlayerCategory & 3)) {
            retVal += Number(objItem.staevne_ranglistepoint_point)
          }
        }
      }

      arrIndex++
    }

    return retVal
  }

  // Calc points for junior category type
  public calcCompetitionResultPointsOfDartConnectForJunior (competRankingsPointCategory: string, competPlayerCategory: number, competPlayerPlacement: string, juniorCategoryPlayer: boolean, eventType: any) : number {
    let retVal = 0
    let arrIndex = 0
    let posDashSeperatorInString = 0
    let minPlacementVal = 0
    let maxPlacementVal = 0
    let rankingsPointRow = 0
    posDashSeperatorInString = competPlayerPlacement?.trim().indexOf('-')
    posDashSeperatorInString = posDashSeperatorInString >= 0 ? posDashSeperatorInString : competPlayerPlacement?.trim().length
    const playerMinPlacementVal = Number(competPlayerPlacement?.trim().slice(0, posDashSeperatorInString))
    const playerMaxPlacementVal = Number(competPlayerPlacement?.trim().slice((posDashSeperatorInString === competPlayerPlacement?.trim().length) ? 0 : posDashSeperatorInString + 1))

    for (const objItem of (this as any).tempCompetitionRankingsPoints) {
      posDashSeperatorInString = objItem.staevne_ranglistepoint_placering?.trim().indexOf('-')
      posDashSeperatorInString = posDashSeperatorInString >= 0 ? posDashSeperatorInString : objItem.staevne_ranglistepoint_placering?.trim().length
      minPlacementVal = Number(objItem.staevne_ranglistepoint_placering?.trim().slice(0, posDashSeperatorInString))
      maxPlacementVal = Number(objItem.staevne_ranglistepoint_placering?.trim().slice((posDashSeperatorInString === objItem.staevne_ranglistepoint_placering?.trim().length) ? 0 : posDashSeperatorInString + 1))
      rankingsPointRow = (objItem.staevne_ranglistepoint_raekke === null ? 2 : (objItem.staevne_ranglistepoint_raekke ? 0 : 1))

      if (objItem.staevne_ranglistepoint_status && objItem.staevne_ranglistepoint_kategori.toString()?.trim().toLocaleLowerCase() === competRankingsPointCategory) {
        if (playerMinPlacementVal >= minPlacementVal && playerMinPlacementVal <= maxPlacementVal) {
          if (juniorCategoryPlayer && objItem?.staevne_ranglistepoint_junior) {
            if (eventType === 'A' && objItem?.staevne_ranglistepoint_raekke === true) {
              retVal = Number(objItem.staevne_ranglistepoint_point)
            }
            if (eventType === 'B' && objItem?.staevne_ranglistepoint_raekke === false) {
              retVal = Number(objItem.staevne_ranglistepoint_point)
            }
          }
          if (!objItem?.staevne_ranglistepoint_junior) {
            if (eventType === 'A' && objItem?.staevne_ranglistepoint_raekke === true) {
              retVal = Number(objItem.staevne_ranglistepoint_point)
            }
            if (eventType === 'B' && objItem?.staevne_ranglistepoint_raekke === false) {
              retVal = Number(objItem.staevne_ranglistepoint_point)
            }
          }
          if (eventType === '' && juniorCategoryPlayer && objItem?.staevne_ranglistepoint_junior) {
            retVal = Number(objItem.staevne_ranglistepoint_point)
          }
          if (eventType === '' && !juniorCategoryPlayer) {
            if (rankingsPointRow === (competPlayerCategory & 3)) {
              retVal += Number(objItem.staevne_ranglistepoint_point)
            }
          }
        }
      }

      arrIndex++
    }

    return retVal
  }

  public reduceCompetitionResultPointsOfDartConnect (dataObjIndex: number, csvObjPlace: number) : void {
    const offsetValue = this.tempCompetitionResultsData && this.tempCompetitionResultsData[dataObjIndex]?.points ? this.tempCompetitionResultsData[dataObjIndex].points : 0
    this.tempCompetitionResultsData[dataObjIndex].pointoffset = -(Number(offsetValue))
  }

  public restoreCompetitionResultPointsOfDartConnect (dataObjIndex: number, csvObjPlace: number) : void {
    this.tempCompetitionResultsData[dataObjIndex].pointoffset = 0
  }

  public saveUpdateCompetitionResultsFromDartConnect () : void {
    console.log('Save or update competition results for the competition with the Id: ' + this.lastEditedCompetitionResultsCompetitionId)
    // Check if there is already an existing result entry for the given result date and the given comptition and competition row and member / player.
    // If there is one then update the result of it, otherwise create at new entry.

    let csvObjIndex = 0

    for (const objItem of this.tempCompetitionResultsData) {
      console.log('[saveUpdateCompetitionResults] memberactive = ' + objItem.memberactive)
      console.log('[saveUpdateCompetitionResults] licens is a number = ' + !isNaN(Number(objItem.licens)) + ' [' + (!isNaN(Number(objItem.licens)) ? objItem.licens : '-') + ']')
      console.log('[saveUpdateCompetitionResults] points = ' + objItem.points)
      console.log('[saveUpdateCompetitionResults] pointoffset = ' + objItem.pointoffset)
      const csvInputDataNameFieldValueFixedup = (objItem.navn === null ? '' : this.fixCSVInputName(objItem.navn))
      console.log('[saveUpdateCompetitionResults] name fixup = ***' + csvInputDataNameFieldValueFixedup + '***')
      let tempMedlemmers: SysMember[] = []
      let competitionMemberId = 0
      // Set default license to 0 for the foreign players
      if (!objItem.licens) {
        objItem.licens = 0
      }

      if (objItem.licens !== undefined && objItem.licens !== null &&
        objItem.points !== undefined && objItem.pointoffset !== undefined) {
        MembersDataService.getAll('', null, 'medlem_licens=' + Number(objItem.licens).toString())
          .then((response) => {
            tempMedlemmers = response.data

            if (tempMedlemmers.length >= 0 && isNaN(Number(objItem.navn.toString()))) {
              competitionMemberId = (tempMedlemmers.length > 0 && tempMedlemmers[0].id !== null ? Number(tempMedlemmers[0].id) : this.idOfGenericMember)
              console.log('[saveUpdateCompetitionResults] Searching for an existing competition result entry for member with Id: ' + competitionMemberId + ' ; Name : ' + csvInputDataNameFieldValueFixedup)
              console.log('[saveUpdateCompetitionResults] Searching for an existing competition result entry with competition result date Id: ' + this.competitionResultsData.competitionResultsDate)
              console.log('[saveUpdateCompetitionResults] Searching for an existing competition result entry with competition Id: ' + this.lastEditedCompetitionResultsCompetitionId)
              console.log('[saveUpdateCompetitionResults] Searching for an existing competition result entry with competition player category row Id: ' + Number(this.competitionPlayerCategoryTitleValue.value))
              tempMedlemmers = []

              if (competitionMemberId === this.idOfGenericMember) {
                // If it is a generic member (without a licens, - typical for foreign players) do a search for exact name match in the existing competition results data.
                console.log('[saveUpdateCompetitionResults] Generic member - doing a name search in the existing competition results data.')

                Competitions.CompetitionResultsDataService.findByCompetitionRowAndPlayerAltName(true, true, this.competitionResultsData.competitionResultsDate, '', null, this.lastEditedCompetitionResultsCompetitionId, Number(this.competitionPlayerCategoryTitleValue.value), (objItem.navn !== null ? csvInputDataNameFieldValueFixedup : ''))
                  .then((response) => {
                    this.tempCompetitionResults = response.data
                    console.log(response.data)

                    if (this.tempCompetitionResults.length > 0 && this.tempCompetitionResults[0].id !== null) {
                      // Update an existing competition result entry.
                      console.log('[saveUpdateCompetitionResults] Update an existing competition result entry for member with Id: ' + competitionMemberId + ' and for existing result with Id: ' + Number(this.tempCompetitionResults[0].id).toString())

                      const updateCompetitionResultsData = {
                        staevne_resultater_status: true,
                        medlem_id: competitionMemberId.toString(),
                        staevne_raekker_id: this.competitionPlayerCategoryTitleValue.value.toString(),
                        staevner_id: this.lastEditedCompetitionResultsCompetitionId.toString(),
                        staevne_resultater_dato: this.competitionResultsData.competitionResultsDate,
                        staevne_ranglistepoint: (competitionMemberId === this.idOfGenericMember ? 0 : (Number(objItem.points) + Number(objItem.pointoffset))),
                        staevne_ranglistepoint_oprindelig: (competitionMemberId === this.idOfGenericMember ? 0 : Number(objItem.points)),
                        staevne_resultater_navn: (objItem.navn !== null ? csvInputDataNameFieldValueFixedup : ''),
                        staevne_resultater_placering: objItem.place
                      }

                      console.log('[saveUpdateCompetitionResults] ' + JSON.stringify(updateCompetitionResultsData))

                      Competitions.CompetitionResultsDataService.update(Number(this.tempCompetitionResults[0].id).toString(), updateCompetitionResultsData)
                        .then((response) => {
                          console.log(response.data)

                          if (csvObjIndex >= this.tempCompetitionResultsData.length) {
                            this.editCompetitionResultsModal = false
                          }
                        })
                        .catch((err) => {
                          this.error = err
                        })
                    } else {
                      // Create a new competition result entry.
                      console.log('[saveUpdateCompetitionResults] Create a new competition result entry for member with Id: ' + competitionMemberId)

                      const createCompetitionResultsData = {
                        staevne_resultater_status: true,
                        medlem_id: competitionMemberId.toString(),
                        staevne_raekker_id: this.competitionPlayerCategoryTitleValue.value.toString(),
                        staevner_id: this.lastEditedCompetitionResultsCompetitionId.toString(),
                        staevne_resultater_dato: this.competitionResultsData.competitionResultsDate,
                        staevne_ranglistepoint: (competitionMemberId === this.idOfGenericMember ? 0 : (Number(objItem.points) + Number(objItem.pointoffset))),
                        staevne_ranglistepoint_oprindelig: (competitionMemberId === this.idOfGenericMember ? 0 : Number(objItem.points)),
                        staevne_resultater_navn: (objItem.navn !== null ? csvInputDataNameFieldValueFixedup : ''),
                        staevne_resultater_placering: objItem.place
                      }

                      console.log('[saveUpdateCompetitionResults] ' + JSON.stringify(createCompetitionResultsData))

                      Competitions.CompetitionResultsDataService.create(createCompetitionResultsData)
                        .then((response) => {
                          console.log(response.data)

                          if (csvObjIndex >= this.tempCompetitionResultsData.length) {
                            this.editCompetitionResultsModal = false
                          }
                        })
                        .catch((err) => {
                          this.error = err
                        })
                    }
                  })
                  .catch((err) => {
                    this.error = err
                  })
              } else {
                // Else search for matching member id value in the existing competition results data.
                Competitions.CompetitionResultsDataService.findByCompetitionRowAndMember(true, true, this.competitionResultsData.competitionResultsDate, '', null, this.lastEditedCompetitionResultsCompetitionId, Number(this.competitionPlayerCategoryTitleValue.value), competitionMemberId)
                  .then((response) => {
                    this.tempCompetitionResults = response.data
                    console.log(response.data)

                    if (this.tempCompetitionResults.length > 0 && this.tempCompetitionResults[0].id !== null) {
                      // Update an existing competition result entry.
                      console.log('[saveUpdateCompetitionResults] Update an existing competition result entry for member with Id: ' + competitionMemberId + ' and for existing result with Id: ' + Number(this.tempCompetitionResults[0].id).toString())

                      const updateCompetitionResultsData = {
                        staevne_resultater_status: true,
                        medlem_id: competitionMemberId.toString(),
                        staevne_raekker_id: this.competitionPlayerCategoryTitleValue.value.toString(),
                        staevner_id: this.lastEditedCompetitionResultsCompetitionId.toString(),
                        staevne_resultater_dato: this.competitionResultsData.competitionResultsDate,
                        staevne_ranglistepoint: (competitionMemberId === this.idOfGenericMember ? 0 : (Number(objItem.points) + Number(objItem.pointoffset))),
                        staevne_ranglistepoint_oprindelig: (competitionMemberId === this.idOfGenericMember ? 0 : Number(objItem.points)),
                        staevne_resultater_navn: (objItem.navn !== null ? csvInputDataNameFieldValueFixedup : ''),
                        staevne_resultater_placering: objItem.place
                      }

                      console.log('[saveUpdateCompetitionResults] ' + JSON.stringify(updateCompetitionResultsData))

                      Competitions.CompetitionResultsDataService.update(Number(this.tempCompetitionResults[0].id).toString(), updateCompetitionResultsData)
                        .then((response) => {
                          console.log(response.data)

                          if (csvObjIndex >= this.tempCompetitionResultsData.length) {
                            this.editCompetitionResultsModal = false
                          }
                        })
                        .catch((err) => {
                          this.error = err
                        })
                    } else {
                      // Create a new competition result entry.
                      console.log('[saveUpdateCompetitionResults] Create a new competition result entry for member with Id: ' + competitionMemberId)

                      const createCompetitionResultsData = {
                        staevne_resultater_status: true,
                        medlem_id: competitionMemberId.toString(),
                        staevne_raekker_id: this.competitionPlayerCategoryTitleValue.value.toString(),
                        staevner_id: this.lastEditedCompetitionResultsCompetitionId.toString(),
                        staevne_resultater_dato: this.competitionResultsData.competitionResultsDate,
                        staevne_ranglistepoint: (competitionMemberId === this.idOfGenericMember ? 0 : (Number(objItem.points) + Number(objItem.pointoffset))),
                        staevne_ranglistepoint_oprindelig: (competitionMemberId === this.idOfGenericMember ? 0 : Number(objItem.points)),
                        staevne_resultater_navn: (objItem.navn !== null ? csvInputDataNameFieldValueFixedup : ''),
                        staevne_resultater_placering: objItem.place
                      }

                      console.log('[saveUpdateCompetitionResults] ' + JSON.stringify(createCompetitionResultsData))

                      Competitions.CompetitionResultsDataService.create(createCompetitionResultsData)
                        .then((response) => {
                          console.log(response.data)

                          if (csvObjIndex >= this.tempCompetitionResultsData.length) {
                            this.editCompetitionResultsModal = false
                          }
                        })
                        .catch((err) => {
                          this.error = err
                        })
                    }
                  })
                  .catch((err) => {
                    this.error = err
                  })
              }
            }
            tempMedlemmers = []
          })
          .catch((err) => {
            this.error = err
          })
      }

      csvObjIndex++
    }
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

      if (userDataObject !== null && userDataObject.id !== 1 && userDataObject.id < 100 && userDataObject.role !== undefined && userDataObject.role !== null && userDataObject.role.id === 5) {
        this.retrieveClubs()
        this.retrieveCompetitions()
        this.retrieveCompetitionsForRegistration()
        this.retrieveCompetitionRows()
        this.retrieveCompetitionRankingsPoints()
        this.retrieveProductTypeOptions()
        this.createHistoricalResultsOptions()
        this.retrieveDartConnectTournamentData()
      } else {
        CommonFunctions.redirectLogin()
      }
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
